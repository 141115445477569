import { Box, Typography } from '@mui/material'
import { Dispatch, FC, SetStateAction } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lockedBouncerImage from '@/assets/images/auth/locked_bouncer.svg'
import { theme } from '@/theme/theme'

const UserLocked: FC<{ username: string; setUserLocked: Dispatch<SetStateAction<boolean>> }> = ({
  username,
  setUserLocked,
}) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
        {t('userLocked.userLocked')}
      </Typography>
      <Typography textAlign="center" width={'568px'}>
        <Trans
          i18nKey={'userLocked.description'}
          components={{
            1: <br />,
          }}
          values={{ username: username }}
        />
      </Typography>
      <Box padding={3}>
        <img src={lockedBouncerImage} alt="bouncer" />
      </Box>
      <Typography fontWeight={theme.typography.fontWeightSemiBold} textAlign="center">
        <Trans
          i18nKey={'userLocked.backToSignIn'}
          components={{
            1: (
              <Typography
                onClick={() => setUserLocked(false)}
                component="span"
                color={theme.palette.blue[900]}
                fontWeight={theme.typography.fontWeightSemiBold}
                sx={{ cursor: 'pointer' }}
              />
            ),
          }}
        />
      </Typography>
    </Box>
  )
}

export default UserLocked
