import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { Group } from '@/types/groups'

export type EditGroupParams = {
  _id: string
  name: string
  offices?: string[]
  managers?: string[]
}

export async function editGroup({ _id, name, offices = [], managers = [] }: EditGroupParams): Promise<Group> {
  const url = `${API_URL}/groups/edit/${_id}`
  const { data } = await axios.post(url, { name, offices, managers }, { withCredentials: true })
  return data.data
}

export function useEditGroup() {
  const queryCache = useQueryCache()
  return useMutation<Group, ServerError, EditGroupParams>(editGroup, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('groups')
    },
  })
}
