import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'

import { useAdmin } from '@/api/admins/get'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import AdminProfileDetailsSection from './AdminProfileDetailsSection'
import AdminProfileHeader from './AdminProfileHeader'
import AdminProfileMFA from './AdminProfileMFA'
import AdminProfileSecuritySection from './AdminProfileSecuritySection'

const AdminProfile: FC = () => {
  const { _id } = useParams()
  const { user } = useAuth()
  const { data: adminData, isFetching } = useAdmin({ id: _id })
  const isCurrnetUser = user?.id === adminData?._id

  return (
    <StyledBackGround>
      <Box padding={theme.spacing(1, 2)} minWidth={theme.spacing(88)}>
        <CustomBreadcrumbs />
        {!isFetching && adminData && (
          <>
            <StyledSection>
              <AdminProfileHeader adminData={adminData} />
            </StyledSection>
            <StyledSection>
              <AdminProfileDetailsSection adminData={adminData} user={user} />
            </StyledSection>
            {isCurrnetUser && (
              <>
                <StyledSection>
                  <AdminProfileSecuritySection user={user} />
                </StyledSection>
                <StyledSection>
                  <AdminProfileMFA user={user} />
                </StyledSection>
              </>
            )}
          </>
        )}
      </Box>
    </StyledBackGround>
  )
}

const StyledBackGround = styled(Box)(() => ({
  background: theme.palette.grey[100],
  minHeight: '100%',
  margin: theme.spacing(-2, -1),
  paddingBottom: theme.spacing(10),
}))

const StyledSection = styled(Box)(() => ({
  padding: theme.spacing(3),
  margin: theme.spacing(2, 0, 0),
  minWidth: '540px',
  background: theme.palette.white,
  borderRadius: '10px',
}))

export const StyledButtonsWraper = styled(Box)(() => ({
  padding: theme.spacing(1, 0, 2),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  marginLeft: 'auto',
}))
export const StyledTitle = styled(Typography)(() => ({
  fontSize: 14,
  fontWeight: theme.typography.fontWeightMedium,
}))

export default AdminProfile
