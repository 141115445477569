import { faCheck, faX } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Avatar, Button, Container, Paper, Typography } from '@mui/material'
import { useActivateAccount } from '@/api/auth/activate-account'
import { useUrlQuery } from '@/common/hooks/useUrlQuery'

export default function VerifyEmail() {
  const classes = useStyles()
  const { t } = useTranslation()
  const urlQuery = useUrlQuery()
  const navigateTo = useNavigate()
  const { mutateAsync: activateAccount } = useActivateAccount()
  const [isEmailValid, setEmailValid] = useState(false)

  const token = urlQuery.get('token')

  useEffect(() => {
    const verifyToken = async (token: string) => {
      setEmailValid((await activateAccount({ token })) ?? false)
    }
    if (token) {
      verifyToken(token)
    }
  }, [activateAccount, token])

  return (
    <Container component="main" maxWidth="xs">
      <Paper className={classes.root}>
        {isEmailValid ? (
          <Avatar className={classes.avatar}>
            <FontAwesomeIcon icon={faCheck as IconProp} />
          </Avatar>
        ) : (
          <Avatar className={classes.avatarError}>
            <FontAwesomeIcon icon={faX as IconProp} />
          </Avatar>
        )}
        <div className={classes.textContainer}>
          <Typography variant={'h5'}>{t(isEmailValid ? 'verifyEmail.success' : 'verifyEmail.error')}</Typography>
          <Typography variant={'subtitle1'}>
            {t(isEmailValid ? 'verifyEmail.verified' : 'verifyEmail.unverified')}
          </Typography>
        </div>
        <Button onClick={() => navigateTo('/sign-in')} variant={'contained'} color={'primary'}>
          {t('verifyEmail.toLogin')}
        </Button>
      </Paper>
    </Container>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(12),
      padding: theme.spacing(4),
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
    },
    avatarError: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
    },
  })
)
