import axios from 'axios'
import { useMutation, useQueryCache } from 'react-query'

import { Office, OfficeWorkingHours } from '@/types/offices'
import { API_URL } from '../constants'
import { ServerError } from '../types'
import { serializeArray, Serialized } from '../utils/serialize-array'

export type NewOfficeParams = {
  name: string
  language: string
  time_zone: string
  geo_location: string
  state?: string
  working_hours: OfficeWorkingHours
}

export async function createNewOffice(params: NewOfficeParams): Promise<Office> {
  const url = `${API_URL}/offices/new`
  const { data } = await axios.post(url, params, { withCredentials: true })
  return data.data
}

export function useNewOffice() {
  const queryCache = useQueryCache()
  return useMutation<Office, ServerError, NewOfficeParams>(createNewOffice, {
    throwOnError: true,
    onMutate: (newOffice) => {
      const predictedOffice: Office = {
        _id: '',
        client_id: '',
        awareness_score: 0,
        awareness_last_30_days: [],
        ...newOffice,
      }
      queryCache.cancelQueries('offices')
      const previousOffices = queryCache.getQueryData('offices') as Serialized<Office>
      //@ts-ignore
      queryCache.setQueryData('offices', (old: Serialized<Office>) =>
        old ? serializeArray([...old.array, predictedOffice]) : serializeArray([predictedOffice])
      )
      return () => queryCache.setQueryData('offices', previousOffices)
    },
    //@ts-ignore
    onError: (err, variables, rollback) => rollback(),
    onSettled: () => {
      queryCache.invalidateQueries('offices')
    },
  })
}
