import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Paper,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { FC, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { useCampaignAssets } from '@/api/campaigns/campaign-assets'
import { useGetCampaignReport } from '@/api/campaigns/campaign-report'
import { useCampaign } from '@/api/campaigns/get'
import { useCampaignUsers } from '@/api/campaigns/getUsers'
import { ALL_DATA_LIMIT, NEW_LAUNCH_ANIMATION_DELAY_TIME, US_DATE_TIME_WITH_MONTH_NAME } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import packageLaunchAnimationData from '@/assets/lottie/rocketsUnpackaged.json'
import FeedbackAnimation from '@/common/animations/FeedbackAnimation'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import CampainDetails from '@/common/components/CampaignDetails/CampaignDetails'
import Countdown from '@/common/components/Countdown/Countdown'
import GaugeScoremeter from '@/common/components/GaugeScoremeter/GaugeScoremeter'
import InfoTooltip from '@/common/components/InfoTooltip/InfoTooltip'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import SimulationPreview from '@/common/components/SimulationPreview/SimulationPreview'
import { CampaignEventsStats, CampaignGroupEventsStats, CampaignUsersByEvent } from '@/common/types/campaign.types'
import LoadingButton from '@mui/lab/LoadingButton'
import ReportAlert from '../TrainingCampaignDashboard/ReportAlert'
import CampaignGroupAnalytics from './CampaignGroupAnalytics'
import CampaignUsersTable from './CampaignUsersTable'
import EventsStepper from './EventsStepper'
import MultiSimPreviewTabs from './MultiSimPreviewTabs'

const statisticEvents = ['email_open', 'email_link_click', 'submit', 'report']

const CampaignDashboard: FC = () => {
  const classes = useStyles()
  const { _id } = useParams()
  const { t } = useTranslation()
  const [isReportAlertOpen, setReportAlertOpen] = useState(false)
  const { data: campaignData, isPending: isPendingCampaign } = useCampaign(_id, { refetchInterval: 10_000 })
  const { data: allUsersData, isPending: isPendingMemers } = useCampaignUsers(
    { id: _id, limit: 0 },
    { refetchInterval: 10_000 }
  )
  const { data: groupsData, isLoading: groupsLoading } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: simulationsData, isPending: isSimulationPending } = useCampaignAssets(campaignData?._id, {
    enabled: !!campaignData,
  })

  //TODO add the queryies for Componets and pass as properties, Campaigs Members

  const vectors = campaignData?.asset_metadata?.vector
    ? [campaignData.asset_metadata.vector]
    : [...new Set(simulationsData?.flatMap((simulation) => simulation.vectors) || [])] || ['video']

  const assetAllowUnverifiedDomains = !vectors.includes('email')

  const [getCampaignReport, { isLoading: isReportLoading }] = useGetCampaignReport(() => setReportAlertOpen(true))

  if (
    isPendingCampaign ||
    isPendingMemers ||
    isSimulationPending ||
    groupsLoading ||
    !campaignData ||
    !allUsersData ||
    !simulationsData ||
    !groups
  )
    return <LoadingContainer />

  const { results: users } = allUsersData || {}
  const hasEvents = users.some((user) => Object.values(user.events).some((event) => !!event))
  const onlyAssetsWithPhone = simulationsData?.every((a) =>
    a.vectors?.every((vector) => vector === 'sms' || vector === 'whatsapp')
  )

  const eventStats: CampaignEventsStats = users.reduce(
    (acc, user) => {
      for (let event in user.events) {
        if (event in acc && user.events[event]) {
          acc[event].count++
          acc[event].percentage = parseFloat(((acc[event].count / users.length) * 100).toFixed(2))
        }
      }
      return acc
    },
    {
      email_open: { count: 0, percentage: 0 },
      email_link_click: { count: 0, percentage: 0 },
      submit: { count: 0, percentage: 0 },
      report: { count: 0, percentage: 0 },
    }
  )

  const usersByEvent: CampaignUsersByEvent = users.reduce(
    (acc, user) => {
      for (let event in user.events) {
        if (event in acc && user.events[event]) {
          const { events, ...userDetails } = user
          acc[event].push(userDetails)
        }
      }
      return acc
    },
    { email_open: [], email_link_click: [], submit: [], report: [] }
  )

  const groupsById = groups.reduce((acc, obj) => {
    acc[obj._id] = obj
    return acc
  }, {})

  const groupStats: CampaignGroupEventsStats = users.reduce((acc, user) => {
    for (let event of statisticEvents) {
      if (user.events[event]) {
        for (let groupId of user.member_of) {
          const { name: groupName } = groupsById[groupId] || {}
          if (groupName) {
            if (!acc[event]) acc[event] = {}
            acc[event][groupName] = (acc[event][groupName] || 0) + 1
          }
        }
      }
    }
    return acc
  }, {} as CampaignGroupEventsStats)

  const userEventsCsvHeaders = [
    { label: t('campaignDashboard.name'), key: 'name' },
    { label: t('campaignDashboard.username'), key: 'username' },
    { label: t('campaignDashboard.clicked'), key: 'email_link_click' },
    { label: t('campaignDashboard.insertedData'), key: 'submit' },
    { label: t('campaignDashboard.feedbackCompleted'), key: 'feedback_completed' },
    { label: t('campaignDashboard.reported'), key: 'report' },
  ]
  dayjs.extend(customParseFormat)
  const csvExportData = users.map((user) => ({
    user_id: user._id,
    username: user.username,
    name: `${user.first_name} ${user.last_name}`,
    ...Object.fromEntries(
      Object.keys(user.events).map((key) => [
        key,
        user.events[key] === false ? '-' : dayjs(user.events[key]).format(US_DATE_TIME_WITH_MONTH_NAME),
      ])
    ),
  }))

  const isNEW =
    dayjs().valueOf() -
      dayjs(campaignData?.created_at).add(dayjs(campaignData?.created_at).utcOffset(), 'minutes').valueOf() <
    NEW_LAUNCH_ANIMATION_DELAY_TIME

  return (
    <div className={classes.root}>
      <ReportAlert open={isReportAlertOpen} handleClose={() => setReportAlertOpen(false)} />
      <CustomBreadcrumbs lastBreadcrumbSuffix={`| ${campaignData.name}` || ''} />
      <div className={classes.titleContainer}>
        <div></div>
        <div className={classes.flex}>
          {hasEvents ? (
            <CSVLink
              filename={'Campaign Events Log'}
              data={csvExportData}
              headers={userEventsCsvHeaders}
              style={{ textDecoration: 'none' }}>
              <Button variant={'outlined'} color={'primary'}>
                {t('campaignDashboard.export')}
              </Button>
            </CSVLink>
          ) : (
            <Button disabled variant={'outlined'} color={'primary'}>
              {t('campaignDashboard.export')}
            </Button>
          )}
          <Tooltip title={!users?.length && t('campaignDashboard.noMembers')}>
            <Box>
              <LoadingButton
                sx={{
                  px: '22px',
                  ml: '8px',
                }}
                variant="contained"
                onClick={() => getCampaignReport(campaignData._id)}
                disabled={campaignData.status !== 'completed' || isReportLoading || !users?.length}
                loading={isReportLoading}
                loadingPosition="end">
                {t('campaignDashboard.downloadReport')}
              </LoadingButton>
            </Box>
          </Tooltip>
        </div>
      </div>
      <div className={classes.eventsContainer}>
        <EventsStepper eventStats={eventStats} usersByEvent={usersByEvent} />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={12} md={4}>
            <Paper className={classes.detailsContainer}>
              <TitleBar>
                <Typography color={'inherit'} variant={'subtitle2'}>
                  {t('trainingCampaignDashboard.campaignDetails')}
                </Typography>
              </TitleBar>
              <CampainDetails
                campaignData={campaignData}
                membersData={allUsersData}
                isSimulation
                phishingSimulations={simulationsData}
                vectors={vectors}
              />
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={classes.statsContainer}>
              <TitleBar>
                <Typography color={'inherit'} variant={'subtitle2'}>
                  {t('trainingCampaignDashboard.timeFrame')}
                </Typography>
              </TitleBar>
              <div className={classes.scoreMeterContainer}>
                <Countdown campaign={campaignData} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={classes.scoreContainer}>
              <TitleBar>
                <Typography color={'inherit'} variant={'subtitle2'}>
                  {t('campaignDashboard.awarenessLevel.awarenessLevel')}
                </Typography>
                <InfoTooltip className={classes.infoTooltip} title={t('campaignDashboard.awarenessLevel.tooltip')} />
              </TitleBar>
              <div className={classes.scoreMeterContainer}>
                <GaugeScoremeter score={campaignData.score || 0} hideTitle />
              </div>
            </Paper>
          </Grid>
          {simulationsData?.length === 1 ? (
            <>
              <Grid container direction={'column'} item xs={12}>
                <CustomAccordion defaultChecked>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                      {t('campaignDashboard.usersTable.users')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CampaignUsersTable
                      campaignId={_id}
                      hasDistribution={false}
                      assetAllowUnverifiedDomains={assetAllowUnverifiedDomains}
                      onlyAssetsWithPhone={onlyAssetsWithPhone}
                    />
                  </AccordionDetails>
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion defaultChecked>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                      {t('campaignDashboard.groupAnalytics.groupAnalytics')}
                    </Typography>
                    <InfoTooltip
                      className={classes.infoTooltip}
                      title={t('campaignDashboard.groupAnalytics.tooltip')}
                    />
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: '#FFF' }}>
                    <CampaignGroupAnalytics
                      hasDynamicGroups={campaignData.dynamic_groups.length > 0}
                      eventStats={eventStats}
                      groupStats={groupStats}
                    />
                  </AccordionDetails>
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion defaultChecked>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <div className={classes.flexContainer}>
                      <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                        {t('campaignDashboard.template')}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <SimulationPreview campaignData={campaignData} simulationsData={simulationsData[0]} />
                  </AccordionDetails>
                </CustomAccordion>
              </Grid>
            </>
          ) : (
            <>
              <Grid container direction={'column'} item xs={12}>
                <MultiSimPreviewTabs simulationsData={simulationsData} />
              </Grid>
              <Grid container direction={'column'} item xs={12}>
                <CustomAccordion defaultChecked>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                      {t('campaignDashboard.usersTable.users')}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CampaignUsersTable
                      campaignId={_id}
                      hasDistribution={true}
                      assetAllowUnverifiedDomains={assetAllowUnverifiedDomains}
                    />
                  </AccordionDetails>
                </CustomAccordion>
              </Grid>
              <Grid item xs={12}>
                <CustomAccordion defaultChecked>
                  <AccordionSummary
                    expandIcon={<FontAwesomeIcon icon={faChevronDown as IconProp} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <Typography gutterBottom color={'inherit'} variant={'subtitle2'}>
                      {t('campaignDashboard.groupAnalytics.groupAnalytics')}
                    </Typography>
                    <InfoTooltip
                      className={classes.infoTooltip}
                      title={t('campaignDashboard.groupAnalytics.tooltip')}
                    />
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: '#FFF' }}>
                    <CampaignGroupAnalytics
                      eventStats={eventStats}
                      groupStats={groupStats}
                      hasDynamicGroups={campaignData.dynamic_groups.length > 0}
                    />
                  </AccordionDetails>
                </CustomAccordion>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Box height={30} />
      {isNEW && (
        <FeedbackAnimation animationData={simulationsData.length > 1 ? packageLaunchAnimationData : undefined} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      padding: '0px 30px',
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      justifyContent: 'space-between',
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    reportButton: {
      marginLeft: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(2),
      border: '1.5px solid rgb(184 184 184)',
    },
    detailsContainer: {
      height: '100%',
      border: '1.5px solid rgb(184 184 184)',
    },
    statsContainer: {
      borderRadius: '15px',
      border: '1.5px solid rgb(184 184 184)',
      height: '100%',
      overflow: 'hidden',
    },
    scoreContainer: {
      height: '100%',
      overflow: 'hidden',
      border: '1.5px solid rgb(184 184 184)',
    },
    scoreMeterContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(6, 0),
    },
    chartsContainer: {
      padding: theme.spacing(2),
      width: '100%',
    },
    eventsContainer: {
      paddingBottom: '30px',
    },
    templateContainer: {
      border: '1.5px solid rgb(184 184 184)',
      padding: theme.spacing(2),
      minHeight: 600,
      display: 'flex',
      justifyContent: 'center',
    },
    userTableContainer: {
      border: '1.5px solid rgb(184 184 184)',
      flex: '1',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    flexContainer: {
      display: 'flex',
    },
    infoTooltip: {
      padding: '0px 8px',
    },
  })
)

const TitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#294D55',
  alignItems: 'stretch',
  padding: '12px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#E0EDEF',
  borderRadius: '15px 15px 0px 0px',
}))

const CustomAccordion = styled(Accordion)(() => ({
  margin: '0px',
  padding: '0px',
  borderRadius: '15px',
  border: '1.5px solid rgb(184 184 184)',
  background: '#E0EDEF',
  '.MuiAccordionDetails-root': {
    background: 'white',
    paddingTop: '15px',
    overflow: 'hidden',
    borderRadius: '0px 0px 15px 15px',
  },
  '.MuiButtonBase-root': {
    minHeight: '45px',
  },
  '.MuiAccordionSummary-content': {
    margin: '0px',
    padding: '0px',
  },
}))

export default CampaignDashboard
