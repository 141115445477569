import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useMembers } from '@/api/members/get'
import { User } from '@/api/users/users'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Table, { TalbeColumn } from '@/common/components/Table'
import GroupCellComponent from '@/common/components/Table/CellComponents/GroupCellComponent'
import OfficComponentCell from '@/common/components/Table/CellComponents/OfficeCellComponent'
import { RowDataItem } from '@/types/common'
import { Group } from '@/types/groups'
import { Member, MembersListQuery } from '@/types/members'

type UserTableProps = {
  handleEditUser: (user: User) => void
  handleOnboardUser: (ids: string[]) => void
  handleOffboardUser: (ids: string[]) => void
  handleAssignToGroups: (ids: string[]) => void
  handleAssignToOffice: (ids: string[]) => void
  groups?: Group[]
  queryParams: MembersListQuery
  updateQueryParam: (params: MembersListQuery) => void
}

const UsersTable: FC<UserTableProps> = ({
  handleEditUser,
  handleOffboardUser,
  handleOnboardUser,
  handleAssignToGroups,
  handleAssignToOffice,
  queryParams,
  updateQueryParam,
}) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  const { data: queryData, isFetching } = useMembers(queryParams) // TODO add as MembersListQuery
  const { results: members, total } = queryData || { total: 0, results: [] }

  const tableColumns: TalbeColumn[] = [
    {
      id: 'first_name',
      label: t('users.usersTable.name'),
      sortable: true,
      format: (row: Member) => `${row.first_name} ${row.last_name}`,
    },
    {
      id: 'username',
      sortable: true,
      label: t('users.usersTable.email'),
    },
    {
      id: 'phone_number',
      label: 'Phone Number',
      format: (row: Member) => row.phone_number || '-',
    },
    {
      id: 'groups',
      label: t('users.usersTable.groups'),
      component: (row: Member) => <GroupCellComponent memberOf={row.member_of} />,
    },
    {
      id: 'office_id',
      label: t('users.usersTable.office'),
      component: (row: Member) => <OfficComponentCell id={row.office_id} />,
    },
    {
      id: 'awareness_score',
      sortable: true,
      label: t('users.usersTable.score'),
      component: (row: Member) => <HumanizedScore score={row.awareness_score} />,
    },
    {
      id: 'state',
      sortable: true,
      label: t('users.usersTable.status'),
      format: (row: Member) => (row.state === 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive')),
    },
    {
      id: 'actions',
      label: '',
      component: (row: Member) => (
        <EditUserCellComponent
          row={row}
          handleEditUser={handleEditUser}
          handleOffboardUser={handleOffboardUser}
          handleOnboardUser={handleOnboardUser}
        />
      ),
    },
  ]

  const multiSelectActions = [
    {
      label: 'Activate',
      onClick: (userIds: string[]) => {
        handleOnboardUser(userIds)
      },
    },
    {
      label: 'Deactivate',
      onClick: (userIds: string[]) => {
        handleOffboardUser(userIds)
      },
    },
    {
      label: 'Assign To Groups',
      onClick: (userIds: string[]) => {
        handleAssignToGroups(userIds)
      },
    },
    {
      label: 'Assign to Office',
      onClick: (userIds: string[]) => {
        handleAssignToOffice(userIds)
      },
    },
  ]

  function handleNavigateToUserProfile(row: RowDataItem) {
    navigateTo(`/recipients/users/${row._id}`)
  }

  return (
    <Table
      loading={isFetching}
      data={members}
      total={total}
      inactiveRowCondition={(row) => row.state === 'inactive'}
      queryParams={queryParams}
      updateQueryParam={updateQueryParam}
      columns={tableColumns}
      selectable
      rowClick={handleNavigateToUserProfile}
      multiSelectBar={{
        selectedCountLable: 'groupProfile.usersTable.user_count',
        actions: multiSelectActions,
      }}
    />
  )
}

//TODO Move this to TableCell Components

type EditUserCellComponentProps = {
  row: Member
  handleEditUser: (user: User) => void
  handleOffboardUser: (ids: string[]) => void
  handleOnboardUser: (ids: string[]) => void
}

const EditUserCellComponent: FC<EditUserCellComponentProps> = ({
  row,
  handleEditUser,
  handleOffboardUser,
  handleOnboardUser,
}) => {
  const user = row
  const userId = user._id
  const isActive = user.state === 'active'

  const { t } = useTranslation()

  if (!row) return null

  return (
    <MoreMenu
      items={[
        {
          label: 'users.usersTable.edit',
          onClick: () => {
            handleEditUser(user)
          },
        },
        {
          label: isActive ? t('users.usersTable.deactivate') : t('users.usersTable.activate'),
          textColor: isActive ? '#c40956' : '#007059',
          fontWeight: 'bold',
          onClick: () => {
            isActive ? handleOffboardUser([userId]) : handleOnboardUser([userId])
          },
        },
      ]}
      ariaLabel={'users.usersTable.moreActions'}
    />
  )
}

export default UsersTable
