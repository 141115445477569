import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useClientDetails } from '@/api/client/client'
import { theme } from '@/theme/theme'
import { ImportStats } from '.'
import StepSubmit from './StepSubmit'
import UploadLoading from './UploadLoading'

type Step5Props = {
  fileUpload: (dryRun: boolean) => void
  isLoading: boolean
  handleClose: () => void
  importStats?: ImportStats
}

const Step5: FC<Step5Props> = ({ fileUpload, isLoading, handleClose, importStats }) => {
  //TODO: Consider moving out to parent component (also in step 4)
  const { data: clientDetails } = useClientDetails()
  const { t } = useTranslation()

  const usedSeats = (importStats?.active_newly_imported_count || 0) + (clientDetails?.members.active || 0)

  const handleUpload = () => {
    fileUpload(false)
  }

  return (
    <>
      {isLoading ? (
        <UploadLoading title="Uploading" />
      ) : (
        <Box display="flex" flexDirection="column" gap={2}>
          <Typography fontSize={18}> {t('bulkUserUpload.quotaValidation.title')}</Typography>
          <Typography> {t('bulkUserUpload.quotaValidation.description')}</Typography>
          <Box display="flex" gap={2}>
            <StyledCountBox>
              <Typography fontSize={20} fontWeight={theme.typography.fontWeightMedium}>
                {importStats?.active_imported_count.toLocaleString() || 0}
              </Typography>
              <Typography> {t('bulkUserUpload.quotaValidation.activeToBeImported')}</Typography>
            </StyledCountBox>
            <StyledCountBox hasError={!!importStats?.inactive_imported_count}>
              <Typography fontSize={20} fontWeight={theme.typography.fontWeightMedium}>
                {importStats?.inactive_imported_count.toLocaleString() || 0}
              </Typography>
              <Typography> {t('bulkUserUpload.quotaValidation.inactiveToBeImported')} </Typography>
            </StyledCountBox>
          </Box>
          <Typography>
            <Trans
              i18nKey={'bulkUserUpload.quotaValidation.note'}
              components={{ 1: <Typography component="span" fontWeight={theme.typography.fontWeightSemiBold} /> }}
            />
          </Typography>
          <StyledBorderBox>
            <Typography fontSize={20} fontWeight={theme.typography.fontWeightMedium}>
              <Trans
                i18nKey={'bulkUserUpload.quotaValidation.usedSeats'}
                values={{ usedSeats: usedSeats.toLocaleString() }}
              />
            </Typography>
            <Typography>
              <Trans
                i18nKey={'bulkUserUpload.quotaValidation.usedOf'}
                values={{
                  quotaSeats: clientDetails?.users_quota.toLocaleString(),
                  remainsSeats: ((clientDetails?.users_quota || 0) - usedSeats).toLocaleString(),
                }}
              />
            </Typography>
          </StyledBorderBox>
        </Box>
      )}

      <StepSubmit handleClose={handleClose} confirmHandler={handleUpload} isSubmitDisabled={isLoading} />
    </>
  )
}

const StyledBorderBox = styled(Box)(() => ({
  padding: theme.spacing(1),
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  border: `1px solid ${theme.palette.grey[400]}`,
}))

const StyledCountBox = styled(StyledBorderBox)(({ hasError }: { hasError?: boolean }) => ({
  border: `1px solid ${hasError ? theme.palette.pink[800] : theme.palette.green[800]}`,
}))

export default Step5
