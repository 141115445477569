import { Tooltip } from '@mui/material'
import { FC, useMemo } from 'react'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { Group } from '@/types/groups'

type KeyedGroup = {
  [key: string]: Group
}

type CellGroupProps = {
  memberOf: string[]
}

const GroupCellComponent: FC<CellGroupProps> = ({ memberOf }) => {
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)

  const groupsById = useMemo(() => {
    const byId = {} as KeyedGroup
    if (groupsData?.results) {
      groupsData?.results.forEach((group) => {
        byId[group._id] = group
      })
    }

    return byId
  }, [groupsData])

  const memberOfGroups = useMemo(
    () => memberOf.map((groupId) => groupsById[groupId]?.name).filter(Boolean),
    [memberOf, groupsById]
  )

  if (memberOfGroups.length === 0) return <>-</>

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 'auto',
      }}>
      <div
        style={{
          borderRadius: '4px',
          backgroundColor: '#ADEFF9',
          padding: '4px 4px',
          fontSize: '14px',
        }}>
        {memberOfGroups[0]}
      </div>
      {memberOfGroups.length > 1 && (
        <Tooltip
          placement={'right'}
          title={memberOfGroups
            .slice(1)
            .map((group) => group)
            .join(', ')}>
          <div
            style={{
              padding: '3px 8px',
              gap: '10px',
              borderRadius: '20px',
              border: '1px solid #000',
              marginLeft: '5px',
              verticalAlign: 'middle',
            }}>
            {`+${memberOfGroups.length - 1}`}
          </div>
        </Tooltip>
      )}
    </div>
  )
}

export default GroupCellComponent
