import { FC } from 'react'

import { useSignup } from '@/api/auth/signup'
import FutureFormWrapper from '@/common/components/Layout/FutureFormWrapper'
import SignUpForm from './SignUpForm'
import SignUpSuccess from './SignUpSuccess'

const SignUpPage: FC = () => {
  const [signup, { isSuccess }] = useSignup()

  return <FutureFormWrapper>{isSuccess ? <SignUpSuccess /> : <SignUpForm signupFunction={signup} />}</FutureFormWrapper>
}

export default SignUpPage
