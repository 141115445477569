export const detectAutofill = (element: HTMLInputElement): Promise<boolean> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button')
    }, 50)
  })
}

export const makeAnimationStartHandler =
  (stateSetter: React.Dispatch<React.SetStateAction<boolean>>, value: any) =>
  (e: React.AnimationEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const autofilledBrowser = Boolean((e.target as any)?.matches('*:-webkit-autofill'))
    const autofilled = autofilledBrowser || (value && value !== '')
    if (e.animationName === 'mui-auto-fill' || e.animationName === 'mui-auto-fill-cancel') {
      stateSetter(autofilled as boolean)
    }
  }
