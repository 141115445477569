import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faArrowRight, faLockKeyhole } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { styled } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { ALL_DATA_LIMIT } from '@/api/constants'
import useCreateMembersOffices from '@/api/members/createMembersOffices'
import { useDeleteOffice } from '@/api/offices/delete-office'
import { EditOfficeParams, useEditOffice } from '@/api/offices/edit-office'
import { useOffices } from '@/api/offices/get'
import { NewOfficeParams, useNewOffice } from '@/api/offices/new-office'
import { getErrorMessage } from '@/api/utils/get-error'
import removeImg from '@/assets/images/popup/delete.svg'
import CreateOfficeModal from '@/common/components/CreateOfficeModal/CreateOfficeModal'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import OfficesTable from './OfficesTable'
import Popup from '@/common/components/Popup'
import useToast from '@/common/hooks/useToast'
import { Office } from '@/types/offices'

type OfficesTabProps = {
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

const OfficesTab: FC<OfficesTabProps> = ({ queryParams, updateQueryParam }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { successToast, errorToast } = useToast()
  const { data: officesData, isLoading: officesIsLoading } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const [editOffice] = useEditOffice()
  const [deleteOffice] = useDeleteOffice()
  const [officeModalIsOpen, setOfficeModalIsOpen] = useState(false)
  const [newOfficeModalIsOpen, setNewOfficeModalIsOpen] = useState(false)
  const [officeToEdit, setOfficeToEdit] = useState<Office | undefined>()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [officeForDeletion, setOfficeForDeletion] = useState<Office | null>(null)
  const [newOfficeId, setNewOfficeId] = useState<string>('')
  const { mutateAsync: createMembersOffices } = useCreateMembersOffices()
  const [createNewOffice] = useNewOffice()

  const confirmButtonLabel =
    offices.length === 1
      ? officeForDeletion?.user_count === 0
        ? t('officesTable.removePopup.confirmRemoveButton')
        : t('officesTable.removePopup.createNewOffice')
      : officeForDeletion?.user_count ?? 0 > 0
      ? t('officesTable.removePopup.reassignMembers')
      : t('officesTable.removePopup.confirmRemoveButton')

  async function handleEditOffice(params: EditOfficeParams) {
    try {
      await editOffice(params)
      successToast(t('users.officeEdited'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
      throw e
    }
  }

  async function handleOfficeDeleteAlert(office: Office) {
    setOfficeForDeletion(office)
    setDeleteModalIsOpen(true)
  }

  async function handleCloseOfficeDelete() {
    setDeleteModalIsOpen(false)
    setOfficeForDeletion(null)
    setNewOfficeId('')
  }

  function openEditOfficeModal(office: Office) {
    setOfficeToEdit(office)
    setOfficeModalIsOpen(true)
  }

  async function handleDeleteOffice() {
    if (!officeForDeletion) return

    try {
      if (officeForDeletion.user_count === 0) {
        await deleteOffice(officeForDeletion._id)
      } else if (newOfficeId && (officeForDeletion.user_count ?? 0 > 0) && (offices.length ?? 1 > 1)) {
        await createMembersOffices({ ids: officeForDeletion.users, office: newOfficeId })
        await deleteOffice(officeForDeletion._id)
      } else {
        setNewOfficeModalIsOpen(true)
        handleCloseOfficeDelete()
        return
      }
      successToast(t('users.officeDeleted'))
      setDeleteModalIsOpen(false)
    } catch (e) {
      errorToast(t('users.errors.failedDeleteOffice'))
    }
  }

  async function handleCreateNewOffice(values: NewOfficeParams) {
    try {
      await createNewOffice(values)
      successToast(t('users.newOfficeSuccess'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
      throw e
    }
  }

  return (
    <>
      <Popup
        open={deleteModalIsOpen}
        onClose={handleCloseOfficeDelete}
        onConfirm={handleDeleteOffice}
        buttonLabels={{
          confirm: confirmButtonLabel,
          cancel: t('officesTable.removePopup.cancelButton'),
        }}
        icon={removeImg}>
        <h4>{t('officesTable.removePopup.title')}</h4>
        {officeForDeletion?.user_count === 0 ? (
          <p style={{ fontSize: '15px' }}>{t('officesTable.removePopup.noMembers')}</p>
        ) : (
          <>
            <p style={{ fontSize: '15px' }}>{t('officesTable.removePopup.assignedMembers')}</p>
            {offices.length === 1 ? (
              <p style={{ fontSize: '14px' }}>{t('officesTable.removePopup.hasNoOffices')}</p>
            ) : (
              <>
                <p style={{ fontSize: '15px' }}>{t('officesTable.removePopup.hasOffices')}</p>
                <Box className={classes.selectBox}>
                  <StyledTextField
                    disabled
                    placeholder={t('officesTable.removePopup.currentOffice')}
                    label={t('officesTable.removePopup.currentOffice')}
                    value={officeForDeletion?.name}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" style={{ cursor: 'default', width: '15px' }}>
                          <FontAwesomeIcon icon={faLockKeyhole as IconProp} fontSize="16" />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FontAwesomeIcon icon={faArrowRight as IconProp} fontSize={24} />
                  <FormControl>
                    <StyledInputLabel id="office-select-label">
                      {t('officesTable.removePopup.newOffice')}
                    </StyledInputLabel>
                    <StyledSelect
                      labelId="office-select-label"
                      value={newOfficeId}
                      label={t('officesTable.removePopup.newOffice')}>
                      {offices
                        .filter((office) => office._id !== officeForDeletion?._id)
                        .map((office) => (
                          <MenuItem key={office._id} value={office._id} onClick={() => setNewOfficeId(office._id)}>
                            {office.name}
                          </MenuItem>
                        ))}
                    </StyledSelect>
                  </FormControl>
                </Box>
                <p style={{ fontSize: '15px' }}>
                  <Trans
                    i18nKey={'officesTable.removePopup.alternativeChangeOffice'}
                    components={{ 1: <Link to="/recipients/members" /> }}
                  />
                </p>
              </>
            )}
          </>
        )}
      </Popup>
      <CreateOfficeModal
        showButton={false}
        open={officeModalIsOpen}
        setOpen={setOfficeModalIsOpen}
        createNewOffice={() => {}}
        saveOfficeEdit={handleEditOffice}
        buttonClass={classes.button}
        office={officeToEdit}
        onClose={() => setOfficeToEdit(undefined)}
      />
      <CreateOfficeModal
        saveOfficeEdit={() => {}}
        open={newOfficeModalIsOpen}
        setOpen={setNewOfficeModalIsOpen}
        createNewOffice={handleCreateNewOffice}
        buttonClass={classes.button}
        onClose={() => {}}
      />
      <div className={classes.root}>
        {!officesIsLoading ? (
          <OfficesTable
            clickable
            handleDeleteOffice={handleOfficeDeleteAlert}
            handleEditOffice={openEditOfficeModal}
            queryParams={queryParams}
            updateQueryParam={updateQueryParam}
          />
        ) : (
          <LoadingContainer height={400} />
        )}
      </div>
    </>
  )
}

const StyledInputLabel = styled(InputLabel)(() => ({
  fontFamily: 'Montserrat',
  '&.MuiInputLabel-root': {
    marginTop: '-7px',
  },
  '&.MuiFormLabel-filled': {
    marginTop: '0px',
  },
}))

const StyledSelect = styled(Select)(() => ({
  fontFamily: 'Montserrat',
  width: '200px',
  height: '42px',
  '& .MuiSelect-root': {
    width: '200px',
    height: '42px',
  },
}))

const StyledTextField = styled(TextField)(() => ({
  fontFamily: 'Montserrat',
  width: '200px',
  height: '42px',
  '& .MuiInputBase-root': {
    width: '200px',
    height: '42px',
  },
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginRight: theme.spacing(2),
    },
    selectBox: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-around',
      gap: '20px',
      alignItems: 'center',
    },
  })
)

export default OfficesTab
