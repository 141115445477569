import { useState } from 'react'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { useDeleteGroup } from '@/api/groups/delete-group'
import useToast from '@/common/hooks/useToast'
import { useTranslation } from 'react-i18next'
import GroupsTable from './GroupsTable'
import DeletionModal from '../DeletionModal'

type GroupsTabProps = {
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

export default function GroupsTab({ queryParams, updateQueryParam }: GroupsTabProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  const [deleteGroup] = useDeleteGroup()
  const { successToast, errorToast } = useToast()
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [groupForDeletion, setGroupForDeletion] = useState<string>('')

  async function handleGroupDeleteAlert(groupId: string) {
    setGroupForDeletion(groupId)
    setDeleteModalIsOpen(true)
  }

  async function handleCloseGroupDelete() {
    setDeleteModalIsOpen(false)
    setGroupForDeletion('')
  }

  async function handleDeleteGroup() {
    try {
      await deleteGroup(groupForDeletion)
      successToast(t('users.groupsTable.groupDeleted'))
      setDeleteModalIsOpen(false)
    } catch (e) {
      errorToast(t('users.groupsTable.errors.faliedDeleteGroup'))
    }
  }

  return (
    <div className={classes.root}>
      <DeletionModal
        confirmDeleteOpen={deleteModalIsOpen}
        handleCancelDelete={handleCloseGroupDelete}
        deleteIntegrationHandler={handleDeleteGroup}
        title={t('users.groupsTable.deleteGroup')}
        description={t('users.groupsTable.deleteGroupAlert')}
      />
      <GroupsTable
        handleDeleteGroup={handleGroupDeleteAlert}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
      />
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: 'auto',
      paddingBottom: '20px',
    },
  })
)
