import { Box, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { EducationModule } from '@/api/education-modules/modules'
import { useVideos } from '@/api/videos/get'
import Table, { TalbeColumn } from '@/common/components/Table'
import LibraryTitleCellComponent from '@/common/components/Table/CellComponents/LibraryTitleCellComponent'
import VideoLibraryActionsCellComponent from '@/common/components/Table/CellComponents/VideoLibraryActionsCellComponent'
import Tags from '@/common/components/Tags/Tags'
import useQueryParams from '@/common/hooks/useQueryParams'
import { secondsToTimestamp } from '@/common/utils/secondsToTimestamp'
import { useAuth } from '@/context/Auth'
import { RowDataItem, SortOrder } from '@/types/common'

type VideoLibraryTableProps = {
  openPreviewModal: (video: EducationModule) => void
  deleteModule: (video: EducationModule) => void
  launchModule: (video: EducationModule) => void
  saveModule: (video: EducationModule) => void
  queryFilters?: {
    name?: string
    languages?: string[]
    subtitles?: string[]
    tags?: string[]
  }
  videosByTopic: {
    [topic: string]: EducationModule[]
  }
  savedVideos: string[]
}

const initialQueryFilters: any = {
  search: '',
  languages: [],
  tags: [],
  sort_by: 'title',
  sort_order: SortOrder.desc,
  skip: 0,
  limit: 10,
}

const VideoLibraryTable: FC<VideoLibraryTableProps> = ({
  deleteModule,
  saveModule,
  queryFilters,
  savedVideos = [],
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { user } = useAuth()
  const navigateTo = useNavigate()
  const { queryParams, updateQueryParam } = useQueryParams(initialQueryFilters)
  const { data: videosData, isPending } = useVideos({ query: queryParams })

  useEffect(() => {
    updateQueryParam({ search: queryFilters?.name, languages: queryFilters?.languages, tags: queryFilters?.tags })
  }, [queryFilters])

  const tableColumns: TalbeColumn[] = [
    {
      id: 'title',
      label: t('videoLibrary.table.title'),
      sortable: true,
      component: (row) => (
        <LibraryTitleCellComponent
          title={row.title}
          isCustom={row.client_id === user?.current_client_id}
          isLesson={row.additional_resources?.lesson_plan}
        />
      ),
    },
    {
      id: 'duration',
      label: t('videoLibrary.table.duration'),
      sortable: true,
      component: (row) => {
        return row.duration ? secondsToTimestamp(row.duration) : ''
      },
    },
    {
      id: 'tags',
      label: t('videoLibrary.table.topics'),
      component: (row: any) => {
        return (
          <Tags
            justify="center"
            useChips={false}
            tags={(row.tags ?? []).map((tag: string) => ({
              label: tag,
            }))}
            limit={1}
          />
        )
      },
    },
    {
      id: 'number_of_quiz_questions',
      label: t('videoLibrary.table.quizQuestions'),
      sortable: true,
      component: (row: any) => <Typography>{row.number_of_quiz_questions || '-'}</Typography>,
    },
    {
      id: 'actions',
      label: '',
      component: (row) => (
        <VideoLibraryActionsCellComponent
          row={row}
          isSaved={savedVideos?.includes(row._id)}
          clientId={user?.current_client_id}
          saveFunction={saveModule}
          deleteFunction={deleteModule}
        />
      ),
    },
  ]

  const handleRowClick = (row: RowDataItem) => {
    if (!row._id) return
    navigateTo(`/content-library/videos/${row._id}`)
  }

  return (
    <Box padding={theme.spacing(2, 0, 4)}>
      <Table
        loading={isPending}
        error={false}
        data={videosData?.results as RowDataItem[]}
        total={videosData?.total || 0}
        queryParams={queryParams}
        updateQueryParam={updateQueryParam}
        columns={tableColumns}
        rowClick={handleRowClick}
      />
    </Box>
  )
}

export default VideoLibraryTable
