import { Box, MenuItem, Select, Tooltip } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC, useState } from 'react'

import { AssetAssignmentType } from '@/types/campaigns'
import { PhishingSimulationVector } from '@/types/phishingSimulations'
import { useTranslation } from 'react-i18next'
import { Asset } from './LaunchWizard'
import { RecepientsType } from './UserPicker'

type DistributionSelectProps = {
  type: RecepientsType
  itemId: string
  onChange: (type: RecepientsType, item: any, value: string) => void
  selectedItems?: { [id: string]: AssetAssignmentType.single_random | AssetAssignmentType.random_per_user | 'string' }
  assets: Asset[]
  availableAssets?: string[]
  disabled?: boolean
  disableUnverifiedDomain?: boolean
}

const DistributionSelect: FC<DistributionSelectProps> = ({
  type,
  itemId,
  onChange,
  selectedItems,
  assets,
  disabled,
  disableUnverifiedDomain,
}) => {
  const [value, setValue] = useState(selectedItems?.hasOwnProperty(itemId) ? selectedItems[itemId] : 'random_per_user')
  const { t } = useTranslation()

  return (
    <StyledSelect
      displayEmpty
      variant={'outlined'}
      margin={'none'}
      fullWidth
      disabled={disabled}
      onChange={(e) => {
        setValue(e.target.value)
        onChange(type, itemId, e.target.value)
      }}
      value={value}>
      <MenuItem value={'random_per_user'} onClick={(e) => e.stopPropagation()}>
        {'Random'}
      </MenuItem>
      {assets?.map((asset) =>
        asset.vectors.includes(PhishingSimulationVector.Email) && disableUnverifiedDomain ? (
          <StyledMenuItemDisabled>
            <Tooltip title={t('launchWizard.errors.unverifiedMembersInGroup')}>{asset.name}</Tooltip>
          </StyledMenuItemDisabled>
        ) : (
          <MenuItem value={asset.id} onClick={(e) => e.stopPropagation()}>
            {asset.name}
          </MenuItem>
        )
      )}
    </StyledSelect>
  )
}

// Add StyledBox because MenuItem with flag disabled blocks the tooltip
const StyledMenuItemDisabled = styled(Box)(({ theme }) => ({
  cursor: 'default',
  padding: theme.spacing(1, 2),
  color: theme.palette.grey[400],
}))

const StyledSelect = styled(Select)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  maxWidth: '200px',
  height: '36px',
  textAlign: 'left',
}))

export default DistributionSelect
