import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import successImage from '@/assets/images/auth/success.svg'
import { theme } from '@/theme/theme'

const SignUpSuccess: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  return (
    <Box width={568} display="flex" flexDirection="column" gap={3} alignItems={'center'}>
      <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} textAlign={'center'}>
        {t('signup.success')}
      </Typography>
      <Typography textAlign="center">{t('signup.successDescription')}</Typography>
      <Box padding={3}>
        <img src={successImage} alt="success" />
      </Box>
      <Box width="100%">
        <Button size="large" onClick={() => navigateTo('/sign-in')} variant={'contained'} sx={{ width: '100%' }}>
          {t('signup.goToSignIn')}
        </Button>
      </Box>
    </Box>
  )
}
export default SignUpSuccess
