import {
  Alert,
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Tooltip,
  Typography,
} from '@mui/material'
import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { theme } from '@/theme/theme'
import { BulkUploadConflict, BulkUploadConflictMember, UploadMemberConflictResolutionStrategy } from '@/types/members'
import { ConflictResolution } from '.'
import ConflictMemberCard from './ConflictMemberCard'
import StepSubmit from './StepSubmit'

const manualConflictsResolutionLimit = 10000

type Step3Props = {
  conflicts: BulkUploadConflict[]
  conflictResolution: any
  setConflictResolution: Dispatch<SetStateAction<ConflictResolution>>
  handleClose: () => void
  setStep: (step: number) => void
}

const Step3: FC<Step3Props> = ({ conflicts, conflictResolution, setConflictResolution, handleClose, setStep }) => {
  const { t } = useTranslation()

  const handleConflictResolutionMemberClick = (value: string, email: string) => {
    // Add member to _keep array and don't filter it when value is 'existing'
    // Add member to _overwrite array and don't filter it when value is 'incomming'
    setConflictResolution((prevState) => ({
      ...prevState,
      members_to_keep: [...prevState.members_to_keep, email].filter((m) => value === 'existing' || m !== email),
      members_to_overwrite: [...prevState.members_to_overwrite, email].filter(
        (m) => value === 'incomming' || m !== email
      ),
    }))
  }

  const identicalConflicts = useMemo(
    () =>
      conflicts
        .map(
          (conflict) =>
            JSON.stringify(conflict.existing) === JSON.stringify(conflict.incomming) && conflict.existing.email
        )
        .filter(Boolean) as string[],
    [conflicts]
  )

  const handleSelectAllResolution = (value: 'existing' | 'incomming') => {
    const emails = conflicts
      .map((conflict) => conflict.existing.email)
      .filter((email) => !identicalConflicts.includes(email))

    if (value === 'existing') {
      setConflictResolution((prevState) => ({
        ...prevState,
        members_to_keep: emails,
        members_to_overwrite: [],
      }))
    } else {
      setConflictResolution((prevState) => ({
        ...prevState,
        members_to_keep: [],
        members_to_overwrite: emails,
      }))
    }
  }

  const areConflictsResolved = useMemo(() => {
    if (conflicts.length === 0) {
      return true
    }

    if (conflictResolution.strategy === UploadMemberConflictResolutionStrategy.custom) {
      return (
        conflictResolution.members_to_keep.length +
          conflictResolution.members_to_overwrite.length +
          identicalConflicts.length ===
        conflicts.length
      )
    }

    if (conflictResolution.strategy && conflictResolution.strategy !== UploadMemberConflictResolutionStrategy.custom) {
      return true
    }

    return false
  }, [conflictResolution, conflicts])

  const handleContinue = () => {
    if (identicalConflicts.length) {
      setConflictResolution((prevState) => ({
        ...prevState,
        members_to_keep: [...prevState.members_to_keep, ...identicalConflicts],
      }))
    }

    setStep(4)
  }

  return (
    <>
      <Typography> {t('bulkUserUpload.membersConflicts.title')}</Typography>
      <Box display="flex" gap={2} paddingTop={2} alignItems="center">
        <StyledConflictsDetectBox hasConflicts={!!conflicts.length}>
          <Typography fontSize={20} fontWeight={theme.typography.fontWeightMedium}>
            {conflicts.length.toLocaleString()}
          </Typography>
          <Typography textAlign="center" whiteSpace="nowrap">
            {t('bulkUserUpload.membersConflicts.detectedConflicts')}
          </Typography>
        </StyledConflictsDetectBox>
        <Typography>
          {t(`bulkUserUpload.membersConflicts.${!!conflicts.length ? 'hasConflictsMessage' : 'noConflictsMessage'}`)}
        </Typography>
      </Box>
      {!!conflicts.length && (
        <Box display="flex" flexDirection="column">
          <StyledDivider />
          <Typography>{t('bulkUserUpload.membersConflicts.chooseStrategy')}</Typography>
          <RadioGroup
            sx={{ margin: theme.spacing(2, 1, 0) }}
            key="conflict_resolution_strategy"
            value={conflictResolution.strategy}
            onChange={(e) => {
              setConflictResolution((prevState) => ({
                ...prevState,
                strategy: e?.target?.value as UploadMemberConflictResolutionStrategy,
              }))
            }}>
            <Box display="flex" justifyContent="space-between">
              <FormControlLabel
                value={UploadMemberConflictResolutionStrategy.keep_existing}
                control={<Radio />}
                label={t('bulkUserUpload.membersConflicts.keepPlatformMembers')}
              />
              <FormControlLabel
                value={UploadMemberConflictResolutionStrategy.overwrite_existing}
                control={<Radio />}
                label={t('bulkUserUpload.membersConflicts.keepCsvMembers')}
              />
              {conflicts.length > manualConflictsResolutionLimit ? (
                <Tooltip title={t('bulkUserUpload.membersConflicts.customStrategyAvailableTooltip')} placement="top">
                  <FormControlLabel
                    value={UploadMemberConflictResolutionStrategy.custom}
                    control={<Radio />}
                    disabled={conflicts.length > manualConflictsResolutionLimit}
                    label={t('bulkUserUpload.membersConflicts.customStrategy')}
                  />
                </Tooltip>
              ) : (
                <FormControlLabel
                  value={UploadMemberConflictResolutionStrategy.custom}
                  control={<Radio />}
                  disabled={conflicts.length > manualConflictsResolutionLimit}
                  label={t('bulkUserUpload.membersConflicts.customStrategy')}
                />
              )}
            </Box>
          </RadioGroup>

          {conflictResolution.strategy === UploadMemberConflictResolutionStrategy.custom && (
            <>
              <StyledDivider sx={{ marginTop: 4, marginBottom: 1.5 }} />

              {conflicts.length === identicalConflicts.length ? (
                <Alert severity="warning" sx={{ marginBottom: 2 }}>
                  {t('bulkUserUpload.membersConflicts.allConflictsIdentical')}
                </Alert>
              ) : (
                <>
                  <Box display="flex">
                    <Typography flex="1" textAlign="center" fontSize="14px" fontWeight="bold" padding={0}>
                      {t('bulkUserUpload.membersConflicts.platformMembers')}
                    </Typography>
                    <Typography flex="1" textAlign="center" fontSize="14px" fontWeight="bold" padding={0}>
                      {t('bulkUserUpload.membersConflicts.fileMembers')}
                    </Typography>
                  </Box>

                  <StyledDivider sx={{ marginY: 1.5 }} />

                  <Box display="flex" marginRight={2.5} gap={0}>
                    <FormControlLabel
                      sx={{ flex: 1, margin: 0 }}
                      control={
                        <Checkbox
                          checked={
                            conflictResolution.members_to_keep.length + identicalConflicts.length === conflicts.length
                          }
                          onClick={() => handleSelectAllResolution('existing')}
                        />
                      }
                      label={t('bulkUserUpload.membersConflicts.selectAll')}
                    />
                    <FormControlLabel
                      sx={{ flex: 1, margin: 0 }}
                      control={
                        <Checkbox
                          checked={
                            conflictResolution.members_to_overwrite.length + identicalConflicts.length ===
                            conflicts.length
                          }
                          onClick={() => handleSelectAllResolution('incomming')}
                        />
                      }
                      label={t('bulkUserUpload.membersConflicts.selectAll')}
                    />
                  </Box>

                  <Box style={{ maxHeight: '50vh', overflow: 'scroll' }}>
                    {conflicts
                      .filter((conflict) => !identicalConflicts.includes(conflict.existing.email))
                      .map((conflict) => {
                        const matches = Object.keys(conflict.existing)
                          .map((key) => {
                            const typedKey = key as keyof BulkUploadConflictMember
                            if (
                              JSON.stringify(conflict.existing[typedKey]) ===
                              JSON.stringify(conflict.incomming[typedKey])
                            ) {
                              return key
                            }
                          })
                          .filter((key): key is string => key !== undefined) // filter out undefined values

                        return (
                          <>
                            <Box display="flex" paddingLeft={1.5}>
                              <FormControlLabel
                                sx={{ flex: 1, '.MuiFormControlLabel-label': { width: '100%' } }}
                                control={
                                  <Checkbox
                                    checked={conflictResolution.members_to_keep?.includes(conflict.existing.email)}
                                    onClick={() =>
                                      handleConflictResolutionMemberClick('existing', conflict.existing.email)
                                    }
                                  />
                                }
                                label={<ConflictMemberCard member={conflict.existing} matches={matches} />}
                              />
                              <FormControlLabel
                                sx={{ flex: 1, '.MuiFormControlLabel-label': { width: '100%' } }}
                                control={
                                  <Checkbox
                                    checked={conflictResolution.members_to_overwrite?.includes(
                                      conflict.incomming.email
                                    )}
                                    onClick={() =>
                                      handleConflictResolutionMemberClick('incomming', conflict.incomming.email)
                                    }
                                  />
                                }
                                label={<ConflictMemberCard member={conflict.incomming} matches={matches} />}
                              />
                            </Box>
                          </>
                        )
                      })}
                  </Box>
                </>
              )}
            </>
          )}
        </Box>
      )}

      <StepSubmit handleClose={handleClose} confirmHandler={handleContinue} isSubmitDisabled={!areConflictsResolved} />
    </>
  )
}

const StyledConflictsDetectBox = styled(Box)(({ hasConflicts }: { hasConflicts: boolean }) => ({
  padding: theme.spacing(1),
  borderRadius: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${hasConflicts ? theme.palette.pink[800] : theme.palette.green[800]}`,
}))

const StyledDivider = styled(Divider)(() => ({
  background: theme.palette.grey[400],
  margin: theme.spacing(3, 0, 2),
}))

export default Step3
