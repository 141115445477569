import { palette } from '../palette'
import LockDark from '@/assets/images/switch/locked-light.svg'

export const MuiSwitch = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      marginTop: '-2px',
      '&.locked': {
        '& .MuiSwitch-thumb': {
          backgroundImage: `url(${LockDark})`,
          color: palette.blueDianne[900],
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
        '& .MuiSwitch-track': {
          backgroundColor: `${palette.cyan[500]} !important`,
          outline: `1px solid ${palette.blueDianne[900]} !important`,
          opacity: 1,
        },
      },
    },
    switchBase: {
      padding: 8,
      color: palette.white,
      '&.Mui-checked': {
        color: palette.blueDianne[900],
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.cyan[500]} `,
          outline: `1px solid ${palette.blueDianne[900]}`,
          opacity: 1,
        },
        '&:hover': {
          background: `${palette.cyan[200]}50`,
          '&:active': {
            background: `${palette.cyan[700]}50`,
          },
        },
      },
      '&.Mui-disabled': {
        color: palette.grey[600],
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.grey[400]} `,
          outline: `1px solid ${palette.grey[600]}`,
          opacity: 1,
        },
      },
      '&.Mui-disabled.Mui-checked': {
        color: palette.grey[600],
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.grey[400]}`,
          outline: `1px solid ${palette.grey[600]}`,
          opacity: 1,
        },
      },
    },
    track: {
      borderRadius: 15,
      backgroundColor: palette.grey[400],
      outline: `1px solid ${palette.grey[600]}`,
      height: 16,
      width: 32,
      position: 'relative',
      opacity: 1,
    },
    thumb: {
      boxShadow: 'none',
      width: 14,
      height: 14,
      margin: 5,
    },
    colorSecondary: {
      color: palette.cyan[500],
      '&:hover': {
        background: `${palette.cyan[200]}50`,
      },
      '& + .MuiSwitch-track': {
        backgroundColor: `${palette.blueDianne[900]} !important`,
        outline: `1px solid ${palette.cyan[500]}`,
        opacity: 1,
      },
      '&.Mui-checked': {
        color: palette.cyan[500],
        transform: 'translateX(16px)',
        '& + .MuiSwitch-track': {
          backgroundColor: `${palette.blueDianne[900]} !important`,
          outline: `1px solid ${palette.cyan[500]}`,
          opacity: 1,
        },
        '&:hover': {
          background: `${palette.cyan[200]}50`,
        },
        '&:active': {
          background: `${palette.cyan[700]}50`,
        },
      },
      '&:active': {
        background: `${palette.cyan[700]}50`,
      },
    },
  },
}
