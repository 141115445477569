import { FC, useMemo } from 'react'

import { ALL_DATA_LIMIT } from '@/api/constants'

import { useOffices } from '@/api/offices/get'

type OfficeCellProps = {
  id: string
}

const OfficComponentCell: FC<OfficeCellProps> = ({ id }) => {
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)

  const officeName = useMemo(
    () => officesData?.results?.find((office) => office._id === id)?.name || '',
    [id, officesData]
  )

  //   TODO Replace this styles with chip
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        margin: 'auto',
      }}>
      <div
        style={{
          borderRadius: '4px',
          backgroundColor: '#ADEFF9',
          padding: '2px 4px',
          fontSize: '14px',
        }}>
        {officeName}
      </div>
    </div>
  )
}

export default OfficComponentCell
