import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBoxOpen, faPenToSquare, faTrash, faHeart as heartIcon } from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, IconButton, styled } from '@mui/material'
import { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { LayoutContext } from '@/common/components/Layout/Layout'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import {
  PhishingSimulation,
  PhishingSimulationAndPackages,
  PhishingSimulationPackage,
} from '@/types/phishingSimulations'

type SimulationLibraryActionsCellComponentProps = {
  row: PhishingSimulationAndPackages
  isMultiSimulationSideMenuOpen: boolean
  isPackage: boolean
  editSimulationPackage: (simulationPackage: PhishingSimulationPackage) => void
  isSaved: boolean
  saveFunction: (id: string) => void
  deleteFunction: (simulation: PhishingSimulation) => void
}

const SimulationLibraryActionsCellComponent: FC<SimulationLibraryActionsCellComponentProps> = ({
  row,
  isPackage,
  saveFunction,
  editSimulationPackage,
  deleteFunction,
  isSaved,
  isMultiSimulationSideMenuOpen,
}) => {
  const { user } = useAuth()
  const { addToSimulationPackage } = useContext(LayoutContext)
  const { t } = useTranslation()
  return (
    <StyledActionsContainer>
      {isMultiSimulationSideMenuOpen && !isPackage ? (
        <Button
          sx={{ minWidth: '88px' }}
          onClick={(e) => {
            addToSimulationPackage(row)
            e.stopPropagation()
          }}
          variant={'contained'}
          size="small"
          endIcon={<FontAwesomeIcon icon={faBoxOpen as IconProp} />}>
          {t('simulationLibrary.addTo')}
        </Button>
      ) : (
        <>
          <StyledIconButton
            onClick={(e) => {
              e.stopPropagation()
              saveFunction(row._id)
            }}
            size="small"
            color={'inherit'}>
            <FontAwesomeIcon
              icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
              fontSize={22}
              color={theme.palette.pink[500]}
            />
          </StyledIconButton>
          <StyledIconButton
            disabled={
              isPackage ? row.organization_id !== user?.current_client_id : !(row as PhishingSimulation).ai_generated
            } // && !rowItem.simulations add when activate delete.
            onClick={(e) => {
              e.stopPropagation()
              deleteFunction(row)
            }}
            size="small"
            color={'inherit'}>
            <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
          </StyledIconButton>
          {!!isPackage && (
            <StyledIconButton
              disabled={row.organization_id !== user?.current_client_id}
              onClick={(e) => {
                e.stopPropagation()
                editSimulationPackage(row)
              }}
              size="small"
              color={'inherit'}>
              <FontAwesomeIcon icon={faPenToSquare as IconProp} fontSize={22} />
            </StyledIconButton>
          )}
        </>
      )}
    </StyledActionsContainer>
  )
}

const StyledActionsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const StyledIconButton = styled(IconButton)(() => ({
  margin: theme.spacing(0, 0.5),
  color: theme.palette.blueGray[900],
}))

export default SimulationLibraryActionsCellComponent
