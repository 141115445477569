import axios from 'axios'
import { API_URL } from '../constants'
import { MicrosoftEntraIdAuthenticationData, OnPremiseAuthenticationData } from './test-connection'

import { useQuery, QueryOptions, useMutation } from '@tanstack/react-query'
import { IntegrationAssets } from '@/types/integrations'
import { IntegrationsEntitiesRequest } from '@/types/integrations'
import { paramsSerializer } from '../utils/serialize-array'

export const getIntegrationEntities = async (integrationId: string, entities: IntegrationsEntitiesRequest) => {
  const url = `${API_URL}/integrations/${integrationId}/entities/`

  const { data } = await axios.get(url, { params: entities, withCredentials: true, paramsSerializer: paramsSerializer })
  return data
}

export const getIntegrationEntitiesWithCredentials = async (
  entities: IntegrationsEntitiesRequest,
  authData: OnPremiseAuthenticationData | MicrosoftEntraIdAuthenticationData
) => {
  const url = `${API_URL}/integrations/entities/`
  let params
  if (authData.type === 'ldap') {
    const ldapAuthData = (authData as OnPremiseAuthenticationData).authentication
    params = {
      type: authData.type,
      authentication: {
        host: ldapAuthData.host,
        user: ldapAuthData.user,
        use_ssl: ldapAuthData.useSsl,
        password: ldapAuthData.password,
        port: ldapAuthData.port,
      },
    }
  } else if (authData.type === 'ms-graph') {
    const adAzureAuthData = (authData as MicrosoftEntraIdAuthenticationData).authentication
    params = {
      type: authData.type,
      authentication: {
        tenant_id: adAzureAuthData.tenantId,
        client_secret: adAzureAuthData.clientSecret,
        client_id: adAzureAuthData.clientId,
      },
    }
  }
  const { data } = await axios.post(url, params, {
    params: entities,
    withCredentials: true,
    paramsSerializer: paramsSerializer,
  })
  return data
}

export function useIntegrationEntities(
  integration_id: string,
  entities: IntegrationsEntitiesRequest,
  options?: QueryOptions<any>
) {
  return useQuery<IntegrationAssets>({
    queryKey: ['integrationAssets', integration_id, { ...entities }],
    queryFn: () => getIntegrationEntities(integration_id, entities),
    ...options,
  })
}

export function useIntegrationEntitiesWithCredentials(options?: QueryOptions<any>) {
  return useMutation({
    mutationFn: (variables: {
      entities: IntegrationsEntitiesRequest
      authData: OnPremiseAuthenticationData | MicrosoftEntraIdAuthenticationData
    }) => getIntegrationEntitiesWithCredentials(variables.entities, variables.authData),
    ...options,
  })
}
