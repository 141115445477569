import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { Trans, useTranslation } from 'react-i18next'
import { MutateFunction } from 'react-query'
import { Link } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { SignupParams, SignupResponse } from '@/api/auth/signup'
import { PHONE_NUMNER_E164_REGEX } from '@/api/constants'
import { useGetLatestTerms } from '@/api/terms/get'
import { ServerError } from '@/api/types'
import Checkbox from '@/common/components/Inputs/Checkbox'
import PasswordField from '@/common/components/Inputs/PasswordField'
import PhoneNumberField from '@/common/components/Inputs/PhoneNumberField'
import TextField from '@/common/components/Inputs/TextFieldV2'
import PasswordStrengthEvaluation from '@/pages/Settings/Admins/PasswordStrengthEvaluation'
import { theme } from '@/theme/theme'

const SignUpForm: FC<{ signupFunction: MutateFunction<SignupResponse, ServerError, SignupParams, unknown> }> = ({
  signupFunction,
}) => {
  const { t } = useTranslation()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { data: latestTerms } = useGetLatestTerms()

  const handleSubmit = async (
    values: {
      organizationName: string
      organizationDomain: string
      promoCode: string
      fullName: string
      email: string
      phoneNumber: string
      acceptTerms: boolean
      password: string
    },
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    try {
      const recaptcha = (executeRecaptcha && (await executeRecaptcha('signup'))) || ''
      await signupFunction({
        username: values.email,
        full_name: values.fullName,
        password: values.password,
        company_domains: [values.organizationDomain],
        recaptcha,
        company_name: values.organizationName,
        phone_number: values.phoneNumber,
        promo_code: values.promoCode,
        accept_terms: values.acceptTerms,
        terms: latestTerms?._id as string,
      })
    } catch (e) {
      if ((e as ServerError).response.data.message === 'A user with this username already exists: ' + values.email)
        setFieldError('email', 'A user with this email already exists')
      if (
        (e as ServerError).response.data.message ===
        'Failed to sign up new user, one or more domains are already registered'
      )
        setFieldError('organizationDomain', 'This domain is already registered')
      if ((e as ServerError).response.data.message === 'Promo code is invalid or expired')
        setFieldError('promoCode', 'Promo code is invalid or expired')
    }
  }
  const initialValues = {
    organizationName: '',
    organizationDomain: '',
    promoCode: '',
    fullName: '',
    email: '',
    phoneNumber: '',
    acceptTerms: false,
    password: '',
    confirmPassword: '',
  }
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string().required(t('signup.errors.missingCompanyName')),
    organizationDomain: Yup.string().required(t('signup.errors.missingDomain')),
    fullName: Yup.string().required(t('signup.errors.missingFullName')),
    email: Yup.string().email(t('signup.errors.invalidEmail')).required(t('signup.errors.missingEmail')),
    phoneNumber: Yup.string()
      .matches(PHONE_NUMNER_E164_REGEX, t('signup.errors.invalidPhone'))
      .required(t('signup.errors.missingPhone')),
    acceptTerms: Yup.boolean().oneOf([true]),
    password: Yup.string().required(t('signup.errors.missingPassword')),
    confirmPassword: Yup.string().test(
      'confirm-new-password-check',
      t('signup.errors.passwordsDontMatch'),
      function (this: Yup.TestContext, value: string | undefined) {
        const { password } = this.parent
        if (!password && !value) return true
        return value === password || this.createError({ message: "Passwords don't match" })
      }
    ),
  })
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty, values }) => (
        <Form>
          <Box display="flex" flexDirection="column" gap={2} width="668px">
            <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} textAlign={'center'}>
              {t('signup.signup')}
            </Typography>
            <Typography fontWeight={theme.typography.fontWeightMedium}>{t('signup.organizationDetails')}</Typography>
            <Box display="flex" gap={2} marginRight={-1.5}>
              <TextField required label="Organization Name" name="organizationName" />
              <TextField required label="Organization Domain" name="organizationDomain" />
            </Box>
            <TextField label="Promo Code" name="promoCode" />
            <Typography fontWeight={theme.typography.fontWeightMedium}>{t('signup.adminDetails')}</Typography>
            <TextField required label="Full Name" name="fullName" />
            <Box display="flex" gap={2}>
              <TextField required label="Email" name="email" />
              <PhoneNumberField name="phoneNumber" label="Phone Number" onChange={() => {}} />
            </Box>
            <Box display="flex" gap={3.5} marginRight={0}>
              <PasswordField label={t('setPassword.newPassword')} name="password" required />
              <PasswordField label={t('setPassword.confirmPassword')} name="confirmPassword" required />
            </Box>
            {!!values.password && <PasswordStrengthEvaluation password={values.password} size="large" />}
            <Checkbox name="acceptTerms" label={termsLable} />
            <Box sx={{ width: '100%', paddingRight: 0.5 }}>
              <LoadingButton
                size="large"
                type="submit"
                loading={isSubmitting}
                variant="contained"
                disabled={!dirty || !isValid}
                sx={{ width: '100%' }}>
                {t('signup.signup')}
              </LoadingButton>
            </Box>
            <Typography textAlign="center" fontWeight={theme.typography.fontWeightMedium}>
              <Trans
                i18nKey={'signup.backTo'}
                components={{
                  1: (
                    <Link
                      to="/sign-in"
                      style={{
                        color: theme.palette.blue[900],
                        fontWeight: theme.typography.fontWeightSemiBold,
                        textDecoration: 'none',
                      }}
                    />
                  ),
                }}
              />
            </Typography>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const termsLable = (
  <Typography
    style={{
      fontWeight: theme.typography.fontWeightSemiBold,
    }}>
    <Trans
      i18nKey={'signup.form.terms'}
      components={{
        1: (
          <Link
            to={'https://cywareness.io/terms-of-service/'}
            target="_blank"
            style={{
              color: theme.palette.blue[900],
            }}
          />
        ),
        2: (
          <Link
            style={{
              color: theme.palette.blue[900],
            }}
            color={theme.palette.blue[900]}
            to={'https://cywareness.io/privacy-policy/'}
            target="_blank"
          />
        ),
      }}
    />
  </Typography>
)
export default SignUpForm
