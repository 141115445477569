import { useChangePassword } from '@/api/profile/edit-change-password'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, styled } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import * as Yup from 'yup'

import { CurrentUser } from '@/api/profile/current-user'
import { getErrorMessage } from '@/api/utils/get-error'
import PasswordField from '@/common/components/Inputs/PasswordField'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { StyledButtonsWraper, StyledTitle } from '.'

const AdminProfileSecuritySection: FC<{ user: CurrentUser | undefined }> = ({ user }) => {
  const { successToast, errorToast } = useToast()
  const { t } = useTranslation()
  const [changePassword] = useChangePassword()

  const changePasswordValidationSchema = Yup.object().shape({
    current_password: Yup.string().test(
      'current-password-check',
      'Current password is required',
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password, confirm_new_password } = this.parent
        return new_password || confirm_new_password ? !!value && value?.length >= 8 : true
      }
    ),
    confirm_new_password: Yup.string().test(
      'confirm-new-password-check',
      "Passwords don't match",
      function (this: Yup.TestContext, value: string | undefined) {
        const { new_password } = this.parent
        if (!new_password && !value) return true
        return value === new_password || this.createError({ message: "Passwords don't match" })
      }
    ),
  })

  const handleSubmitChangePassword = async (values: { current_password: string; new_password: string }) => {
    try {
      await changePassword(values)
      successToast(t(`settings.admins.profile.passwordSuccessToast`))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t(`settings.admins.profile.passwordErrorToast`))
    }
  }

  return (
    <>
      <StyledTitle>{t(`settings.admins.profile.resetPassword`)}</StyledTitle>
      <Formik
        onSubmit={handleSubmitChangePassword}
        initialValues={{
          current_password: '',
          new_password: '',
          confirm_new_password: '',
        }}
        validationSchema={changePasswordValidationSchema}>
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Box display="flex" flexWrap="wrap">
              <Box padding={theme.spacing(2, 2, 0)} width="920px">
                <Box display={'flex'} flexDirection={'column'} gap={2}>
                  <Box display="flex" maxWidth="888px" gap={theme.spacing(6)}>
                    <PasswordField
                      label={t('settings.configurationTab.resetPassword.currentPassword')}
                      name="current_password"
                    />
                    <Box width="100%" />
                  </Box>
                  <Box display="flex" maxWidth="888px" gap={theme.spacing(6)}>
                    <PasswordField
                      label={t('settings.configurationTab.resetPassword.newPassword')}
                      name="new_password"
                      withStrengthIndicator
                    />
                    <PasswordField
                      label={t('settings.configurationTab.resetPassword.confirmNewPassword')}
                      name="confirm_new_password"
                    />
                  </Box>
                </Box>
              </Box>

              <StyledButtonsWraper>
                <LoadingButton type="submit" loading={isSubmitting} variant="contained" disabled={!dirty || !isValid}>
                  {t(`settings.admins.profile.savePassword`)}
                </LoadingButton>
              </StyledButtonsWraper>
            </Box>
          </Form>
        )}
      </Formik>
    </>
  )
}

const StyledSwitchWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: theme.spacing(1),
}))
export default AdminProfileSecuritySection
