import { Box, keyframes, styled, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import cywarenessLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import Fingerprint from '@/assets/images/logo-clean-blue.svg'
import SmartCityGif from '@/assets/images/smart-city.gif'
import { theme } from '@/theme/theme'

const FutureFormWrapper: FC<{ children: ReactNode }> = ({ children }) => {
  const { t } = useTranslation()
  return (
    <StyledConentContainer>
      <StyledBackgroundLogo />
      <StyledLeftSide>
        <StyledPattayaTypography fontSize="96px">{t('settings.admins.invitation.welcome')}</StyledPattayaTypography>
        <StyledPattayaTypography fontSize="48px">
          {t('settings.admins.invitation.toTheFuture')} <br /> {t('settings.admins.invitation.cyberAwereness')}
        </StyledPattayaTypography>
        <StyledGifBox />
      </StyledLeftSide>
      <StyledRightSide>
        <FormWrapper logo={cywarenessLogo}>{children}</FormWrapper>
      </StyledRightSide>
    </StyledConentContainer>
  )
}

const FormWrapper: FC<{ logo: string; children: ReactNode }> = ({ logo, children }) => {
  const { t } = useTranslation()
  return (
    <StyledCard>
      <Box margin={2}>
        <img src={logo} />
      </Box>
      {children}
      <Box mt={3}>
        <Typography variant="body2" color="textSecondary" align="center">
          {t('login.copyright', { year: new Date().getFullYear() })}
        </Typography>
      </Box>
    </StyledCard>
  )
}

const upAnimation = keyframes`
0% {
  transform: translate(0px, 600px);
}
50% {
  transform: translate(0px, 600px);
}
90% {
  transform: translate(0px, -10px);
}
100% {
  transform: translate(0px, 0px);
}
`
const downAnimation = keyframes`
  0% {
    transform: translate(0px, -600px);
  }
  50% {
    transform: translate(0px, -600px);
  }
  90% {
    transform: translate(0px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
`

const StyledCard = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: 'fit-content',
  zIndex: 1,
  borderRadius: '20px',
  backgroundColor: 'white',
  padding: theme.spacing(3),
  margin: theme.spacing(3),
}))

const StyledRightSide = styled(Box)(() => ({
  display: 'flex',
  flexGrow: 3,
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledLeftSide = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexGrow: 2,
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  overflow: 'hidden',
  minWidth: '534px',
  height: '100vh',
}))

const StyledGifBox = styled(Box)(() => ({
  backgroundImage: `url(${SmartCityGif})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '60%',
  backgroundPosition: 'center',
  width: '100%',
  height: '100%',
  maxHeight: '380px',
  margin: '10% 0px 0px',
  animation: `${upAnimation} 7s ease`,
}))

const StyledConentContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  margin: '0px',
  width: '100%',
  height: '100%',
  justifyContent: 'flex-end',
  backgroundImage: `linear-gradient(35deg, ${theme.palette.white}, ${theme.palette.teal[50]} )`,
}))

const StyledBackgroundLogo = styled(Box)(() => ({
  backgroundImage: `url(${Fingerprint})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  position: 'fixed',
  opacity: '25%',
  bottom: '-20%',
  right: '-10%',
  width: '80%',
  minWidth: '500px',
  minHeight: '500px',
  height: '80%',
  scale: '-1 1',
}))

const StyledPattayaTypography = styled(Typography)(({ fontSize }: { fontSize: string }) => ({
  fontFamily: 'Pattaya',
  fontWeight: theme.typography.fontWeightRegular,
  background: `linear-gradient(${theme.palette.blueGray[200]}, ${theme.palette.blueDianne[900]} ,${theme.palette.grey[900]})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'center',
  fontSize: fontSize,
  padding: '0px 10px',
  marginBottom: '-20px',
  animation: `${downAnimation} 7s ease`,
}))

export default FutureFormWrapper
