import { FC, ChangeEvent, ReactElement } from 'react'
import { useField, FieldHookConfig } from 'formik'
import FormControlLabel from '@mui/material/FormControlLabel'
import MuiCheckbox, { CheckboxProps as MuiCheckboxProps } from '@mui/material/Checkbox'

interface CheckboxProps extends Omit<MuiCheckboxProps, 'name' | 'onChange'>, Pick<FieldHookConfig<string>, 'name'> {
  label: string | ReactElement
  disableFormik?: boolean
  onChange?: MuiCheckboxProps['onChange']
}

const Checkbox: FC<CheckboxProps> = ({ name, label, disableFormik = false, onChange, ...props }) => {
  const [field, , helpers] = useField(name)

  const isChecked = disableFormik ? !!props.checked : field.value

  const handleChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (!disableFormik) {
      helpers.setValue(checked)
    }

    if (onChange) {
      onChange(event, checked)
    }
  }

  const configCheckbox: MuiCheckboxProps & { checked: boolean } = {
    ...props,
    disableRipple: true,
    checked: isChecked,
    onChange: handleChange,
  }

  return (
    <FormControlLabel
      sx={{
        '& .MuiButtonBase-root': {
          py: 0,
        },
      }}
      control={<MuiCheckbox {...configCheckbox} />}
      label={label}
    />
  )
}

export default Checkbox
