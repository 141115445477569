import moment from 'moment'
import momentTz from 'moment-timezone'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useOffices } from '@/api/offices/get'
import { countriesArray } from '@/assets/countries'
import { languages } from '@/assets/locales/languages'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Table, { TalbeColumn } from '@/common/components/Table'
import { theme } from '@/theme/theme'
import { Office, OfficeWorkingHours } from '@/types/offices'

type OfficesTableProps = {
  handleDeleteOffice: (office: Office) => void
  handleEditOffice: (office: Office) => void
  clickable?: boolean
  queryParams: Record<string, string>
  updateQueryParam: (params: Record<string, string>) => void
}

export default function OfficesTable({
  handleDeleteOffice,
  handleEditOffice,
  clickable = false,
  queryParams,
  updateQueryParam,
}: OfficesTableProps) {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: queryData, isFetching } = useOffices(queryParams)
  const { results: offices, total } = queryData || { total: 0, results: [] }

  function getOfficeLanguage(languageCode: string) {
    try {
      const language = languages[languageCode]
      return language?.name
    } catch (e) {
      return ''
    }
  }

  function getOfficeCountry(countryCode: string) {
    let countryName = ''
    countriesArray.map((country) => {
      if (country.code === countryCode) {
        countryName = country?.name
      }
    })
    return countryName
  }

  function getOfficeTimeZone(timeZone: string) {
    try {
      const timeZoneName = momentTz.tz(timeZone).format('Z')
      return `GMT${timeZoneName.substring(0, 3)}`
    } catch (e) {
      return ''
    }
  }

  const formatWorkingHours = useCallback(
    ({ days, opening, closing }: OfficeWorkingHours): string => {
      let daysString = ''
      const daysArray = Object.keys(days)
      let i
      for (i = 0; i < daysArray.length; i++) {
        if (days[daysArray[i]]) {
          if (days[daysArray[i]] && !daysString) {
            daysString = t(`createOfficeModal.days.${daysArray[i]}`)
          } else {
            if (daysString && days[daysArray[i - 1]]) {
              if (days[daysArray[i + 1]]) {
              } else {
                daysString = daysString + ` - ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            } else {
              if (daysString && !days[daysArray[i - 1]]) {
                daysString = daysString + `, ${t(`createOfficeModal.days.${daysArray[i]}`)}`
              }
            }
          }
        }
      }

      return `${moment().hour(opening.h).minute(opening.m).format('HH:mm')}~${moment()
        .hour(closing.h)
        .minute(closing.m)
        .format('HH:mm')} | ${daysString}`
    },
    [t]
  )

  const tableColumns: TalbeColumn[] = [
    {
      id: 'name',
      label: 'Name',
      sortable: true,
    },
    {
      id: 'number_of_users',
      label: 'No. Of Members',
      sortable: true,
    },
    {
      id: 'language',
      label: 'Language',
      sortable: true,
      format: (row: Office) => (row.language ? getOfficeLanguage(row.language) : ''),
    },
    {
      id: 'geo_location',
      label: 'Country',
      sortable: true,
      format: (row: Office) => (row.geo_location ? getOfficeCountry(row.geo_location) : ''),
    },
    {
      id: 'time_zone',
      label: t('officesTable.timezone'),
      format: (row: Office) => (row.time_zone ? getOfficeTimeZone(row.time_zone) : ''),
    },
    {
      id: 'working_hours',
      label: t('officesTable.workingDays'),
      format: (row: Office) => (row.working_hours.hasOwnProperty('days') ? formatWorkingHours(row.working_hours) : ''), //TODO remove this when working_hours strings are removed from the DB
    },
    {
      id: 'awareness_score',
      label: t('users.groupsTable.score'),
      sortable: true,
      component: (row: Office) => <HumanizedScore score={row.awareness_score} />,
    },
    {
      id: 'actions',
      label: '',
      component: (row) => (
        <MoreMenu
          items={[
            {
              label: 'simulatorOnboarding.groups.groupsTable.edit',
              onClick: () => handleEditOffice(row),
            },
            {
              label: 'simulatorOnboarding.groups.groupsTable.remove',
              textColor: theme.palette.error.main,
              onClick: () => handleDeleteOffice(row),
            },
          ]}
          ariaLabel={'simulatorOnboarding.groups.groupsTable.moreActions'}
        />
      ),
    },
  ]

  function handleNavigateToOfficeProfile(id: string) {
    navigateTo(`/recipients/offices/${id}`)
  }

  return (
    <Table
      loading={isFetching}
      data={offices}
      total={total}
      inactiveRowCondition={(row) => row.state === 'inactive'}
      queryParams={queryParams}
      updateQueryParam={updateQueryParam}
      columns={tableColumns}
      rowClick={(row) => {
        if (clickable) handleNavigateToOfficeProfile(row._id)
      }}
    />
  )
}
