import { theme } from '@/theme/theme'
import { Box, styled } from '@mui/material'

export const ModalConent = styled(Box)(() => ({
  position: 'absolute',
  top: '40%',
  left: '50%',
  transform: 'translate(-50%, -40%)',
  minWidth: '800px',
  padding: '30px',
  display: 'flex',
  backgroundColor: 'white',
  borderRadius: '10px',
  '&:focus-visible': {
    outline: 'none',
  },
}))

export const ImageWrapper = styled(Box)(() => ({
  width: '192px',
  height: '192px',
  img: {
    width: '100%',
    height: '100%',
  },
}))

export const ContentWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flex: 1,
  gap: '30px',
}))

export const StyledTypography = styled(Box)(() => ({
  fontFamily: 'Montserrat',
  fontSize: '14px',
  paddingBottom: theme.spacing(2),
  h4: {
    margin: theme.spacing(0, 0, 2),
    fontSize: '18px',
    fontWeight: 700,
  },
  ul: {
    margin: theme.spacing(0, 0, 0, -2.5),
    '& li::marker': {
      fontSize: '0.7em',
    },
  },
}))

export const ButtonsWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '8px',
}))
