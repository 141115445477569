import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCalculatorSimple, faHouse } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Divider, Typography, styled } from '@mui/material'
import { FC, useMemo } from 'react'

import { useOrganizations } from '@/api/organizations/get'
import CywarenessBackground from '@/assets/images/logo-light-blue.svg'
import TreeChart from '@/common/components/AccountCenter/TreeChart'
import { OrganizationListItem } from '@/types/organizations'
import { useTranslation } from 'react-i18next'
import { theme } from '@/theme/theme'
import { useNavigate } from 'react-router-dom'
import { useClientDetails } from '@/api/client/client'
import { OrganizationAccountType } from '@/types/organizations'

export type OrganizationCardData = OrganizationListItem & {
  id: string
  parentId: string
  allow_support_admin_access?: boolean
  _highlighted: boolean
  _expanded: boolean
}

const AccountCenter: FC = () => {
  const { data } = useOrganizations()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: client } = useClientDetails()

  const treeChartData: OrganizationCardData[] = useMemo(() => {
    if (!data?.length) return []

    const fakeRoot = {
      id: 'fake_root',
      name: 'Account Center',
      parentId: null,
    }

    const organizations = data.map((organization) => ({
      ...organization,
      id: organization._id,
      parentId: organization.parent_id === null ? 'fake_root' : organization.parent_id,
    }))

    return [fakeRoot, ...organizations]
  }, [data])

  return (
    <StyledRootBox>
      <StyledHeader>
        <Box display="flex" justifyContent="flex-end" gap={theme.spacing(1)} padding={theme.spacing(1.5, 1, 2.5)}>
          {(client?.type === OrganizationAccountType.direct_reseller ||
            client?.type === OrganizationAccountType.distributor) && (
            <Button
              variant="outlined"
              onClick={() => {
                navigateTo('/account-center/pricing-calculator')
              }}
              endIcon={<FontAwesomeIcon icon={faCalculatorSimple as IconProp} />}>
              {t('accountCenter.pricingCalculator')}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={() => {
              navigateTo('/')
            }}
            endIcon={<FontAwesomeIcon icon={faHouse as IconProp} />}>
            {t('accountCenter.backToDashboard')}
          </Button>
        </Box>
        <Divider color={theme.palette.grey[400]}></Divider>
        <Typography fontWeight={theme.typography.fontWeightBold} paddingLeft={theme.spacing(2)}>
          {t('accountCenter.accountCenter')}
        </Typography>
      </StyledHeader>
      <TreeChart data={treeChartData} />
    </StyledRootBox>
  )
}

const StyledRootBox = styled(Box)(() => ({
  height: '100vh',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  backgroundImage: `url(${CywarenessBackground})`,
  backgroundPosition: 'bottom right',
  backgroundRepeat: 'no-repeat',
}))

const StyledHeader = styled(Box)(() => ({
  width: '100%',
  height: '128px',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: theme.spacing(2),
}))

export default AccountCenter
