import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
//@ts-ignore
import { string } from 'yup'

import { ALL_DATA_LIMIT, PHONE_NUMNER_REGEX } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import { useOffices } from '@/api/offices/get'
import { EditUserParams } from '@/api/users/edit-user'
import { User } from '@/api/users/users'
import Form from '../Form/Form'
import useForm, { Field } from '../Form/useForm'

type EditUserModalProps = {
  saveUserEdit: (params: EditUserParams) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onClose?: () => void
  user?: User
}

const EditUserModal: FC<EditUserModalProps> = ({ saveUserEdit, open, setOpen, user, onClose = () => {} }) => {
  const { t } = useTranslation()
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []

  const fields: Field[] = useMemo(
    () => [
      {
        name: 'first_name',
        initialValue: user?.first_name ?? '',
        label: 'createUserModal.firstName',
        validationRule: string().required('createUserModal.errors.missingFirstName'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'last_name',
        initialValue: user?.last_name ?? '',
        label: 'createUserModal.lastName',
        type: 'text',
        required: false,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      // {
      //   name: 'username',
      //   initialValue: user?.username ?? '',
      //   label: 'createUserModal.email',
      //   validationRule: string()
      //     .required('createUserModal.errors.missingEmail')
      //     .email('createUserModal.errors.invalidEmail'),
      //   type: 'text',
      //   required: true,
      //   autoFocus: false,
      //   gridItemProps: {
      //     sm: 6,
      //     xs: 12,
      //   },
      // },
      {
        name: 'phone_number',
        initialValue: user?.phone_number ?? '',
        label: 'createUserModal.phone',
        placeholder: '+12345678901',
        validationRule: string().matches(PHONE_NUMNER_REGEX, {
          message: 'createUserModal.errors.invalidPhone',
          excludeEmptyString: true,
        }),
        type: 'text',
        required: false,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
      },
      {
        name: 'office_id',
        initialValue: user?.office_id ?? '',
        label: 'createUserModal.office',
        validationRule: string().required('createUserModal.errors.missingOffice'),
        type: 'text',
        required: true,
        autoFocus: false,
        gridItemProps: {
          sm: 6,
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
        },
        options: offices
          ? offices.map((office) => ({
              label: office.name,
              value: office._id,
            }))
          : [],
      },
      {
        name: 'member_of',
        initialValue: user?.member_of ?? [],
        label: 'createUserModal.groups',
        validationRule: string(),
        type: 'text',
        required: false,
        autoFocus: false,
        gridItemProps: {
          xs: 12,
        },
        textFieldProps: {
          variant: 'outlined',
          select: true,
          SelectProps: {
            multiple: true,
          },
        },
        options: groups ? groups.map((group) => ({ label: group.name, value: group._id })) : [],
      },
    ],
    [user, offices, groups]
  )

  function handleClose() {
    onClose()
    setOpen(false)
    clearForm()
  }

  const { formProps, handleSubmit, clearForm } = useForm({
    fields,
    onSubmit: handleSumbit,
    clearOnSubmit: false,
  })

  async function handleSaveEdit(values) {
    if (user) {
      try {
        saveUserEdit({ _id: user._id, ...values })
        handleClose()
      } catch (e) {}
    }
  }

  function handleSumbit(values) {
    handleSaveEdit(values)
  }

  return (
    <>
      <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose} aria-labelledby="user-dialog-title">
        <DialogTitle id="user-dialog-title">{t('createUserModal.editUser')}</DialogTitle>
        <DialogContent>
          <Form {...formProps} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined">
            {t('createUserModal.cancel')}
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            {t('createUserModal.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default EditUserModal
