import { Box } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import * as d3 from 'd3'
import { HierarchyNode } from 'd3-hierarchy'
import { OrgChart } from 'd3-org-chart'
import { Dispatch, FC, SetStateAction, useLayoutEffect, useMemo, useRef, useState } from 'react'
import ReactDOMServer from 'react-dom/server'

import { useCurrentUser } from '@/api/profile/current-user'
import { OrganizationCardData } from '@/pages/AccountCenter'
import { OrganizationListItem } from '@/types/organizations'
import AddModal from './AddModal'
import ArchivePopup from './ArchivePopup'
import ClientSwitchPopup from './ClientSwitchPopup'
import EditModal from './EditModal'
import TreeChartActions from './TreeChartActions'
import TreeChartCard from './TreeChartCard'

type TreeChartProps = {
  data: OrganizationCardData[]
}

export type ModalProps = {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  organization: OrganizationCardData
  focusNode?: (value: OrganizationListItem | null, collapse?: boolean, highlighted?: boolean) => void
}

enum ModalType {
  default,
  delete,
  edit,
  add,
  invite,
  clientSwitch,
  // upload
}

const TreeChart: FC<TreeChartProps> = ({ data }) => {
  const [relatedOrganization, setRelatedOrganization] = useState<OrganizationCardData>(data[0])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalType, setModalType] = useState<number>(ModalType.default)
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [searchValue, setSearchValue] = useState<OrganizationListItem | null>(null)
  const [collapsedAll, setCollapsedAll] = useState(false)
  const d3Container = useRef<string | null>(null)
  const chartRef = useRef<OrgChart<OrganizationCardData> | null>(null)

  //TODO Add autofocus
  const classes = useStyles()

  const { data: currentUser } = useCurrentUser()

  const clientOrg = useMemo(() => {
    return data.find((org) => org._id === currentUser?.current_client_id) || data[0]
  }, [data, currentUser])

  const Modal: FC<ModalProps> = ({ organization, open, setOpen, focusNode }) => {
    switch (modalType) {
      case ModalType.edit:
        return <EditModal organization={organization} open={open} setOpen={setOpen} />
      case ModalType.delete:
        return <ArchivePopup open={open} setOpen={setOpen} organization={organization} />
      case ModalType.add:
        return <AddModal organization={organization} open={open} setOpen={setOpen} focusNode={focusNode} />
      case ModalType.clientSwitch:
        return <ClientSwitchPopup organization={organization} open={open} setOpen={setOpen} />
    }
  }

  const addButtonOpenModalFunctionality = (
    node: HierarchyNode<OrganizationCardData>,
    modalType: ModalType,
    classNameSuffix: string
  ) => {
    const button = document.getElementById(node.id + classNameSuffix)
    if (button)
      button.onclick = () => {
        setModalType(modalType)
        setRelatedOrganization(node.data)
        setIsModalOpen(true)
      }
  }
  useLayoutEffect(() => {
    if (data.length === 0) return

    if (!chartRef.current) {
      chartRef.current = new OrgChart()
    }

    const chart = chartRef.current
    if (data && d3Container.current && currentUser) {
      chart
        .container(d3Container.current)
        .data(data)
        .nodeWidth(() => 280)
        .nodeHeight(() => 260)
        .childrenMargin(() => 75)
        .compactMarginBetween(() => 75)
        .compactMarginPair(() => 80)
        .nodeButtonHeight(() => 0)
        .buttonContent(() => '')
        .compact(false)
        .scaleExtent([0.1, 1])
        .initialZoom(1)
        .nodeContent((node) => {
          //@ts-ignore
          const closedChildren = node._children
          const opendChildren = node.children
          return ReactDOMServer.renderToStaticMarkup(
            <TreeChartCard
              organization={node.data as OrganizationCardData}
              depth={node.depth}
              closedChildren={closedChildren}
              opendChildren={opendChildren}
              currentUser={currentUser}
            />
          )
        })
        .nodeUpdate((node) => {
          //@ts-ignore
          const closedChildren = node?._children
          const opendChildren = node.children
          node.data.id === 'fake_root' && chart.setExpanded('fake_root', true)
          const expandCollapseBtn = document.getElementById(node.id + '_expandCollapse')
          if (expandCollapseBtn)
            expandCollapseBtn.onclick = () => {
              if (closedChildren?.length) {
                chart.setExpanded(closedChildren[0].id as string).render()
              } else if (opendChildren?.length) {
                if (node.parent !== null) chart.setExpanded(opendChildren[0].id as string, false).render()
                else chart.collapseAll()
              }
            }
          addButtonOpenModalFunctionality(node, ModalType.edit, '_edit')
          addButtonOpenModalFunctionality(node, ModalType.clientSwitch, '_card')
          addButtonOpenModalFunctionality(node, ModalType.delete, '_archive')
          addButtonOpenModalFunctionality(node, ModalType.add, '_add')
          // addButtonOpenModalFunctionality(node, ModalType.upload, '_uploadImage')
        })
        .render()

      // no links from fake_root:
      d3.select(d3Container.current)
        .selectAll<SVGGElement, HierarchyNode<{ name: string; value?: number }>>('.link')
        .filter(function (d) {
          return d.depth === 1
        })
        .style('stroke', 'none')
      chart.setCentered(currentUser?.current_client_id ?? 'fake_root').expandAll()
    }
  }, [data, currentUser])

  const focusNode = (value: OrganizationListItem | null, collapse = true, highlighted = true) => {
    if (!value) {
      chartRef.current?.clearHighlighting()
      return
    }

    if (collapse) {
      chartRef.current?.clearHighlighting()

      const chartData = chartRef.current?.data()
      chartData?.forEach((node) => {
        node._expanded = false
      })
      chartData?.forEach((node) => {
        if (node.id === value._id) {
          node._highlighted = highlighted
          node._expanded = true
        }
      })
    }

    chartRef.current?.setCentered(value._id).initialZoom(1).render()
  }

  const toggleCollapse = () => {
    if (collapsedAll) {
      chartRef.current?.expandAll()
    } else {
      chartRef.current?.data()?.forEach((node) => {
        node.parentId === 'fake_root' ? (node._expanded = true) : (node._expanded = false)
      })
      chartRef.current?.data(data).render()
    }
    setCollapsedAll((currentCollapsed) => !currentCollapsed)
  }

  return (
    <Box className={classes.root} ref={d3Container}>
      <Box className={classes.treeChartActionsContainer}>
        <Modal organization={relatedOrganization} open={isModalOpen} setOpen={setIsModalOpen} focusNode={focusNode} />
        <TreeChartActions
          setShowSearchInput={setShowSearchInput}
          zoomIn={() => chartRef.current?.zoomIn()}
          zoomOut={() => chartRef.current?.zoomOut()}
          zoomToFit={() => chartRef.current?.setCentered(currentUser?.current_client_id ?? 'fake_root').render()}
          recenterAccount={() => focusNode(clientOrg, false, false)}
          toggleAll={toggleCollapse}
          collapsedAll={collapsedAll}
          showSearchInput={showSearchInput}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          handleSearch={focusNode}
        />
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flex: 1,
      '& .link': {
        strokeWidth: 2,
        stroke: '#B8B8B8',
      },
      '& svg': {
        height: '100%',
      },
    },
    treeChartActionsContainer: {
      position: 'absolute',
      top: 0,
    },
  })
)

export default TreeChart
