import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faGraduationCap, faMicrochipAi, faPenPaintbrush } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Chip, Tooltip, tooltipClasses, TooltipProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { shortenString } from '@/common/utils/shortenString'
import { theme } from '@/theme/theme'

type LibraryTitleCellComponentProps = {
  title: string
  isCustom?: boolean
  isLesson?: boolean
  isAiGenerated?: boolean
}
const LibraryTitleCellComponent: FC<LibraryTitleCellComponentProps> = ({
  title,
  isCustom = false,
  isLesson = false,
  isAiGenerated = false,
}) => {
  const { t } = useTranslation()
  return (
    <Box display="flex" alignItems="center">
      {title.length > 30 ? (
        <CustomWidthTooltip title={title} placement="top">
          <Typography noWrap>{shortenString(title, 30)}</Typography>
        </CustomWidthTooltip>
      ) : (
        <Typography noWrap>{title}</Typography>
      )}
      {isCustom && !isLesson && (
        <Chip
          size="small"
          label={t('videoLibrary.custom')}
          icon={<FontAwesomeIcon icon={faPenPaintbrush as IconProp} />}
        />
      )}
      {isLesson && (
        <Chip
          size="small"
          label={t('videoLibrary.lesson')}
          sx={{ background: theme.palette.lightGreen[300] }}
          icon={<FontAwesomeIcon icon={faGraduationCap as IconProp} />}
        />
      )}
      {isAiGenerated && (
        <Chip
          size="small"
          label={t('simulationLibrary.aiGenerated')}
          icon={<FontAwesomeIcon icon={faMicrochipAi as IconProp} />}
        />
      )}
    </Box>
  )
}

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
})

export default LibraryTitleCellComponent
