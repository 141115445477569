import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, Paper, Tooltip, Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { startCase } from 'lodash'
import { FC, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink, useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { CampaignUsersQuery, useCampaignUsers } from '@/api/campaigns/getUsers'
import { useClientDetails } from '@/api/client/client'
import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import Tags from '@/common/components/Tags/Tags'
import { theme } from '@/theme/theme'
import { byPrefixAndName } from '@awesome.me/kit-39de79544e/icons'
import { styled } from '@mui/system'

type CampaignUsersTableProps = {
  campaignId: string | undefined
  hasDistribution: boolean
  assetAllowUnverifiedDomains: boolean
  onlyAssetsWithPhone: boolean
}

const CampaignUsersTable: FC<CampaignUsersTableProps> = ({
  campaignId,
  hasDistribution,
  assetAllowUnverifiedDomains,
  onlyAssetsWithPhone,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const [pageCount, setPageCount] = useState(0)
  const { data: clientDetails } = useClientDetails()

  const [queryFilters, setQueryFilters] = useState<CampaignUsersQuery>({
    id: campaignId,
    limit: 10,
    skip: 0,
  })

  const { data: usersData, isFetching } = useCampaignUsers(queryFilters, { refetchInterval: 10_000 })

  const { results: users, total: count } = usersData ?? { count: 0, users: [] }

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setQueryFilters((prevState) => {
        return {
          ...prevState,
          skip: (prevState.limit as number) * pageIndex || 0,
          limit: pageSize,
        }
      })
      setPageCount(Math.ceil((count as number) / pageSize))
    },
    [count]
  )

  const groupsById = useMemo(() => {
    const byId = {}
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }
    return byId
  }, [groups])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: startCase(t('campaignDashboard.name')),
        accessor: 'first_name',
        disableSortBy: true,
        Cell: (data) => {
          const user = data.row.original
          return `${user.first_name} ${user.last_name}`
        },
      },
      {
        Header: startCase(t('trainingCampaignDashboard.usersTable.email')),
        accessor: 'username',
        disableSortBy: true,
        Cell: (data) => {
          const user = data.row.original
          const isDomainVerified = user.domain_verified

          if (!isDomainVerified && (!assetAllowUnverifiedDomains || !clientDetails?.allow_unverified_domains)) {
            user.disabled = true
          }

          return (
            <div>
              <span>{user.username}</span>
              {user.disabled ? (
                <Tooltip title={<TableBodyTooltip member={user} />} placement="right">
                  <StyledFontAwesome icon={faTriangleExclamation as IconProp} />
                </Tooltip>
              ) : (
                ''
              )}
              {!user.phone_number && onlyAssetsWithPhone ? (
                <Tooltip
                  title={
                    <Trans
                      i18nKey="campaignDashboard.usersTable.invalidPhoneNumber"
                      components={{ 1: <NavLink to={`/recipients/users/${user._id}`} target="_blank" /> }}
                    />
                  }
                  placement="top">
                  <StyledFontAwesome icon={byPrefixAndName.fak['light-mobile-circle-xmark'] as IconProp} />
                </Tooltip>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        Header: t('campaignDashboard.groups'),
        accessor: 'member_of',
        disableSortBy: true,
        Cell: ({ value }) =>
          value ? (
            <div className={classes.tags}>
              <Tags
                useChips={false}
                tags={value.map((groupId) => ({
                  label: groupsById[groupId]?.name,
                }))}
                limit={1}
              />
            </div>
          ) : null,
      },
      ...(hasDistribution
        ? [
            {
              Header: t('campaignDashboard.usersTable.distribution'),
              accessor: 'asset',
              disableSortBy: true,
              Cell: ({ value }) => value,
            },
          ]
        : []),
      {
        Header: t('campaignDashboard.usersTable.score'),
        accessor: 'campaign_score',
        disableSortBy: true,
        Cell: ({ value }) => <HumanizedScore score={value} />,
      },
    ],
    [t, groupsById, classes.tags]
  )

  const handleNavigateToUserProfile = ({ original }) => {
    navigateTo(`/recipients/users/${original._id}`)
  }

  return (
    <Paper className={classes.root}>
      <PaginatingTable
        enableCheckbox={false}
        columns={columns}
        data={users ?? []}
        fetchData={fetchData}
        loading={isFetching}
        pageCount={pageCount}
        count={count}
        noResultsTextId={'campaignDashboard.usersTable.noResults'}
        onRowClick={handleNavigateToUserProfile}
      />
    </Paper>
  )
}

const StyledFontAwesome = styled(FontAwesomeIcon)({
  color: theme.palette.orange[900],
  height: 18,
  display: 'inline-flex',
  alignItems: 'center',
  margin: 'auto',
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
})

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      border: 'none',
      overflow: 'hidden',
      '& .MuiTableCell-head': {
        textAlign: 'center',
        paddingLeft: 40,
        '&:first-child': {
          paddingLeft: 15,
          textAlign: 'left',
        },
      },
      '& .MuiTableCell-body': {
        textAlign: 'center',
        '&:first-child': {
          textAlign: 'left',
        },
      },
    },
    tags: {
      display: 'flex',
      justifyContent: 'center',
    },
    tooltip: {
      textDecoration: 'underline #D1F6FF',
      color: '#D1F6FF',
    },
    tooltipText: {
      fontSize: '12px',
      fontWeight: 'normal',
    },
  })
)

const TableBodyTooltip: FC<{ member }> = ({ member }) => {
  const classes = useStyles()
  return (
    <Typography className={classes.tooltipText}>
      <Trans
        i18nKey={!member.domain_verified ? 'campaignDashboard.usersTable.unverifiedDomain' : ''}
        components={{
          1: (
            <Link
              href={!member.domain_verified ? '@/settings/allowlisting' : ''}
              target="_blank"
              className={classes.tooltip}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default CampaignUsersTable
