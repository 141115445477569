import FutureFormWrapper from '@/common/components/Layout/FutureFormWrapper'
import { FC, useState } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { useLogin } from '@/api/auth/login'
import Login from './Login'
import MFA from './MFA'
import UserLocked from '../UserLocked'
import { ServerError } from '@/api/types'
import { useNavigate } from 'react-router-dom'

const LoginPage: FC = () => {
  const [login, { isLoading }] = useLogin()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [MFAType, setMFAType] = useState<'email' | 'app' | undefined>(undefined)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [userLocked, setUserLocked] = useState(false)

  const navigateTo = useNavigate()

  const handleLogin = async (formValues: { username: string; password: string }) => {
    try {
      setUsername(formValues.username)
      setPassword(formValues.password)
      const recaptcha = (executeRecaptcha && (await executeRecaptcha('login'))) || ''
      const data = await login({ ...formValues, recaptcha })
      if (data?.mfa_type) {
        setMFAType(data.mfa_type)
      } else {
        navigateTo('/mfa-setup')
      }
    } catch (error) {
      if ((error as ServerError).response.data.message === 'User locked') setUserLocked(true)
      else throw error
    }
  }

  const resendMFA = async () => {
    const recaptcha = (executeRecaptcha && (await executeRecaptcha('login'))) || ''
    await login({ ...{ username, password }, recaptcha })
  }

  return (
    <FutureFormWrapper>
      {userLocked ? (
        <UserLocked username={username} setUserLocked={setUserLocked} />
      ) : MFAType ? (
        <MFA username={username} resendMFA={resendMFA} MFAType={MFAType} />
      ) : (
        <Login isLoading={isLoading} handleLogin={handleLogin} />
      )}
    </FutureFormWrapper>
  )
}
export default LoginPage
