import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { Office } from '@/types/offices'
import { serializeArray, Serialized } from '../utils/serialize-array'

export async function deleteOffice(_id: string) {
  const url = `${API_URL}/offices/delete/${_id}`
  const { data } = await axios.post(url, {}, { withCredentials: true })
  return data.data
}

export function useDeleteOffice() {
  const queryCache = useQueryCache()
  return useMutation<string, ServerError, string>(deleteOffice, {
    throwOnError: true,
    onMutate: (_id) => {
      queryCache.cancelQueries('offices')
      const previousOffices = queryCache.getQueryData('offices') as Serialized<Office>
      const updatedOffices = serializeArray(previousOffices.array.filter((office) => _id !== office._id))

      queryCache.setQueryData('offices', () => updatedOffices)
      return () => queryCache.setQueryData('offices', previousOffices)
    },
    //this is a type checker foor the rollback since i'm not sure what the rollback will looks like. since it's () i guess its a function, so if it's not to not return it
    onError: (err, variables, rollback) => {
      if (typeof rollback === 'function') {
        rollback()
      }
    },
    onSettled: () => {
      queryCache.invalidateQueries('offices')
    },
  })
}
