import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useResetPassword } from '@/api/auth/reset-password'
import { DIGITS_REGEX, LOWERCASE_REGEX, SPECIAL_CHARACTERS_REGEX, UPPERCASE_REGEX } from '@/api/constants'
import PasswordField from '@/common/components/Inputs/PasswordField'
import TextField from '@/common/components/Inputs/TextFieldV2'

const SetPassword: FC = () => {
  const { t } = useTranslation()
  const [setNewPassword, { isLoading }] = useResetPassword(() => navigateTo('/reset-password/success'))
  const { username, token } = useParams()
  const navigateTo = useNavigate()

  const isStrongPassword = (password: string) => {
    return (
      UPPERCASE_REGEX.test(password) &&
      LOWERCASE_REGEX.test(password) &&
      DIGITS_REGEX.test(password) &&
      SPECIAL_CHARACTERS_REGEX.test(password) &&
      password.length > 7
    )
  }

  const handleSubmit = async (values: { newPassword: string }) => {
    setNewPassword({ password: values.newPassword, username, token })
  }
  const initialValues = {
    username: username,
    newPassword: '',
    confirmNewPassword: '',
  }
  const validationSchema = Yup.object().shape({
    confirmNewPassword: Yup.string().test(
      'confirm-new-password-check',
      "Passwords don't match",
      function (this: Yup.TestContext, value: string | undefined) {
        const { newPassword } = this.parent
        if (!newPassword && !value) return true
        return value === newPassword || this.createError({ message: "Passwords don't match" })
      }
    ),
  })

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty, values }) => (
        <Form>
          <Box width={568} display="flex" flexDirection="column" gap={2}>
            <TextField required label={'Email'} name="username" isLocked disabled />
            <PasswordField
              label={t('setPassword.newPassword')}
              name="newPassword"
              withStrengthIndicator
              strengthIndicatorSize={'large'}
              required
            />
            <PasswordField label={t('setPassword.confirmPassword')} name="confirmNewPassword" required />
            <Box sx={{ width: '100%', paddingRight: 0.5 }}>
              <LoadingButton
                size="large"
                type="submit"
                loading={isSubmitting || isLoading}
                variant="contained"
                disabled={!dirty || !isValid || !isStrongPassword(values.newPassword)}
                sx={{ width: '100%' }}>
                {t('setPassword.resetPassword')}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default SetPassword
