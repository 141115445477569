import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'
import { useQueryCache } from 'react-query'

type MFAVerify = {
  token: string
}

const mfaVerifySetup = async (mfaPaypload: MFAVerify) => {
  const url = `${API_URL}/profile/mfa/verify`
  const { data } = await axios.post(url, mfaPaypload, {
    withCredentials: true,
  })

  return data
}

const mfaVerifyLogin = async (mfaPaypload: MFAVerify) => {
  const url = `${API_URL}/auth/verify-mfa/`
  const { data } = await axios.post(url, mfaPaypload, {
    withCredentials: true,
  })

  return data
}

export const useMFAVerifySetup = () => {
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: mfaVerifySetup,
    onSuccess: () => {
      // Invalidate the user to refetch the data after MFa verification
      queryCache.invalidateQueries('user')
    },
  })
}

export const useMFAVerifyLogin = () => {
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: mfaVerifyLogin,
    onSuccess: () => {
      // Invalidate the user to refetch the data after MFa verification
      queryCache.invalidateQueries('user')
    },
  })
}
