import {
  faBadgeCheck,
  faExclamationCircle,
  faExclamationSquare,
  faExclamationTriangle,
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactNode } from 'react'

import { palette } from '../palette'

const iconMapping: Record<'success' | 'info' | 'warning' | 'error', ReactNode> = {
  success: <FontAwesomeIcon icon={faBadgeCheck} />,
  info: <FontAwesomeIcon icon={faExclamationCircle} />,
  warning: <FontAwesomeIcon icon={faExclamationTriangle} />,
  error: <FontAwesomeIcon icon={faExclamationSquare} />,
}

export const MuiAlertTitle = {
  styleOverrides: {
    root: {
      fontWeight: 'bold',
    },
  },
}
export const MuiAlert = {
  defaultProps: {
    iconMapping,
  },
  styleOverrides: {
    root: {
      alignItems: 'flex-start',
      padding: '16px 16px 12px',
    },
    icon: {
      fontSize: '32px',
    },
    message: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '32px',
    },
    standard: {
      border: 'none',
      '& .MuiAlert-message': {
        color: palette.blueDianne[900],
      },
    },
    filled: {
      padding: '17px 16px 13px',
      border: 'none',
      color: palette.black,
    },
    outlined: {
      '& .MuiAlert-message': {
        color: palette.blueDianne[900],
      },
    },
    standardSuccess: {
      '& .MuiAlert-icon': {
        color: palette.green[500],
      },
      backgroundColor: palette.green[50],
    },
    standardInfo: {
      '& .MuiAlert-icon': {
        color: palette.blue[500],
      },
      backgroundColor: palette.blue[50],
    },
    standardWarning: {
      '& .MuiAlert-icon': {
        color: palette.orange[900],
      },
      backgroundColor: palette.orange[50],
    },
    standardError: {
      '& .MuiAlert-icon': {
        color: palette.red[900],
      },
      backgroundColor: palette.red[50],
    },

    filledSuccess: {
      backgroundColor: palette.green[500],
    },
    filledInfo: {
      backgroundColor: palette.blue[500],
    },
    filledWarning: {
      backgroundColor: palette.orange[400],
    },
    filledError: {
      color: palette.white,

      backgroundColor: palette.red[900],
    },

    outlinedSuccess: {
      '& .MuiAlert-icon': {
        color: palette.green[500],
      },
      borderColor: palette.green[500],
    },
    outlinedInfo: {
      '& .MuiAlert-icon': {
        color: palette.blue[500],
      },
      borderColor: palette.blue[500],
    },
    outlinedWarning: {
      '& .MuiAlert-icon': {
        color: palette.orange[900],
      },
      borderColor: palette.orange[400],
    },
    outlinedError: {
      '& .MuiAlert-icon': {
        color: palette.red[900],
      },
      borderColor: palette.red[900],
    },
    action: {
      button: {
        color: palette.black,
      },
      height: '100%',
      alignItems: 'center',
      padding: '16px',
    },
  },
}
