import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { PagedResult } from '@/types/common'
import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'
import { OfficeListItem, OfficesListQuery } from '@/types/offices'

const getOffices = async (params: OfficesListQuery) => {
  const url = `${API_URL}/offices/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params,
    paramsSerializer,
  })

  return data
}

export const useOffices = (query: OfficesListQuery) => {
  return useQuery<PagedResult<OfficeListItem>>({
    queryKey: ['offices', query],
    queryFn: () => getOffices(query),
    placeholderData: keepPreviousData,
  })
}
