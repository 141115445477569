import { Box, LinearProgress, Typography } from '@mui/material'
import { FC } from 'react'

import uploadingAnimatipon from '@/assets/lottie/uploading.json'
import useLottie from '@/common/hooks/useLottie'
import { theme } from '@/theme/theme'

const UploadLoading: FC<{ title: string }> = ({ title }) => {
  const { LottieAnimation } = useLottie({
    animationData: uploadingAnimatipon,
    height: 320,
    width: 320,
  })

  return (
    <Box display="flex" gap={4} sx={{ flexDirection: 'row' }}>
      <Box width={320} height={280}>
        {LottieAnimation}
      </Box>
      <Box flex={1}>
        <Typography marginBottom={1}>{title}</Typography>
        <LinearProgress
          sx={{
            background: theme.palette.cyan[50],
            '& .MuiLinearProgress-bar1Indeterminate': {
              background: theme.palette.cyan[600],
            },
            '& .MuiLinearProgress-bar2Indeterminate': {
              background: theme.palette.cyan[900],
            },
          }}
        />
      </Box>
    </Box>
  )
}

export default UploadLoading
