import { DefinedInitialDataOptions, keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '@/api/constants'
import { config } from '@/common/config'
import { CampaignQuerySort } from '@/types/campaigns'
import { PagedQuery, PagedResult } from '@/types/common'
import {
  PhishingSimulation,
  PhishingSimulationAndPackagesRequest,
  PhishingSimulationAndPackagesResponse,
} from '@/types/phishingSimulations'
import { paramsSerializer } from '../utils/serialize-array'

//TODO consider moving this
export const buildClaudinaryUrl = (path: string) => {
  const imageFolderUrl =
    import.meta.env.VITE_ENV === 'dev'
      ? config.cloudinaryCoverImagesFolderDevelopmentUrl
      : config.cloudinaryCoverImagesFolderProductionUrl

  return `${imageFolderUrl}/${path}`
}

const v1AdapterSimulation = (data: PhishingSimulation): any => ({
  ...data,
  template_thumbnail: buildClaudinaryUrl(data.image_cloudinary_ids?.message),
  landing_page_thumbnail: buildClaudinaryUrl(data.image_cloudinary_ids?.landing_page),
  feedback_page_thumbnail: buildClaudinaryUrl(data.image_cloudinary_ids?.micro_training),
})

const v1AdapterSimulations = (data: PagedResult<PhishingSimulation>): any => ({
  ...data,
  results: data.results.map((itemData) => ({
    ...itemData,
    template_thumbnail: buildClaudinaryUrl(itemData.image_cloudinary_ids?.message),
    landing_page_thumbnail: buildClaudinaryUrl(itemData.image_cloudinary_ids?.landing_page),
  })),
})

const getPhishingSimulation = async (id: string | undefined) => {
  const url = `${API_URL}/phishing-simulations/${id}`
  const { data } = await axios.get(url, { withCredentials: true })

  return data
}

const getPhishingSimulations = async (params?: Partial<PagedQuery<CampaignQuerySort>>) => {
  const url = `${API_URL}/phishing-simulations/`
  const { data } = await axios.get(url, {
    params,
    withCredentials: true,
  })

  return data
}

const getPhishingSimulationsWithPackages = async (params: any) => {
  const url = `${API_URL}/phishing-simulations/with_packages`
  const { data } = await axios.get(url, {
    params,
    paramsSerializer,
    withCredentials: true,
  })

  return {
    results: data.results.map(({ id, ...rest }: { id: string }) => ({
      _id: id,
      ...rest,
    })),
    total: data.total,
  }
}

export const usePhishingSimulationsWithPackages = ({ query }: PhishingSimulationAndPackagesRequest) => {
  return useQuery<PhishingSimulationAndPackagesResponse>({
    queryKey: ['phishing-simulations', query],
    queryFn: () => {
      return getPhishingSimulationsWithPackages(query)
    },
    placeholderData: keepPreviousData,
  })
}

export const usePhishingSimulation = (
  id: string | undefined,
  options?: Partial<DefinedInitialDataOptions<PhishingSimulation>>
) => {
  return useQuery<PhishingSimulation>({
    queryKey: ['phishing-simulations', id],
    queryFn: () => getPhishingSimulation(id),
    enabled: !!id,
    select: v1AdapterSimulation,
    ...options,
  })
}

export const usePhishingSimulations = (
  params?: Partial<PagedQuery<CampaignQuerySort>>,
  options?: Partial<DefinedInitialDataOptions<PagedResult<PhishingSimulation>>>
) => {
  return useQuery<PagedResult<PhishingSimulation>>({
    queryKey: ['phishing-simulations'],
    queryFn: () => getPhishingSimulations(params),
    select: v1AdapterSimulations,
    ...options,
  })
}
