import { useEffect, ReactNode } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { useAuth } from '../context/Auth'
import Popup from '@/common/components/Popup'
import { Trans, useTranslation } from 'react-i18next'
import { useTermsModal } from '@/context/TermsContext'

import termsModal from '@/assets/images/popup/terms-modal.svg'
import { OrganizationState } from '@/types/organizations'

function ProtectedRoute({
  children,
  redirectTo = '/sign-in',
  enabled = true,
  enableArchive = false,
}: {
  children: ReactNode
  redirectTo?: string
  enabled?: boolean
  enableArchive?: boolean
}) {
  const { user, isLoading, organizationState, isError } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showTermsModal, setShowTermsModal, handleAcceptTerms, handleDeclineTerms } = useTermsModal()

  useEffect(() => {
    if (!isLoading) {
      if (isError || !enabled) {
        navigate(redirectTo)
      }
      if (organizationState === OrganizationState.archived && !enableArchive) {
        navigate('/archived-organization')
      } else if (user && !user.accepted_latest_terms) {
        setShowTermsModal(true)
      }
    }
  }, [user, isLoading, enabled])

  if (isLoading) return null

  return (
    <>
      <Popup
        open={showTermsModal}
        onConfirm={handleAcceptTerms}
        onClose={handleDeclineTerms}
        disableBackdropClick
        buttonLabels={{
          confirm: t('routes.termsPopup.accept'),
          cancel: t('routes.termsPopup.decline'),
        }}
        icon={termsModal}>
        <h4>{t('routes.termsPopup.title')}</h4>
        <Trans
          i18nKey="routes.termsPopup.description"
          components={{
            1: <Link to={'https://cywareness.io/terms-of-service/'} target="_blank" />,
            2: <Link to={'https://cywareness.io/privacy-policy/'} target="_blank" />,
            3: <br />,
          }}
        />
      </Popup>
      {user && enabled ? children : null}
    </>
  )
}

export default ProtectedRoute
