import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import successImage from '@/assets/images/auth/success.svg'

const ResetPasswordSuccess: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  return (
    <Box width={568} display="flex" flexDirection="column" gap={3} alignItems="center">
      <Typography textAlign="center">{t('resetPasswordSuccess.passwordChanged')} </Typography>
      <Box padding={3}>
        <img src={successImage} alt="bouncer" />
      </Box>
      <Box width="100%">
        <Button size="large" onClick={() => navigateTo('/sign-in')} variant={'contained'} sx={{ width: '100%' }}>
          {t('login.signIn')}
        </Button>
      </Box>
    </Box>
  )
}
export default ResetPasswordSuccess
