import { theme } from '@/theme/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faDownload, faFileCsv, faUpload } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertTitle, Box, Button, Typography } from '@mui/material'
import Papa from 'papaparse'
import { FC, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'

import InputFile from '@/common/components/Inputs/InputFile'
import Stepper from '@/common/components/Stepper'
import Step from '@/common/components/Stepper/Step'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { FileError } from '.'
import StepSubmit from './StepSubmit'

const csvFields = [
  'first_name',
  'last_name',
  // 'principal_name_language',
  // 'principal_name',
  'email_address',
  'phone_number',
  'office',
  'groups',
]

type Step1Prosp = {
  setCsvFile: (file: any) => void
  fileUpload: (dryRun: boolean, withResolutionStrategy: boolean) => void
  handleClose: () => void
  setStep: (step: number) => void
}

const Step1: FC<Step1Prosp> = ({ setCsvFile, fileUpload, handleClose, setStep }) => {
  const [error, setError] = useState<FileError | null>(null)
  const [csvData, setCsvData] = useState<any[]>([])
  const [isSubmitted, setIsSubmitted] = useState(false)

  const { t } = useTranslation()

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]

    setError(null)
    setIsSubmitted(true)

    if (file) {
      Papa.parse(file, {
        complete: async (result) => {
          if (result.errors.length > 0) {
            setError({
              code: 'format_error',
              title: t('bulkUserUpload.uploadStatusErrors.formatError.title'),
              message: t('bulkUserUpload.uploadStatusErrors.formatError.message'),
            })
            return
          }

          if (!csvFields.every((field) => result.meta.fields?.includes(field))) {
            setError({
              code: 'wrong_headers',
              title: t('bulkUserUpload.uploadStatusErrors.wrongHeaders.title'),
              message: t('bulkUserUpload.uploadStatusErrors.wrongHeaders.message'),
            })
            return
          }

          if (result.data.length < 1) {
            setError({
              code: 'empty_file',
              title: t('bulkUserUpload.uploadStatusErrors.emptyFile.title'),
              message: t('bulkUserUpload.uploadStatusErrors.emptyFile.message'),
            })
            return
          }
          if (result.data.length > 50000) {
            setError({
              code: 'file_max',
              title: t('bulkUserUpload.uploadStatusErrors.fileMax.title'),
              message: t('bulkUserUpload.uploadStatusErrors.fileMax.message'),
            })
            return
          }

          setCsvFile(file)
          setCsvData(result.data)
        },
        header: true,
        dynamicTyping: true,
        transformHeader: (header: string) => {
          return header.toLowerCase().replace(/\s/g, '_')
        },
        skipEmptyLines: true,
      })
    }
  }

  const handleContinue = () => {
    fileUpload(true, false)
    setStep(2)
  }

  return (
    <>
      <Stepper>
        <Step
          label={t('bulkUserUpload.downloadTemplateLabel')}
          icon={() => <FontAwesomeIcon icon={faDownload as IconProp} color={theme.palette.cyan[500]} />}
          active={true}>
          <Typography>{t('bulkUserUpload.downloadTemplateDescription')}</Typography>
          <Button
            variant="outlined"
            endIcon={<FontAwesomeIcon icon={faDownload as IconProp} />}
            sx={{ margin: theme.spacing(1, 0, 3) }}
            href="https://content.cywareness.io/add_users_template.csv">
            {t('bulkUserUpload.downloadTemplate')}
          </Button>
        </Step>
        <Step
          label={t('bulkUserUpload.editMemberInfoLabel')}
          icon={() => <FontAwesomeIcon icon={faFileCsv as IconProp} color={theme.palette.cyan[500]} />}
          active={true}>
          <Typography fontWeight={theme.typography.fontWeightMedium}>
            {t('bulkUserUpload.editMemberInfoSubTitle')}
          </Typography>
          <Typography
            component="ul"
            margin={theme.spacing(1, -2)}
            display={'flex'}
            flexDirection={'column'}
            gap={theme.spacing(1)}>
            <Typography component="li">{t('bulkUserUpload.editMemberInfoDescription1')}</Typography>
            <Typography component="li">{t('bulkUserUpload.editMemberInfoDescription2')}</Typography>
            <Typography component="li">{t('bulkUserUpload.editMemberInfoDescription3')}</Typography>
            <Typography component="li">{t('bulkUserUpload.editMemberInfoDescription4')}</Typography>
          </Typography>
        </Step>
        <Step
          label={t('bulkUserUpload.uploadYouFileLabel')}
          icon={() => <FontAwesomeIcon icon={faUpload as IconProp} color={theme.palette.cyan[500]} />}
          active={true}>
          <Typography padding={theme.spacing(0, 0, 1)}>{t('bulkUserUpload.uploadYouFileDescription')}</Typography>
          <Box margin={theme.spacing(1, -0.5, 2)}>
            <InputFile
              label={t('bulkUserUpload.attachCSV')}
              accept="text/csv"
              onChange={handleFileChange}
              endIcon={<FontAwesomeIcon icon={faUpload} />}
            />
          </Box>

          {error ? (
            <Alert severity="error">
              <AlertTitle>{error.title}</AlertTitle>
              {error.message}
            </Alert>
          ) : (
            <BulkUploadUsersTable tableData={csvData} />
          )}
        </Step>
      </Stepper>

      <StepSubmit
        handleClose={handleClose}
        confirmHandler={handleContinue}
        isSubmitDisabled={!isSubmitted || (!!error && isSubmitted)}
        sx={{ marginTop: theme.spacing(-2.5) }}
      />
    </>
  )
}

const BulkUploadUsersTable = ({ tableData }) => {
  const { t } = useTranslation()
  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('bulkUserUpload.exampleTable.number'),
        accessor: null,
        Cell: (data) => {
          return +data.row.id + 1
        },
      },
      {
        Header: t('bulkUserUpload.exampleTable.firstName'),
        accessor: 'first_name',
      },
      {
        Header: t('bulkUserUpload.exampleTable.lastName'),
        accessor: 'last_name',
      },
      // {
      //   Header: t('bulkUserUpload.exampleTable.principalNameLanguage'),
      //   accessor: 'principal_name_language',
      // },
      // {
      //   Header: t('bulkUserUpload.exampleTable.principalName'),
      //   accessor: 'principal_name',
      // },
      {
        Header: t('bulkUserUpload.exampleTable.username'),
        accessor: 'email_address',
      },
      {
        Header: t('bulkUserUpload.exampleTable.phone'),
        accessor: 'phone_number',
      },
      {
        Header: t('bulkUserUpload.exampleTable.groups'),
        accessor: 'groups',
        disableSortBy: true,
      },
      {
        Header: t('bulkUserUpload.exampleTable.office'),
        accessor: 'office',
        disableSortBy: true,
      },
    ],
    [t]
  )

  return (
    <SimpleTable
      //   highlightRows={{ variant: 'error', rows: highlightedRows || [] }}
      isEditable={false}
      enableCheckbox={false}
      columns={columns}
      data={tableData}
      setData={function (value: SetStateAction<any[]>): void {
        throw new Error('Function not implemented.')
      }}
      noResultsContent={<>No Results</>}
    />
  )
}

export default Step1
