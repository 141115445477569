export enum SortOrder {
  asc = 'asc',
  desc = 'desc',
}

export type PagedResult<T> = {
  total: number
  results: T[]
}

export type PagedQuery<SortBy> = {
  skip?: number
  limit?: number
  sort_by?: SortBy
  sort_order?: SortOrder
}

type Language = {
  name: string
  nativeName: string
}

export type LanguagesByCode = {
  [code: string]: Language
}

// RowData has either id or _id, but not both
export type RowDataItem =
  | ({ id: string; _id?: never } & { [key: string]: any })
  | ({ _id: string; id?: never } & { [key: string]: any })
