import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faBoxOpen } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, styled } from '@mui/material'
import { FC } from 'react'

import Carousel from '@/common/components/Carousel/Carousel'
import { PhishingSimulationPackage } from '@/types/phishingSimulations'
import SimulationPackageCarouselCard from './SimulationPackageMenu/SimulationPackageCarouselCard'

type PackagesCaruselViewProps = {
  packages: PhishingSimulationPackage[]
  showPreview: (simulation: PhishingSimulationPackage) => void
  savedSimulationPackages?: string[]
  deleteSimulationPackage: (simulationPackageId: string) => void
  saveSimulationPackageToggle: (id: string) => void
}

const PackagesCaruselView: FC<PackagesCaruselViewProps> = ({
  packages,
  showPreview,
  savedSimulationPackages = [],
  deleteSimulationPackage,
  saveSimulationPackageToggle,
}) => (
  <StyledCarouselWrapper>
    <Carousel title={'Packages'} icon={<FontAwesomeIcon icon={faBoxOpen as IconProp} fontSize={20} />}>
      {packages.map((simulationPackage: PhishingSimulationPackage) => (
        <StyledSimulationPackageCardWrapper key={simulationPackage.id}>
          <SimulationPackageCarouselCard
            showPreview={showPreview}
            simulationPackage={simulationPackage}
            isSaved={savedSimulationPackages.includes(simulationPackage.id)}
            deleteSimulationPackage={deleteSimulationPackage}
            saveSimulationPackageToggle={saveSimulationPackageToggle}
          />
        </StyledSimulationPackageCardWrapper>
      ))}
    </Carousel>
  </StyledCarouselWrapper>
)

const StyledCarouselWrapper = styled(Box)(() => ({
  marginTop: '40px',
}))

const StyledSimulationPackageCardWrapper = styled(Box)(() => ({
  minWidth: '366px',
  padding: '0px 8px',
}))

export default PackagesCaruselView
