import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faPenToSquare, faTrash, faUserPlus, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryCache } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useDeleteGroup } from '@/api/groups/delete-group'
import { useGroups } from '@/api/groups/get'
import { useGroup } from '@/api/groups/group'
import { GroupUsersQueryVariables, useGroupUsers } from '@/api/groups/group-users'
import { useRemoveUsersFromGroup } from '@/api/groups/remove-users-from-group'
import { useOffices } from '@/api/offices/get'
import removeImg from '@/assets/images/popup/delete.svg'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import GaugeScoremeter from '@/common/components/GaugeScoremeter/GaugeScoremeter'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import Popup from '@/common/components/Popup'
import SimpleSearchBar from '@/common/components/SimpleSearchBar/SimpleSearchBar'
import InTableMessageWithAnimation from '@/common/components/Tables/InTableMessageWithAnimation'
import { MultiselectBarProps } from '@/common/components/Tables/MultiselectBar'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import useToast from '@/common/hooks/useToast'
import EditGroupModal from '@/pages/GroupProfile/EditGroupModal'
import { theme } from '@/theme/theme'

const GroupProfile: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { successToast, errorToast } = useToast()
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  const [editModalType, setEditModalType] = useState<'group' | 'users'>('group')
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false)
  const [groupForDeletion, setGroupForDeletion] = useState<string>('')
  const [usersForRemoval, setUsersForRemoval] = useState<string[]>([])
  const [removeUserManager, setRemoveUserManager] = useState<boolean>(false)
  const [removeUsersAlertIsOpen, setRemoveUsersAlertIsOpen] = useState(false)
  const theme = useTheme()
  const { _id } = useParams()
  const { data: group } = useGroup(_id as string)
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const [deleteGroup] = useDeleteGroup()
  const [removeUsers] = useRemoveUsersFromGroup()

  const INITIAL_QUERY_FILTERS = {
    _id: _id as string,
    first_name: '',
    last_name: '',
    email: '',
    offices: [],
    offset: 5,
    startIndex: 0,
  }

  const [queryFilters, setQueryFilters] = useState<GroupUsersQueryVariables>(INITIAL_QUERY_FILTERS)
  const { resolvedData: queryData, isLoading, isFetching } = useGroupUsers(queryFilters)
  const { items: users, count } = queryData ?? { count: 0, items: [] }
  const [pageCount, setPageCount] = useState(0)
  const queryCache = useQueryCache()

  const fetchData = useCallback(
    ({ pageSize, pageIndex }: { pageSize: number; pageIndex: number }) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        startIndex: prevState.offset * pageIndex,
        offset: pageSize,
      }))
      setPageCount(Math.ceil(count / pageSize))
      queryCache.prefetchQuery([
        'group',
        'users',
        {
          ...queryFilters,
          startIndex: pageSize * (pageIndex + 1),
          offset: pageSize,
        },
      ])
    },
    [count]
  )

  function handleNavigateToMemberProfile({ original }: { original: any }) {
    navigateTo(`/recipients/users/${original._id}`)
  }

  const handleSearch = (value: string) => {
    setQueryFilters((prevFilters) => ({
      ...prevFilters,
      first_name: value,
    }))
  }

  function openRemoveUsersAlert(users: string[]) {
    setRemoveManager(users)
    setUsersForRemoval(users)
    setRemoveUsersAlertIsOpen(true)
  }

  const groupsById = useMemo(() => {
    const byId: { [key: string]: any } = {}
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }
    return byId
  }, [groups])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data: any) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: t('users.usersTable.email'),
        accessor: 'username',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        Cell: ({ value }: { value: string }) => value || '-',
      },
      {
        Header: t('users.usersTable.groups'),
        accessor: 'groups',
        Cell: (data: any) => {
          const value = data.row.original.member_of
          const groupsByName: string[] = []
          if (Array.isArray(value) && value.length > 0) {
            value.forEach((groupId) => {
              const recipientGroup = groupsById[groupId]
              recipientGroup && groupsByName.push(recipientGroup.name)
            })
          }
          return groupsByName.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}>
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '4px 4px',
                  fontSize: '14px',
                }}>
                {groupsByName[0]}
              </div>
              {groupsByName.length > 1 ? (
                <Tooltip
                  placement={'right'}
                  title={groupsByName
                    .slice(1)
                    .map((group) => group)
                    .join(', ')}>
                  <div
                    style={{
                      padding: '3px 8px',
                      gap: '10px',
                      borderRadius: '20px',
                      border: '1px solid #000',
                      marginLeft: '5px',
                      verticalAlign: 'middle',
                    }}>
                    {`+${groupsByName.length - 1}`}
                  </div>
                </Tooltip>
              ) : null}
            </div>
          ) : (
            '-'
          )
        },
      },
      {
        Header: t('users.usersTable.office'),
        accessor: 'office_id',
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 'auto',
              }}>
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '2px 4px',
                  fontSize: '14px',
                }}>
                {offices?.find((office) => office._id === value)?.name || ''}
              </div>
            </div>
          )
        },
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }: { value: number }) => {
          return <HumanizedScore score={value} />
        },
      },
      {
        Header: t('users.usersTable.status'),
        accessor: 'state',
        Cell: ({ value }: { value: string }) =>
          value === 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive'),
      },
      {
        id: 'delete',
        Cell: (data: any) => {
          const user = data.row.original
          const userId = user._id
          return (
            <MoreMenu
              items={[
                {
                  label: 'groupProfile.usersTable.remove',
                  textColor: theme.palette.error.main,
                  onClick: () => {
                    openRemoveUsersAlert([userId])
                  },
                },
              ]}
              ariaLabel={'groupProfile.usersTable.moreActions'}
            />
          )
        },
      },
    ],
    [t, offices]
  )

  function setRemoveManager(users: string[]) {
    group?.managers.forEach((manager) => {
      if (users.includes(manager._id)) {
        setRemoveUserManager(true)
        return
      }
    })
  }

  function closeRemoveUsersAlert() {
    setUsersForRemoval([])
    setRemoveUserManager(false)
    setRemoveUsersAlertIsOpen(false)
  }

  function closeDeleteGroupModal() {
    setGroupForDeletion('')
    setDeleteModalIsOpen(false)
  }

  async function handleRemoveUser() {
    try {
      await removeUsers({ _id: _id ?? '', users: usersForRemoval })
      successToast(t('groupProfile.userDeleted'))
      closeRemoveUsersAlert()
    } catch (e) {
      errorToast(t('groupProfile.errors.faliedDeleteUser'))
    }
  }

  async function handleDeleteGroup() {
    try {
      await deleteGroup(groupForDeletion)
      successToast(t('users.groupsTable.groupDeleted'))
      setDeleteModalIsOpen(false)
      navigateTo(`/recipients/groups`)
    } catch (e) {
      errorToast(t('users.groupsTable.errors.faliedDeleteGroup'))
    }
  }

  async function handleDeleteGroupModalOpen() {
    setDeleteModalIsOpen(true)
    setGroupForDeletion(_id as string)
  }

  async function handleEditGroupModalOpen() {
    setEditModalType('group')
    setEditModalIsOpen(true)
  }

  async function handleAddGroupMembersModalOpen() {
    setEditModalType('users')
    setEditModalIsOpen(true)
  }

  const multiSelectActions: MultiselectBarProps['actions'] = [
    {
      label: 'groupProfile.usersTable.remove',
      onClick: (userIds) => {
        openRemoveUsersAlert(userIds)
      },
      icon: () => <FontAwesomeIcon icon={faTrash as IconProp} fontSize={20} />,
      variant: 'iconButton',
    },
  ]

  return (
    <StyledRootBox>
      <Popup
        open={deleteModalIsOpen}
        onClose={closeDeleteGroupModal}
        onConfirm={handleDeleteGroup}
        buttonLabels={{
          confirm: t('groupProfile.removeModal.confirm'),
          cancel: t('groupProfile.removeModal.cancel'),
        }}
        icon={removeImg}>
        <h4>{t('groupProfile.removeModal.title')}</h4>
        <p style={{ fontSize: '16px' }}>{t('groupProfile.removeModal.description')}</p>
      </Popup>
      <EditGroupModal
        open={editModalIsOpen}
        setOpen={setEditModalIsOpen}
        onClose={() => setEditModalIsOpen(false)}
        group={group}
        // offices={offices}
        mode={editModalType}
      />
      <Popup
        open={removeUsersAlertIsOpen}
        onClose={closeRemoveUsersAlert}
        onConfirm={handleRemoveUser}
        buttonLabels={{
          confirm: t('groupProfile.removeMemberModal.confirm'),
          cancel: t('groupProfile.removeMemberModal.cancel'),
        }}
        icon={removeImg}>
        <h4>{t('groupProfile.removeMemberModal.title')}</h4>
        <p style={{ fontSize: '16px' }}>
          {usersForRemoval.length === 1
            ? t('groupProfile.removeMemberModal.description')
            : t('groupProfile.removeMemberModal.pluralDescription', { membersCount: usersForRemoval.length })}
        </p>
      </Popup>
      <StyledBreadcrumbBox>
        <CustomBreadcrumbs lastBreadcrumbSuffix={`| ${group?.name}` || ''} />
      </StyledBreadcrumbBox>
      <StyledGroupTitleBox>
        <Typography ml={theme.spacing(1)} fontSize={18} fontWeight={theme.typography.fontWeightMedium}>
          {group?.name}
        </Typography>
        <StyledActionsBox>
          <Button sx={{ padding: 0 }} onClick={handleAddGroupMembersModalOpen}>
            <FontAwesomeIcon icon={faUserPlus as IconProp} fontSize={18} color={theme.palette.black} />
          </Button>
          <Button sx={{ padding: 0 }} onClick={handleEditGroupModalOpen}>
            <FontAwesomeIcon icon={faPenToSquare as IconProp} fontSize={18} color={theme.palette.black} />
          </Button>
          <Button sx={{ padding: 0 }} onClick={handleDeleteGroupModalOpen}>
            <FontAwesomeIcon icon={faTrash as IconProp} fontSize={18} color={theme.palette.black} />
          </Button>
        </StyledActionsBox>
      </StyledGroupTitleBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '328px' }}>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('groupProfile.groupDetails')}
              </Typography>
            </StyledTitleBar>
            {/* <StyledDetailBox>
              <FontAwesomeIcon icon={faRepeat as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('groupProfile.createdAt')}</StyledKeyTypography>
              <Typography color={'inherit'}>{dayjs.utc(group?.created_at).format(EU_DATE_TIME_24HR)}</Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" />
            <StyledDetailBox>
              <FontAwesomeIcon icon={faBullhorn as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('groupProfile.lastCampaign')}</StyledKeyTypography>
              <Typography color={'inherit'}>
                {group?.campaign_last_sent_at ? dayjs.utc(group?.campaign_last_sent_at).format(EU_DATE_TIME_24HR) : '-'}
              </Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" /> */}
            <StyledDetailBox>
              <FontAwesomeIcon icon={faUsers as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('groupProfile.memberCount')}</StyledKeyTypography>
              <Typography color={'inherit'}>{group?.members?.count}</Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '328px' }}>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('groupProfile.groupScore')}
              </Typography>
            </StyledTitleBar>
            <StyledScoreMeterBox>
              <GaugeScoremeter score={group?.awareness_score || 0} hideTitle />
            </StyledScoreMeterBox>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '15px' }}>
        <Grid item xs={12} md={12}>
          <Paper>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('groupProfile.groupMembers')}
              </Typography>
            </StyledTitleBar>
            <StyledSearchBox>
              <SimpleSearchBar onChange={handleSearch} debounceTime={0} />
            </StyledSearchBox>
            <PaginatingTable
              multiSelectBarProps={{
                label: 'groupProfile.usersTable.user_count',
                actions: multiSelectActions,
              }}
              noResultsTextId={'groupProfile.usersTable.noResults'}
              columns={columns}
              data={users ?? []}
              fetchData={fetchData}
              loading={isLoading || isFetching}
              pageCount={pageCount}
              count={count}
              onRowClick={handleNavigateToMemberProfile}
              noResultsContent={<NoResults membersCount={count} hasSearch={!!queryFilters?.first_name} />}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledRootBox>
  )
}

const NoResults: FC<{ membersCount?: number; hasSearch?: boolean }> = ({ membersCount, hasSearch = false }) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation>
      <p>{t(!membersCount ? (!hasSearch ? 'groupProfile.noMembers' : 'groupProfile.noMembersSearch') : '')}</p>
    </InTableMessageWithAnimation>
  )
}

const StyledActionsBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
}))

const StyledDivider = styled(Divider)(() => ({
  margin: '0px 15px',
  borderBottomWidth: '3px',
  borderColor: '#E0EDEF',
}))

const StyledKeyTypography = styled(Typography)(() => ({
  fontWeight: theme.typography.fontWeightBold,
}))

const StyledTitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#294D55',
  alignItems: 'stretch',
  padding: '12px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#E0EDEF',
  borderRadius: '13px 13px 0px 0px',
}))

const StyledDetailBox = styled(Box)(() => ({
  display: 'flex',
  padding: '12px 26px',
  gap: '12px',
  alignItems: 'center',
}))

const StyledRootBox = styled(Box)(() => ({
  height: '100%',
  padding: theme.spacing(1, 4, 4),
}))

const StyledBreadcrumbBox = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
}))

const StyledGroupTitleBox = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))

const StyledScoreMeterBox = styled(Box)(() => ({
  justifyContent: 'center',
  paddingTop: '60px',
}))

const StyledSearchBox = styled(Box)(() => ({
  padding: theme.spacing(1),
}))

export default GroupProfile
