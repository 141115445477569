import Modal from '@mui/material/Modal'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationCreate } from '@/api/organizations/create'
import { getErrorMessage } from '@/api/utils/get-error'
import inviteImage from '@/assets/images/account-center/invite-image.svg'
import useToast from '@/common/hooks/useToast'
import { OrganizationListItem, OrganizationRequest } from '@/types/organizations'
import { ButtonsWrapper, ContentWrapper, ImageWrapper, ModalConent, StyledTypography } from './PopupsStyledComponents'
import { ModalProps } from './TreeChart'
import { Button } from '@mui/material'

type InvitePopupProps = Omit<ModalProps, 'organization'> & {
  organizationPayload: OrganizationRequest
  focusNode?: (value: OrganizationListItem | null, collapse?: boolean, highlighted?: boolean) => void
  onClose: () => void
  onCreate: () => void
}

const InvitePopup: FC<InvitePopupProps> = ({ open, setOpen, organizationPayload, focusNode, onClose, onCreate }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    onClose()
    setOpen(false)
  }

  const { mutateAsync: createOrganization } = useOrganizationCreate()
  const { errorToast, successToast } = useToast()

  const handleCreate = async (invite = true) => {
    if (!invite) {
      organizationPayload.admin_email = ''
    }
    try {
      const newOrganization = await createOrganization(organizationPayload)
      handleClose()
      onCreate()
      successToast(t('accountCenter.editModal.updateSuccess'))
      setTimeout(() => {
        focusNode && focusNode(newOrganization, false)
      }, 100)
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage || t('accountCenter.addModal.addFailed'))
    }
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalConent>
        <ImageWrapper>
          <img src={inviteImage} alt="invite" />
        </ImageWrapper>

        <ContentWrapper>
          <StyledTypography>
            <h4>{t('accountCenter.invitePopup.title', { email: organizationPayload.admin_email })}</h4>
            <p>{t('accountCenter.invitePopup.message')}</p>
            <p>{t('accountCenter.invitePopup.message2')}</p>
          </StyledTypography>

          <ButtonsWrapper>
            <Button variant="outlined" style={{ borderColor: '#979797' }} onClick={handleClose}>
              {t('accountCenter.invitePopup.cancel')}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleCreate(false)
              }}>
              {t('accountCenter.invitePopup.submit2')}
            </Button>
            <Button onClick={() => handleCreate()} variant="contained">
              {t('accountCenter.invitePopup.submit')}
            </Button>
          </ButtonsWrapper>
        </ContentWrapper>
      </ModalConent>
    </Modal>
  )
}

export default InvitePopup
