import dayjs from 'dayjs'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { US_DATE_WITH_MONTH_NAME } from '@/api/constants'
import { useOrganizationArchive } from '@/api/organizations/update'
import { getErrorMessage } from '@/api/utils/get-error'
import questionImg from '@/assets/images/popup/question.svg'
import useToast from '@/common/hooks/useToast'
import Popup from '../Popup'
import { ModalProps } from './TreeChart'

const ArchivePopup: FC<ModalProps> = ({ open, setOpen, organization }) => {
  const { t } = useTranslation()
  const { mutateAsync: archiveOrganization } = useOrganizationArchive()
  const { errorToast, successToast } = useToast()

  const handleArchive = async () => {
    try {
      await archiveOrganization({ id: organization.id })
      setOpen(false)
      successToast(t('accountCenter.archivePopup.success'))
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      errorToast(errorMessage)
    }
  }
  return (
    <Popup
      open={open}
      onClose={() => {
        setOpen(false)
      }}
      onConfirm={handleArchive}
      buttonLabels={{
        confirm: t('accountCenter.archivePopup.archive'),
        cancel: t('accountCenter.archivePopup.cancel'),
      }}
      icon={questionImg}
      extraBottom={
        <Trans
          i18nKey={'accountCenter.archivePopup.extraBottom'}
          components={{ 1: <b />, 2: <ul />, 3: <li /> }}></Trans>
      }>
      <Trans
        i18nKey={'accountCenter.archivePopup.content'}
        components={{ 1: <h4 />, 2: <p />, 3: <b />, 4: <ul />, 5: <li /> }}
        values={{
          state: t('accountCenter.archivePopup.state.' + organization.state),
          lastSignIn: organization.last_login
            ? dayjs.utc(organization.last_login).format(US_DATE_WITH_MONTH_NAME)
            : t('accountCenter.archivePopup.none'),
        }}></Trans>
    </Popup>
  )
}

export default ArchivePopup
