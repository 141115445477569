import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { PagedResult } from '@/types/common'
import { DynamicGroup, DynamicGroupListItem, GroupsListQuery } from '@/types/dynamicGroups'
import { API_URL } from '../constants'
import { ServerError } from '../types'

const getDynamicGroup = async (id: string) => {
  const url = `${API_URL}/dynamic-groups/${id}`
  const { data } = await axios.get(url, {
    withCredentials: true,
  })

  return data
}

const getDynamicGroups = async (params: GroupsListQuery) => {
  const url = `${API_URL}/dynamic-groups/`
  const { data } = await axios.get(url, {
    params,
    withCredentials: true,
  })

  return data
}

export const useDynamicGroups = (query: GroupsListQuery) =>
  useQuery<PagedResult<DynamicGroupListItem>>({
    queryKey: ['dynamic-groups', query],
    queryFn: () => getDynamicGroups(query),
  })

export const useDynamicGroup = (id: string) =>
  useQuery<DynamicGroup, ServerError>({
    queryKey: ['dynamic-group', id],
    queryFn: () => getDynamicGroup(id),
  })
