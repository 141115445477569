import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEye, faEyeSlash, faTriangleExclamation } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  keyframes,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
// @ts-ignore
import * as Yup from 'yup'

import { useAcceptInvitation } from '@/api/auth/accept-invitation'
import { useActivateAccount } from '@/api/auth/activate-account'
import { useVerifyToken, VerifyTokenResponse } from '@/api/auth/verify-token'
import { DIGITS_REGEX, LOWERCASE_REGEX, SPECIAL_CHARACTERS_REGEX, UPPERCASE_REGEX } from '@/api/constants'
import { getErrorMessage } from '@/api/utils/get-error'
import CywarenessLogo from '@/assets/images/logo-be-awere-stay-safe.svg'
import Fingerprint from '@/assets/images/logo-clean-blue.svg'
import SmartCityGif from '@/assets/images/smart-city.gif'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import useToast from '@/common/hooks/useToast'
import PasswordStrengthEvaluation from './PasswordStrengthEvaluation'
import Popup from '@/common/components/Popup'
import DeskIcon from '@/assets/images/popup/desk.svg'
import { useGetLatestTerms } from '@/api/terms/get'

// TODO: use the new FutureFormWrapper!!!
const AcceptInvitationPage = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { mutateAsync: verifyToken, isPending } = useVerifyToken()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const [tokenData, setTokenData] = useState<VerifyTokenResponse | null>(null)
  const [isPasswordConfirmVisible, setIsPasswordConfirmVisible] = useState(false)
  const [verifiedPopupOpen, setVerifiedPopupOpen] = useState(false)

  const token = queryParams.get('token')
  const { successToast, errorToast } = useToast()
  const { mutateAsync: activateAccount } = useActivateAccount()
  const { mutateAsync: acceptInvitation } = useAcceptInvitation()

  const initialNewAdminValues = { password: '', passwordConfirm: '', email: '', accept_terms: false, terms_verison: '' }
  const initialExistingAdminValues = { password: '', email: '', accept_terms: false, terms_verison: '' }
  const { data: latestTerms } = useGetLatestTerms()

  useEffect(() => {
    const fetchTokenData = async (token: string) => {
      setTokenData(await verifyToken(token))
    }
    if (token) {
      fetchTokenData(token)
    }
  }, [verifyToken, token])

  const handleSubmit = async (values: {
    password: string
    passwordConfirm: string
    email: string
    accept_terms: boolean
    terms: string
  }) => {
    try {
      if (tokenData?.type === 'invitation') {
        await acceptInvitation({
          token: token || '',
          password: values.password,
          accept_terms: values.accept_terms,
          terms: latestTerms?._id || '',
        })
      }
      if (tokenData?.type === 'activation') {
        await activateAccount({
          token: token || '',
          password: values.password,
          accept_terms: values.accept_terms,
          terms: latestTerms?._id || '',
        })
      }
      successToast(t('settings.admins.invitation.newAdminActivated'))
      window.location.href = '/'
    } catch (e) {
      const errorMessage = getErrorMessage(e)
      if (errorMessage === 'Email not verified') {
        setVerifiedPopupOpen(true)
      } else errorToast(errorMessage)
    }
  }

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(8)
      .matches(LOWERCASE_REGEX)
      .matches(UPPERCASE_REGEX)
      .matches(DIGITS_REGEX)
      .matches(SPECIAL_CHARACTERS_REGEX)
      .required(),
  })

  if (isPending) return <LoadingContainer />

  return (
    <>
      <Popup
        open={verifiedPopupOpen}
        onClose={() => setVerifiedPopupOpen(false)}
        icon={DeskIcon}
        buttonLabels={{
          cancel: 'Got it!!',
        }}>
        <Typography variant="h4">Uh oh!</Typography>
        <Trans i18nKey="settings.admins.invitation.inactivePopup" components={{ 1: <Typography /> }} />
      </Popup>
      <ConentContainer>
        <Logo />
        <LeftSideBox>
          {tokenData?.type !== 'invitation' || !tokenData?.verified ? (
            <>
              <PattayaTypography
                sx={{
                  fontSize: '96px',
                }}>
                {t('settings.admins.invitation.welcome')}
              </PattayaTypography>
              <PattayaTypography
                sx={{
                  fontSize: '48px',
                }}>
                {t('settings.admins.invitation.toTheFuture')} <br /> {t('settings.admins.invitation.cyberAwereness')}
              </PattayaTypography>
            </>
          ) : (
            <>
              <PattayaTypography
                sx={{
                  fontSize: '96px',
                }}>
                {t('settings.admins.invitation.welcomeBack')}
              </PattayaTypography>
              <PattayaTypography
                sx={{
                  fontSize: '48px',
                }}>
                {t('settings.admins.invitation.toJoin')} <br /> {t('settings.admins.invitation.organization')}
              </PattayaTypography>
            </>
          )}
          <GifBox />
        </LeftSideBox>
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Card>
            <img
              src={CywarenessLogo}
              style={{
                margin: '40px 30px',
              }}
            />
            {tokenData?.type !== 'invitation' || !tokenData?.verified ? (
              <>
                <Typography
                  sx={{
                    fontFamily: 'Montserrat',
                    fontWeight: '800',
                    textAlign: 'center',
                    fontSize: '23px',
                    padding: '0px 60px',
                  }}>
                  {t('settings.admins.invitation.welcomeToTheFuture')}
                </Typography>
                <Typography
                  sx={{
                    marginTop: '20px',
                    fontFamily: 'Montserrat',
                    fontWeight: '400',
                    textAlign: 'center',
                    fontSize: '16px',
                  }}>
                  {t('settings.admins.invitation.register')}
                </Typography>

                <Formik
                  onSubmit={handleSubmit}
                  initialValues={initialNewAdminValues}
                  validationSchema={validationSchema}>
                  {({ setFieldValue, isSubmitting, values, touched, setTouched, dirty, errors, isValid }) => (
                    <Form>
                      <FieldsWrapper>
                        <Box sx={{ height: '50px' }}>
                          <StyledTextField
                            disabled
                            value={tokenData?.email}
                            label={t('settings.admins.table.email')}
                            name="email"
                            placeholder={t('settings.admins.table.email')}
                            error={touched.email && errors.email ? true : false}
                          />
                        </Box>
                        <Box sx={{ height: '50px' }}>
                          <StyledTextField
                            required
                            type={isPasswordVisible ? 'text' : 'password'}
                            label={t('settings.admins.invitation.newPassword')}
                            name="password"
                            placeholder="********"
                            onChange={(value) => {
                              setFieldValue('password', value.target.value)
                              setTouched({ ...touched, ['password']: true }, true)
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      setIsPasswordVisible((e) => !e)
                                    }}
                                    icon={(isPasswordVisible ? faEyeSlash : faEye) as IconProp}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            error={touched.password && !!errors.password}
                          />
                        </Box>
                        {touched.password && <PasswordStrengthEvaluation password={values.password} />}
                        <Box sx={{ height: '50px' }}>
                          <StyledTextField
                            required
                            type={isPasswordConfirmVisible ? 'text' : 'password'}
                            label={t('settings.admins.invitation.confirmNewPassword')}
                            name="passwordConfirm"
                            placeholder="********"
                            onChange={(value) => {
                              setFieldValue('passwordConfirm', value.target.value)
                              setTouched({ ...touched, ['passwordConfirm']: true }, true)
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      setIsPasswordConfirmVisible((e) => !e)
                                    }}
                                    icon={(isPasswordConfirmVisible ? faEyeSlash : faEye) as IconProp}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            error={touched.password && values.password !== values.passwordConfirm}
                          />
                        </Box>
                        {(values.password || values.passwordConfirm) && values.password !== values.passwordConfirm && (
                          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <FontAwesomeIcon
                              icon={faTriangleExclamation as IconProp}
                              style={{ marginRight: '8px', color: '#EB115D', fontSize: '18px' }}
                            />
                            <Typography>Your passwords don't match. Try again!</Typography>
                          </Box>
                        )}
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => setFieldValue('accept_terms', !values.accept_terms)}
                              checked={values.accept_terms}
                            />
                          }
                          label={
                            <Trans
                              i18nKey="signup.form.terms"
                              components={{
                                1: <NavLink to={'https://cywareness.io/terms-of-service/'} target="_blank" />,
                                2: <NavLink to={'https://cywareness.io/privacy-policy/'} target="_blank" />,
                              }}
                            />
                          }
                          labelPlacement="end"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '12px',
                            },
                          }}
                        />
                      </FieldsWrapper>
                      <ButtonsWrapper>
                        <StyledButton
                          disableRipple
                          type="submit"
                          disabled={
                            !dirty ||
                            !isValid ||
                            isSubmitting ||
                            values.password !== values.passwordConfirm ||
                            !values.accept_terms
                          }
                          color="primary"
                          variant="contained">
                          {t('settings.admins.invitation.letGetStarted')}
                        </StyledButton>
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <Formik onSubmit={handleSubmit} initialValues={initialExistingAdminValues}>
                  {({ setFieldValue, isSubmitting, values, touched, setTouched, dirty, errors, isValid }) => (
                    <Form>
                      <FieldsWrapper>
                        <Box sx={{ height: '50px' }}>
                          <StyledTextField
                            disabled
                            value={tokenData?.email}
                            label={t('settings.admins.table.email')}
                            name="email"
                            placeholder={t('settings.admins.table.email')}
                            error={touched.email && errors.email ? true : false}
                          />
                        </Box>
                        <Box sx={{ height: '50px' }}>
                          <StyledTextField
                            required
                            type={isPasswordVisible ? 'text' : 'password'}
                            label={t('settings.admins.invitation.password')}
                            name="password"
                            placeholder="********"
                            onChange={(value) => {
                              setFieldValue('password', value.target.value)
                              setTouched({ ...touched, ['password']: true }, true)
                            }}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="start" style={{ cursor: 'default', width: '15px' }}>
                                  <FontAwesomeIcon
                                    onClick={() => {
                                      setIsPasswordVisible((e) => !e)
                                    }}
                                    icon={(isPasswordVisible ? faEyeSlash : faEye) as IconProp}
                                  />
                                </InputAdornment>
                              ),
                            }}
                            error={touched.password && !!errors.password}
                          />
                        </Box>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={() => setFieldValue('accept_terms', !values.accept_terms)}
                              checked={values.accept_terms}
                            />
                          }
                          label={
                            <Trans
                              i18nKey="signup.form.terms"
                              components={{
                                1: <NavLink to={'https://cywareness.io/terms-of-service/'} target="_blank" />,
                                2: <NavLink to={'https://cywareness.io/privacy-policy/'} target="_blank" />,
                              }}
                            />
                          }
                          labelPlacement="end"
                          sx={{
                            '& .MuiFormControlLabel-label': {
                              fontSize: '12px',
                            },
                          }}
                        />
                      </FieldsWrapper>
                      <ButtonsWrapper>
                        <StyledButton
                          disableRipple
                          type="submit"
                          disabled={!dirty || !isValid || isSubmitting || !values.password || !values.accept_terms}
                          color="primary"
                          variant="contained">
                          Join
                        </StyledButton>
                      </ButtonsWrapper>
                    </Form>
                  )}
                </Formik>
              </>
            )}
          </Card>
        </Box>
      </ConentContainer>
    </>
  )
}

const downAnimation = keyframes`
  0% {
    transform: translate(0px, -600px);
  }
  50% {
    transform: translate(0px, -600px);
  }
  90% {
    transform: translate(0px, 10px);
  }
  100% {
    transform: translate(0px, 0px);
  }
`
const upAnimation = keyframes`
0% {
  transform: translate(0px, 600px);
}
50% {
  transform: translate(0px, 600px);
}
90% {
  transform: translate(0px, -10px);
}
100% {
  transform: translate(0px, 0px);
}
`
const PattayaTypography = styled(Typography)(() => ({
  fontFamily: 'Pattaya',
  fontWeight: '400',
  background: 'linear-gradient(#96B3BA, #294D55 ,#1E1E1E)',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'center',
  padding: '0px 10px',
  marginBottom: '-20px',
  animation: `${downAnimation} 7s ease`,
}))

const GifBox = styled(Box)(() => ({
  backgroundImage: `url(${SmartCityGif})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: '440px',
  backgroundPosition: 'center',
  width: '500px',
  height: '450px',
  margin: '50px 0px 0px',
  animation: `${upAnimation} 7s ease`,
}))
const Card = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '430px',
  alignItems: 'center',
  height: 'fit-content',
  maxHeight: '800px',
  zIndex: 1,
  borderRadius: '20px',
  backgroundColor: 'white',
  opacity: '85%',
  paddingBottom: '20px',
}))
const ConentContainer = styled(Box)(() => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  margin: '0px',
  width: '100%',
  height: '100vh',
  justifyContent: 'flex-end',
  backgroundImage: 'linear-gradient(35deg, #FFFFFF 60%, #CBFAFF )',
}))

const StyledTextField = styled(TextField)(() => ({
  fontFamily: 'Montserrat',
  width: '100%',
  '.MuiInputBase-input': {
    padding: '15px 10px',
  },
}))
const ButtonsWrapper = styled(Box)(() => ({
  padding: '0px 20px 10px',
  display: 'flex',
  gap: 40,
  flexDirection: 'row',
  justifyContent: 'center',
}))
const FieldsWrapper = styled(Box)(() => ({
  padding: '20px 40px',
  display: 'flex',
  gap: 16,
  flexDirection: 'column',
}))
const StyledButton = styled(Button)(() => ({
  px: '30px',
  py: '10px',
  width: 'fit-content',
  padding: '10px 30px',
  height: '40px',
  fontSize: '16px',
  fontWeight: 500,
  color: 'black',
}))
const LeftSideBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '20px',
  overflow: 'hidden',
  minWidth: '534px',
}))

const Logo = styled(Box)(() => ({
  backgroundImage: `url(${Fingerprint})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  position: 'fixed',
  opacity: '25%',
  bottom: '-300px',
  right: '-200px',
  width: '900px',
  height: '900px',
  scale: '-1 1',
}))

export default AcceptInvitationPage
