import { faShieldCheck, faShieldXmark } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Link, styled, Typography } from '@mui/material'
import { FC, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ColumnInterface } from 'react-table'

import { useClientDetails } from '@/api/client/client'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { theme } from '@/theme/theme'
import { BulkUploadConflict, UploadMemberConflictResolutionStrategy } from '@/types/members'
import { ConflictResolution } from '.'
import StepSubmit from './StepSubmit'

type Step4Props = {
  importedDomain: any[]
  fileUpload: (dryRun: boolean) => void
  isLoading: boolean
  conflicts: BulkUploadConflict[]
  conflictResolution: ConflictResolution
  handleClose: () => void
  setStep: (step: number) => void
}

const Step4: FC<Step4Props> = ({
  handleClose,
  fileUpload,
  conflicts,
  conflictResolution,
  isLoading,
  setStep,
  importedDomain,
}) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: clientDetails } = useClientDetails()
  const unverifiedMembersCount = importedDomain
    .filter((domain) => !domain.verified)
    .reduce((sum, members) => sum + members.count, 0)

  // Recall bulkUploadMember with dry run to verify domains after conflict resolution
  useEffect(() => {
    if (conflicts.length > 0 && conflictResolution.strategy !== UploadMemberConflictResolutionStrategy.keep_existing) {
      fileUpload(true)
    }
  }, [])

  return (
    <>
      {unverifiedMembersCount === 0 ? (
        <Typography>
          <Trans
            i18nKey={'bulkUserUpload.domainVerification.verifiedDomains'}
            components={{ 1: <Typography component="span" fontWeight={theme.typography.fontWeightMedium} /> }}
          />
        </Typography>
      ) : (
        <>
          <Typography>
            {clientDetails?.allow_unverified_domains ? (
              <Trans
                i18nKey={'bulkUserUpload.domainVerification.unverifiedAllowed'}
                components={{ 1: <Typography component="span" fontWeight={theme.typography.fontWeightMedium} /> }}
              />
            ) : (
              <Trans
                i18nKey={'bulkUserUpload.domainVerification.unverifiedNotAllowed'}
                components={{ 1: <Typography component="span" fontWeight={theme.typography.fontWeightMedium} /> }}
              />
            )}
          </Typography>
          <Typography>
            {clientDetails?.allow_unverified_domains ? (
              <Trans
                i18nKey={'bulkUserUpload.domainVerification.unverifiedDomainsAllowed'}
                components={{
                  1: <Typography component="span" fontWeight={theme.typography.fontWeightMedium} />,
                  2: <b />,
                }}
                values={{ unverifiedMembersCount: unverifiedMembersCount.toLocaleString() }}
              />
            ) : (
              <Trans
                i18nKey={'bulkUserUpload.domainVerification.unverifiedDomainsNotAllowed'}
                components={{
                  1: <Typography component="span" fontWeight={theme.typography.fontWeightMedium} />,
                  2: <b />,
                }}
                values={{ unverifiedMembersCount: unverifiedMembersCount.toLocaleString() }}
              />
            )}
          </Typography>
        </>
      )}
      {/* <Typography fontWeight={theme.typography.fontWeightMedium} paddingTop={1}>
        {t('bulkUserUpload.domainVerification.domainVerificationSummary')}
      </Typography> */}
      <BulkUploadMembersDomains tableData={importedDomain} isLoading={isLoading} />

      <Typography>
        <Trans
          i18nKey={'bulkUserUpload.domainVerification.verifyMore'}
          components={{
            1: (
              <Link
                color={theme.palette.blue[800]}
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  window.open('/settings/allowlisting', '_blank')
                }}
              />
            ),
          }}
          values={{ parentName: clientDetails?.parent_name }}
        />
      </Typography>
      <StepSubmit handleClose={handleClose} confirmHandler={() => setStep(5)} isSubmitDisabled={isLoading} />
    </>
  )
}

const BulkUploadMembersDomains: FC<{ tableData: any[]; isLoading: boolean }> = ({ tableData, isLoading }) => {
  const { t } = useTranslation()

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: <b>{t('bulkUserUpload.domainVerification.domainName')}</b>,
        accessor: 'domain',
        disableSortBy: false,
      },
      {
        Header: <b>{t('bulkUserUpload.domainVerification.members')}</b>,
        accessor: 'count',
        format: (data: any) => data.value.toLocaleString(),
      },
      {
        Header: <b>{t('bulkUserUpload.domainVerification.state')}</b>,
        accessor: 'verified',

        Cell: (data: any) => {
          return <DomainStatus verified={data.value} />
        },
      },
    ],
    [t]
  )

  return (
    <StyledTableWrapper>
      <SimpleTable
        loading={isLoading}
        isEditable={false}
        enableCheckbox={false}
        columns={columns}
        data={tableData}
        setData={() => {
          throw new Error('Function not implemented.')
        }}
        noResultsContent={<>No Results</>}
        lastChildAlignment="right"
      />
    </StyledTableWrapper>
  )
}

// TODO: Consider move to common components
type DomainStatusProps = {
  verified: boolean
}

const DomainStatus: FC<DomainStatusProps> = ({ verified }) => {
  const { t } = useTranslation()
  return (
    <StyledDomainStatusConatiner>
      <FontAwesomeIcon
        icon={verified ? faShieldCheck : faShieldXmark}
        color={verified ? theme.palette.green[800] : theme.palette.red[900]}
        fontSize={20}
      />
      <Typography>{t('bulkUserUpload.domainStatus.' + (verified ? 'verified' : 'unverfied'))}</Typography>
    </StyledDomainStatusConatiner>
  )
}

const StyledDomainStatusConatiner = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  gap: theme.spacing(1),
}))

const StyledTableWrapper = styled(Box)(() => ({
  border: `solid 1px ${theme.palette.grey[400]}`,
  borderRadius: '10px',
  padding: theme.spacing(1),
  margin: theme.spacing(1, 0),
  height: 440,
  overflow: 'auto',
}))

export default Step4
