import { FC } from 'react'
import { Route, Routes } from 'react-router-dom'

import FutureFormWrapper from '@/common/components/Layout/FutureFormWrapper'
import App from './App'
import ChooseType from './ChooseType'
import Email from './Email'

const FMASetupPage: FC = () => {
  return (
    <FutureFormWrapper>
      <Routes>
        <Route path="/" element={<ChooseType />} />
        <Route path="app" element={<App />} />
        <Route path="email" element={<Email />} />
      </Routes>
    </FutureFormWrapper>
  )
}

export default FMASetupPage
