import useLottie from '@/common/hooks/useLottie'
import { useAuth } from '@/context/Auth'
import { theme } from '@/theme/theme'
import { Box, Button, Link, styled, Typography } from '@mui/material'
import archivedAnimationDataq from '@/assets/lottie/archived.json'
import { Trans, useTranslation } from 'react-i18next'
import { useClientDetails } from '@/api/client/client'
import { useLogout } from '@/api/auth/logout'
import { useNavigate } from 'react-router-dom'
const ArchivedOrganiztionPage = () => {
  const { user } = useAuth()
  const { data: client } = useClientDetails()
  const [logout] = useLogout()
  const navigateTo = useNavigate()
  const { t } = useTranslation()

  const { LottieAnimation } = useLottie({
    animationData: archivedAnimationDataq,
    width: 300,
    height: 300,
    options: {
      loop: true,
      autoplay: true,
      animationData: archivedAnimationDataq,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  })

  const backToAccountCenter = () => {
    navigateTo('/account-center')
  }
  async function handleLogout() {
    await logout()
    navigateTo('/sign-in')
  }
  return (
    <StyledContainer>
      <StyledPattayaTypography fontSize={'36px'}> {t('archivedOrganization.title')}</StyledPattayaTypography>
      <Typography>{t('archivedOrganization.subTitle')}</Typography>
      <Box marginBottom={-3}>{LottieAnimation}</Box>
      <Trans
        i18nKey={'archivedOrganization.message'}
        components={{
          1: <Typography fontWeight={theme.typography.fontWeightMedium} align="center" />,
          2: <Link href="mailto:support@cywareness.io" color={theme.palette.blue[900]} />,
        }}
        values={{
          parentName: client?.parent_name,
        }}></Trans>
      {user?.has_multiple_clients ? (
        <Button size="large" variant="contained" onClick={backToAccountCenter}>
          {t('archivedOrganization.backToAccountCenter')}
        </Button>
      ) : (
        <Button size="large" variant="contained" onClick={handleLogout}>
          {t('archivedOrganization.backToLogin')}
        </Button>
      )}
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  minHeight: '100vh',
})

const StyledPattayaTypography = styled(Typography)(({ fontSize }: { fontSize: string }) => ({
  fontFamily: 'Pattaya',
  fontWeight: theme.typography.fontWeightRegular,
  background: `linear-gradient(${theme.palette.blueGray[200]}, ${theme.palette.blueDianne[900]} ,${theme.palette.grey[900]})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textAlign: 'center',
  fontSize: fontSize,
  padding: '0px 10px',
  marginBottom: '-20px',
}))

export default ArchivedOrganiztionPage
