import { useMutation } from '@tanstack/react-query'
import axios from 'axios'

import { API_URL } from '../constants'

type MFASetup = {
  type: 'email' | 'app'
}

const mfaSetup = async (setupPaypload: MFASetup) => {
  const url = `${API_URL}/profile/mfa/setup`
  const { data } = await axios.post(url, setupPaypload, {
    withCredentials: true,
  })

  return data
}

export const useMFASetup = () => {
  return useMutation({ mutationFn: mfaSetup })
}
