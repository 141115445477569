import { TableFooter, TablePagination, TableRow } from '@mui/material'
import { ChangeEvent, FC, MouseEvent } from 'react'
import TablePaginationActions from '../Tables/TablePaginationActions'

type CustomTableFooterProps = {
  colSpan: number
  total: number
  currentPage: number
  disabled?: boolean
  rowsPerPageOptions?: number[]
  queryParams: any
  updateQueryParam: (newValues: any) => void
}

const CustomTableFooter: FC<CustomTableFooterProps> = ({
  colSpan,
  total,
  currentPage,
  disabled = false,
  rowsPerPageOptions = [5, 10, 25, 50],
  queryParams,
  updateQueryParam,
}) => {
  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    updateQueryParam({
      skip: newPage * queryParams.limit,
    })
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateQueryParam({
      skip: 0, // When changing the rows per page, we want to go back to the first page
      limit: parseInt(event.target.value),
    })
  }

  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          colSpan={colSpan}
          count={total}
          rowsPerPage={queryParams.limit}
          page={currentPage}
          disabled={disabled}
          slotProps={{
            select: {
              inputProps: {
                'aria-label': 'rows per page',
              },
              native: true,
            },
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </TableRow>
    </TableFooter>
  )
}

export default CustomTableFooter
