import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Typography } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useVerifyPasswordResetToken } from '@/api/auth/verify-password-reset-token'
import { ServerError } from '@/api/types'
import TextField from '@/common/components/Inputs/TextFieldV2'

const VerifyResetToken: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { username } = useParams()
  const [token, setToken] = useState('')
  const [verified, setVerified] = useState(false)

  const [verifyToken, { isLoading }] = useVerifyPasswordResetToken(() => {
    setVerified(true)
  })

  useEffect(() => {
    if (verified) navigateTo(`/reset-password/set-new/${username}/${token}`)
  }, [verified])

  const handleSubmit = async (
    values: { token: string },
    { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }
  ) => {
    try {
      setToken(values.token)
      await verifyToken({ ...values, ...{ username } })
    } catch (e) {
      setFieldError('token', (e as ServerError).response.data.message)
    }
  }

  const initialValues = {
    token: '',
  }
  const validationSchema = Yup.object().shape({
    token: Yup.string().required('Field required'),
  })

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Box width={568} display="flex" flexDirection="column" gap={3}>
            <Typography textAlign="center">
              <Trans
                i18nKey={'verifyToken.enterCodeDescription'}
                components={{
                  1: <br />,
                }}
                values={{ username: username }}
              />
            </Typography>
            <TextField required label={t('verifyToken.form.code')} name="token" />
            <Box sx={{ width: '100%', paddingRight: 0.5 }}>
              <LoadingButton
                size="large"
                type="submit"
                loading={isSubmitting || isLoading}
                variant="contained"
                disabled={!dirty || !isValid}
                sx={{ width: '100%' }}>
                {t('verifyToken.verify')}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default VerifyResetToken
