import axios from 'axios'
import { API_URL } from '../constants'
import { useQuery } from 'react-query'
import { ServerError } from '../types'
import { Office } from '@/types/offices'

// TODO : when change the BE to new endpoint move this to the get.ts file.
export async function getOfficeById(key, _id) {
  const url = `${API_URL}/offices/${_id}`
  const { data } = await axios.get(url, { withCredentials: true })
  return data.data
}

export function useOffice(_id: string) {
  return useQuery<Office, ServerError>(['office', _id], getOfficeById)
}
