import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faImage } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  styled,
} from '@mui/material'
import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
// @ts-ignore
import * as Yup from 'yup'

import { useUpdateClient } from '@/api/client/update-client'
import { countriesArray } from '@/assets/countries'
import TextField from '@/common/components/Inputs/TextFieldV2'
import HelperTextComponent from '@/common/components/Inputs/TextFieldV2/HelperTextComponent'
import UploadImageModal from '@/common/components/UploadImageModal/UploadImageModal'
import useToast from '@/common/hooks/useToast'
import { theme } from '@/theme/theme'
import { FieldWrapper, Title } from './ConfigurationsStyledComponents'

type OrganizationSettingsProps = {
  name?: string
  country?: string
  logo?: string
}

const OrganizationSettings: FC<OrganizationSettingsProps> = ({ name, country, logo }) => {
  const { t } = useTranslation()
  const [updateClient, { isLoading: isUpdatingClient }] = useUpdateClient()
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false)
  const [initialClientValues, setInitialClientValues] = useState({
    organizationName: name || '',
    organiztionCountry: country || '',
  })
  const [logoUrl, setLogoUrl] = useState(logo || '')
  const { successToast, errorToast } = useToast()
  const validationSchema = Yup.object().shape({
    organizationName: Yup.string()
      .min(2)
      .required(t('settings.configurationTab.organizationSettings.organiztaionNameRequired')),
    organiztionCountry: Yup.string().required(
      t('settings.configurationTab.organizationSettings.organizationOriginRequired')
    ),
  })

  async function handleOnFinishImageUpload(url: string) {
    await updateClient({ logo: url })
    setLogoUrl(url)
  }

  const handleSubmit = async (values: { organizationName: string; organiztionCountry: string }) => {
    const { organizationName, organiztionCountry } = values
    if (organizationName && organiztionCountry) {
      try {
        await updateClient({ name: organizationName, origin_country: organiztionCountry })
        setInitialClientValues(values)
        successToast(t('settings.configurationTab.organizationSettings.organizationUpdated'))
      } catch (e) {
        errorToast(t('settings.configurationTab.organizationSettings.errors.organizationUpdateFailed'))
      }
    }
  }

  return (
    <Box style={{ paddingBottom: '20px' }}>
      <Title>{t('settings.configurationTab.organizationSettings.organizationSettings')}</Title>
      <LogoContainer
        src={logoUrl}
        onClick={() => {
          setImageModalIsOpen(true)
        }}>
        <UploadOnHover hasLogo={logoUrl !== ''}>
          <FontAwesomeIcon fontSize={40} icon={faImage as IconProp} />
          <Typography>
            {logoUrl !== ''
              ? t('settings.configurationTab.organizationSettings.replacePhoto')
              : t('settings.configurationTab.organizationSettings.uploadPhoto')}
          </Typography>
        </UploadOnHover>
      </LogoContainer>
      <Formik onSubmit={handleSubmit} initialValues={initialClientValues} validationSchema={validationSchema}>
        {({ setFieldValue, isSubmitting, values, dirty, isValid, errors, touched }) => (
          <Form>
            <Box display="flex" flexDirection={'column'} gap={2}>
              <TextField
                label={t('settings.configurationTab.organizationSettings.organiztaionName')}
                name="organizationName"
                required
                placeholder={t('settings.configurationTab.organizationSettings.organiztaionName')}
              />
              <StyledFormControl
                fullWidth
                size="small"
                variant="outlined"
                error={!values.organiztionCountry && touched.organizationName}>
                <InputLabel required htmlFor="style-select">
                  {t('settings.configurationTab.organizationSettings.organizationOrigin')}
                </InputLabel>
                <Select
                  size="small"
                  required
                  value={values.organiztionCountry}
                  name="organizationName"
                  placeholder={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                  onChange={(value) => {
                    setFieldValue('organiztionCountry', value.target.value)
                  }}
                  input={
                    <StyledOutlinedInput
                      label={t('settings.configurationTab.organizationSettings.organizationOrigin')}
                    />
                  }>
                  {countriesArray.map((country) => {
                    return <MenuItem value={country.code}>{country.name}</MenuItem>
                  })}
                </Select>
                <Box paddingTop={0.3} height={theme.spacing(2)}>
                  <HelperTextComponent hasError={!!errors.organiztionCountry} helperText={errors.organiztionCountry} />
                </Box>
              </StyledFormControl>
            </Box>
            <FieldWrapper>
              <Button
                sx={{ width: '100%' }}
                type="submit"
                disabled={!dirty || !isValid || isSubmitting || isUpdatingClient}
                variant="contained">
                {t('settings.configurationTab.organizationSettings.saveCahnge')}
              </Button>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
      <UploadImageModal
        open={imageModalIsOpen}
        setOpen={setImageModalIsOpen}
        onFinishUpload={handleOnFinishImageUpload}
      />
    </Box>
  )
}

const StyledFormControl = styled(FormControl)(() => ({
  label: {
    fontSize: '12px',
    padding: '2px 8px',
    margin: '1px 0 0',
  },
  '& .MuiInputLabel-root[data-shrink="false"]': {
    padding: 0,
    margin: 0,
    fontSize: '16px',
  },
}))

const StyledOutlinedInput = styled(OutlinedInput)(() => ({
  '& MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  fieldset: {
    borderRadius: '10px',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderWidth: '0.5px',
  },
  '&:hover fieldset': {
    borderWidth: '0.5px',
  },
  '& legend': {
    fontSize: '12px',
    width: '108px',
    marginLeft: '4px',
  },
}))

const UploadOnHover = styled(Box)(({ hasLogo }: { hasLogo: boolean }) => ({
  background: 'rgba(1,1,1,0.6)',
  color: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  opacity: hasLogo ? '0%' : '50%',
  transition: 'opacity 0.5s',
  cursor: 'pointer',
  '&:hover': {
    opacity: '100%',
  },
}))

const LogoContainer = styled(Box)(({ src }: { src: string }) => ({
  backgroundImage: `url(${src})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: '200px',
  border: '1px solid #B8B8B8',
  borderRadius: '15px',
  height: '120px',
  flex: 1,
  marginBottom: '15px',
  overflow: 'clip',
}))

export default OrganizationSettings
