import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { faChartNetwork, faFingerprint, faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Avatar,
  Box,
  Button,
  Divider,
  ListItem,
  ListItemText,
  MenuItem,
  Popover,
  Skeleton,
  Typography,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useMeasure from 'react-use-measure'

import { useLogout } from '@/api/auth/logout'
import { useClientDetails } from '@/api/client/client'
import NewLabelAnimation from '@/common/animations/NewLabelAnimation'
import { sanitizeString } from '@/common/utils/sanitizeString'
import { useAuth } from '@/context/Auth'
import { OrganizationAccountType } from '@/types/organizations'
import TruncatedText from '../TruncatedText/TruncatedText'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import useNavigateWithLayoutBlocker from '@/common/hooks/useNavigateWithLayoutBlocker'

interface ProfileButtonProps {
  firstName: string
}

const ProfileButton: FC<ProfileButtonProps> = ({ firstName = '' }) => {
  const classes = useStyles()

  return <Avatar className={classes.avatar}>{firstName.charAt(0).toUpperCase()}</Avatar>
}

const Dropdown: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigateWithLayoutBlocker()
  const { user } = useAuth()
  const { data: client } = useClientDetails()
  const [ref, { width }] = useMeasure()
  const theme = useTheme()
  const [logout] = useLogout()
  const [anchorElement, setAnchorElement] = useState<null | Element>(null)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorElement(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorElement(null)
  }

  const handleMyProfile = () => {
    navigateTo(`/settings/admins/${user?.id}`)
    setAnchorElement(null)
  }
  async function handleLogout() {
    await logout()
    navigateTo('/sign-in')
  }
  return (
    <>
      <Button
        disabled={!user}
        ref={ref}
        className={classes.profile}
        startIcon={<ProfileButton firstName={user?.full_name || client?.name || ''} />}
        endIcon={
          <Box className={classes.iconsWrpper}>
            <FontAwesomeIcon
              icon={Boolean(anchorElement) ? faChevronUp : (faChevronDown as IconProp)}
              style={{ fontSize: 12 }}
            />
            <NewLabelAnimation size={30} style={{ marginTop: '-4px' }}></NewLabelAnimation>
          </Box>
        }
        size={'large'}
        variant={'text'}
        aria-haspopup="true"
        onClick={handleClick}
        aria-label={'dropdown menu'}>
        {client || user ? (
          <ListItem className={classes.listItemWrapper}>
            <ListItemText
              color={theme.palette.white}
              primary={
                <TruncatedText
                  text={sanitizeString(user?.full_name ? user.full_name : client?.name || '')}
                  maxLength={14}
                />
              }
              secondary={<TruncatedText text={sanitizeString(client?.name)} maxLength={14} />}
              secondaryTypographyProps={{
                style: { color: theme.palette.white },
              }}
            />
          </ListItem>
        ) : (
          <Skeleton width={120} height={32} />
        )}
      </Button>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id="simple-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={handleClose}>
        <Box padding={2} display="flex" flexDirection="column" gap={1}>
          <MenuItem onClick={handleMyProfile}>
            <FontAwesomeIcon
              icon={faFingerprint}
              className={`${classes.icon} ${classes.iconFa}`}
              color={'inherit'}
              beat
            />
            <Typography color={'inherit'}>{t('navbar.myProfile')}</Typography>
            <NewLabelAnimation size={32} style={{ padding: '0px 15px' }} isDark></NewLabelAnimation>
          </MenuItem>
          <Divider />
          {user?.has_multiple_clients || client?.type !== OrganizationAccountType.client ? (
            <>
              <MenuItem className={classes.menuItem} color={'secondary'} onClick={() => navigateTo('/account-center')}>
                <FontAwesomeIcon
                  icon={faChartNetwork}
                  className={`${classes.icon} ${classes.iconFa}`}
                  color={'inherit'}
                />
                <Typography color={'inherit'}>{t('navbar.accountCenter')}</Typography>
              </MenuItem>
              <Divider />
            </>
          ) : null}
          <MenuItem
            onClick={handleLogout}
            className={[classes.menuItem, classes.logout].join(' ')}
            style={{ width }}
            color={'secondary'}>
            <FontAwesomeIcon
              icon={faArrowRightFromBracket as IconProp}
              className={`${classes.icon} ${classes.iconFa}`}
            />
            <Typography color={'inherit'}>{t('navbar.signOut')}</Typography>
          </MenuItem>
        </Box>
      </Popover>
    </>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    profile: {
      padding: 0,
      paddingRight: theme.spacing(2),
      color: theme.palette.common.white,
      textTransform: 'capitalize',
      '&:hover': {
        background: 'transparent',
      },
    },
    listItemWrapper: {
      width: '134px',
      paddingBottom: 0,
      paddingLeft: theme.spacing(2),
    },
    menuItem: {
      minWidth: 180,
      display: 'flex',
      alignItems: 'center',
      color: theme.palette.text.primary,
    },
    logout: {
      color: theme.palette.error.main,
    },
    icon: {
      fontSize: '28px !important',
      width: 64,
      margin: 0,
      marginLeft: 0,
    },
    iconFa: {
      fontSize: '18px !important',
    },
    avatar: {
      width: 34,
      height: 34,
      backgroundColor: theme.palette.cyan[500],
      color: theme.palette.common.black,
      border: '1px solid white',
      marginLeft: theme.spacing(2),
    },
    iconsWrpper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '17px',
    },
  })
)
export default Dropdown
