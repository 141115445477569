import { useCallback, useEffect, useState, useMemo } from 'react'
import SimpleTable from '@/common/components/Tables/SimpleTable'
import { useTranslation } from 'react-i18next'
import { ColumnInterface } from 'react-table'
import { Group } from '@/api/groups/groups'
import Filter, { FilterObject } from '@/common/components/Tables/Filter'
import { filter } from 'lodash'
import { Paper } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useGroups } from '@/api/groups/get'
import { ALL_DATA_LIMIT } from '@/api/constants'

type GroupTableProps = {
  currentOfficeId: string
}

export default function OfficeGroupsTable({ currentOfficeId }: GroupTableProps) {
  const INITIAL_QUERY_FILTERS = {
    name: '',
    offices: [currentOfficeId],
  }

  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const [queryFilters, setQueryFilters] = useState(INITIAL_QUERY_FILTERS)
  const [data, setData] = useState<Group[]>([])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.groupsTable.group'),
        accessor: 'name',
      },
      {
        Header: t('users.groupsTable.userCount'),
        accessor: 'user_count',
        Cell: ({ value }) => <div style={{ paddingLeft: 26 }}>{value}</div>,
      },
      {
        Header: t('users.groupsTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }) => <div style={{ paddingLeft: 14 }}>{value}</div>,
      },
    ],
    [t]
  )

  const handleFilterChange = useCallback((values: FilterObject[]) => {
    const newFilters = {
      offices: [currentOfficeId],
    }
    values.forEach((appliedFilter) => {
      newFilters[appliedFilter.key] = appliedFilter.value
    })
    setQueryFilters((prevState) => ({ ...prevState, ...newFilters }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filters = [{ key: 'name', label: 'Name', value: '' }]

  useEffect(() => {
    let filteredGroups = groups ? [...groups] : []
    if (queryFilters.name) {
      filteredGroups = filter(groups, (group) => {
        return queryFilters.name ? group.name.toLowerCase().startsWith(queryFilters.name) : true
      })
    }
    setData(filteredGroups)
  }, [groups, queryFilters])

  return (
    <div>
      <Filter filters={filters} onChange={handleFilterChange} />
      <Paper>
        <SimpleTable
          enableCheckbox={false}
          columns={columns}
          data={data}
          setData={() => {}}
          noResultsTextId={'users.groupsTable.noResults'}
          onRowClick={(row) => {
            navigateTo(`/recipients/groups/${row.original._id}`)
          }}
        />
      </Paper>
    </div>
  )
}
