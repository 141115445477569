import axios from 'axios'
import { API_URL } from '../constants'
import { useMutation, useQueryCache } from 'react-query'
import { ServerError } from '../types'
import { Group } from '@/types/groups'

export type NewGroupParams = {
  name: string
  users: string[]
  offices: string[]
  managers: string[]
}

export async function createNewGroup(params: NewGroupParams): Promise<Group> {
  const url = `${API_URL}/groups/`
  const { data } = await axios.post(url, params, { withCredentials: true })
  return data
}

export function useNewGroup() {
  const queryCache = useQueryCache()
  return useMutation<Group, ServerError, NewGroupParams>(createNewGroup, {
    throwOnError: true,
    onSuccess: () => {
      queryCache.invalidateQueries('groups')
    },
  })
}
