import makeStyles from '@mui/styles/makeStyles'
import createStyles from '@mui/styles/createStyles'
import { useAuthClients } from '@/api/profile/clients'
import ClientCard from './ClientCard'
import { Button, Typography } from '@mui/material'
import { useLogout } from '@/api/auth/logout'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LoadingContainer from '@/common/components/LoadingContainer/LoadingContainer'
import LogoIcon from '@/assets/images/logo-clean-blue.svg'
import LogoText from '@/assets/images/logo-text-dark.svg'
import { faArrowRightFromBracket } from '@fortawesome/pro-light-svg-icons'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function ChooseClient(props) {
  const classes = useStyles()
  const navigateTo = useNavigate()
  const { t } = useTranslation()

  const [logout] = useLogout()
  const { data: authClients, isLoading } = useAuthClients()

  async function handleLogout() {
    await logout()
    navigateTo('/sign-in')
  }
  return (
    <div className={classes.root}>
      <div className={classes.flex}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt={'Cywareness Logo'} src={LogoIcon} height={30} width={26} />
          <img alt={'Cywareness Logo'} src={LogoText} height={22} />
        </div>
        <Button
          endIcon={<FontAwesomeIcon icon={faArrowRightFromBracket as IconProp} />}
          onClick={handleLogout}
          color={'primary'}>
          {t('navbar.logout')}
        </Button>
      </div>
      <div className={classes.container}>
        <Typography variant={'h6'}>{t('navbar.chooseClient')} </Typography>
        <div className={classes.cardContainer}>
          {isLoading ? <LoadingContainer /> : (authClients || []).map((client) => <ClientCard client={client} />)}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    container: {
      minHeight: '80vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    cardContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: theme.spacing(4, 6),
    },
    logo: {
      marginRight: theme.spacing(2),
    },
  })
)
