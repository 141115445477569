import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faEnvelope, faMobile } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LoadingButton from '@mui/lab/LoadingButton'
import { Box, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { theme } from '@/theme/theme'

const ChooseType: FC = ({}) => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
        {t('mfa.title')}
      </Typography>
      <Typography textAlign="center">{t('mfa.setup.chooseMethod')}</Typography>
      <Box display="flex" justifyContent="center" gap={2} minWidth={'568px'}>
        <AppLoadingButton variant="outlined" onClick={() => navigateTo('/mfa-setup/app')}>
          <FontAwesomeIcon icon={faMobile as IconProp} fontSize="48" />
          {t('mfa.setup.methodApp')}
        </AppLoadingButton>
        <AppLoadingButton variant="outlined" onClick={() => navigateTo('/mfa-setup/email')}>
          <FontAwesomeIcon icon={faEnvelope as IconProp} fontSize="48" />
          {t('mfa.setup.methodEmail')}
        </AppLoadingButton>
      </Box>
    </Box>
  )
}

const AppLoadingButton = styled(LoadingButton)({
  width: 128,
  height: 128,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderWidth: 5,
  borderColor: theme.palette.cyan[50],
  gap: 16,
  borderRadius: 8,
  '&:hover': {
    borderWidth: 5,
    borderColor: theme.palette.cyan[100],
  },
  '&:active': {
    borderWidth: 5,
    borderColor: theme.palette.cyan[200],
  },
  '&:disabled': {
    borderWidth: 5,
    borderColor: theme.palette.grey[100],
  },
})

export default ChooseType
