import { Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
//@ts-ignore

import { formatSeconds } from '@/common/utils/time'
import { theme } from '@/theme/theme'
import { FC, useEffect, useState } from 'react'

type EmailResendTimerProps = {
  handleClick: () => void
  time?: number
}

const EmailResendTimer: FC<EmailResendTimerProps> = ({ handleClick, time = 60 }) => {
  const { t } = useTranslation()
  const [resendTimer, setResendTimer] = useState(0)

  useEffect(() => {
    if (resendTimer === 0) return
    const timerId = setInterval(() => {
      setResendTimer((prev) => prev - 1)
    }, 1000)
    return () => clearInterval(timerId)
  }, [resendTimer])

  if (resendTimer)
    return (
      <Typography fontWeight={theme.typography.fontWeightSemiBold}>
        {t('mfa.resendTimer')} {formatSeconds(resendTimer)}
      </Typography>
    )

  return (
    <Typography fontWeight={theme.typography.fontWeightSemiBold}>
      <Trans
        i18nKey={'mfa.resend'}
        components={{
          1: (
            <Typography
              onClick={() => {
                handleClick()
                setResendTimer(60)
              }}
              component="text"
              color={theme.palette.blue[900]}
              fontWeight={theme.typography.fontWeightSemiBold}
              textDecoration="underline"
              sx={{ cursor: 'pointer' }}
            />
          ),
        }}
      />
    </Typography>
  )
}

export default EmailResendTimer
