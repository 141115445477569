import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAtom, faBullhorn, faUsers } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Divider, Grid, Paper, Tooltip, Typography } from '@mui/material'
import { styled, useTheme } from '@mui/material/styles'
import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { ColumnInterface } from 'react-table'
import dayjs from 'dayjs'

import { ALL_DATA_LIMIT, EU_DATE_TIME_24HR } from '@/api/constants'
import { useDynamicGroup } from '@/api/dynamic-groups/get'
import { useGroups } from '@/api/groups/get'
import { useOffices } from '@/api/offices/get'
import CustomBreadcrumbs from '@/common/components/Breadcrumbs/Breadcrumbs'
import GaugeScoremeter from '@/common/components/GaugeScoremeter/GaugeScoremeter'
import HumanizedScore from '@/common/components/HumanizedScore/HumanizedScore'
import MoreMenu from '@/common/components/MoreMenu/MoreMenu'
import SimpleSearchBar from '@/common/components/SimpleSearchBar/SimpleSearchBar'
import InTableMessageWithAnimation from '@/common/components/Tables/InTableMessageWithAnimation'
import PaginatingTable from '@/common/components/Tables/PaginatingTable'
import { getDefaultTableSize, setDefaultTableSize, TABLE_SIZE_REFERENCE_KEYS } from '@/common/utils/table-size'
import { theme } from '@/theme/theme'
import { useDynamicGroupMemebers } from '@/api/dynamic-groups/getMembers'
import { SortOrder } from '@/types/common'

const INITIAL_QUERY_FILTERS = {
  sort_by: 'first_name',
  sort_order: SortOrder.asc,
  search: '',
  skip: 0,
  limit: 10,
}

type FetchDataParams = {
  pageSize: number
  pageIndex: number
  sortBy?: string
  sortOrder?: SortOrder.asc
  skip?: number
  limit?: number
}

const DynamicGroupProfile: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const [queryFilters, setQueryFilters] = useState(INITIAL_QUERY_FILTERS)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageCount, setPageCount] = useState(0)

  const theme = useTheme()

  const { _id } = useParams()
  const { data: dynamicGroup } = useDynamicGroup(_id as string)
  const { data: dynamicGroupMembers, isLoading } = useDynamicGroupMemebers({
    id: _id as string,
    params: queryFilters,
  })
  const { data: officesData } = useOffices(ALL_DATA_LIMIT)
  const offices = officesData?.results || []
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []

  const { results: members, total } = dynamicGroupMembers ?? { total: 0, results: [] }
  const defaultRowsPerPage = getDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE)

  const handleSearch = (value: string) => {
    setQueryFilters((prevFilters) => ({
      ...prevFilters,
      search: value,
    }))
  }

  function handleChangeRowsPerPage(pageSize: number) {
    setDefaultTableSize(TABLE_SIZE_REFERENCE_KEYS.ALL_USERS_TABLE, pageSize)
  }

  function handleNavigateToMemberProfile({ original }: { original: any }) {
    navigateTo(`/recipients/users/${original._id}`)
  }

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy, sortOrder }: FetchDataParams) => {
      setQueryFilters((prevState) => ({
        ...prevState,
        skip: prevState.limit * pageIndex,
        limit: pageSize,
        sort_by: sortBy || prevState.sort_by,
        sort_order: sortOrder || prevState.sort_order,
      }))
      setPageCount(Math.ceil(total / pageSize))
      setPageIndex(pageIndex)
    },
    [total]
  )

  const groupsById = useMemo(() => {
    const byId: { [key: string]: any } = {}
    if (groups) {
      groups.forEach((group) => {
        byId[group._id] = group
      })
    }
    return byId
  }, [groups])

  const columns: ColumnInterface[] = useMemo(
    () => [
      {
        Header: t('users.usersTable.name'),
        accessor: 'first_name',
        Cell: (data: any) => {
          const { first_name, last_name } = data.row.original
          return `${first_name} ${last_name}`
        },
      },
      {
        Header: t('users.usersTable.email'),
        accessor: 'username',
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        Cell: ({ value }: { value: string }) => value || '-',
      },
      {
        Header: t('users.usersTable.groups'),
        accessor: 'groups',
        Cell: (data: any) => {
          const value = data.row.original.member_of
          const groupsByName: string[] = []
          if (Array.isArray(value) && value.length > 0) {
            value.forEach((groupId) => {
              const recipientGroup = groupsById[groupId]
              recipientGroup && groupsByName.push(recipientGroup.name)
            })
          }
          return groupsByName.length > 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                margin: 'auto',
              }}>
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '4px 4px',
                  fontSize: '14px',
                }}>
                {groupsByName[0]}
              </div>
              {groupsByName.length > 1 ? (
                <Tooltip
                  placement={'right'}
                  title={groupsByName
                    .slice(1)
                    .map((group) => group)
                    .join(', ')}>
                  <div
                    style={{
                      padding: '3px 8px',
                      gap: '10px',
                      borderRadius: '20px',
                      border: '1px solid #000',
                      marginLeft: '5px',
                      verticalAlign: 'middle',
                    }}>
                    {`+${groupsByName.length - 1}`}
                  </div>
                </Tooltip>
              ) : null}
            </div>
          ) : (
            '-'
          )
        },
      },
      {
        Header: t('users.usersTable.office'),
        accessor: 'office_id',
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => {
          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                margin: 'auto',
              }}>
              <div
                style={{
                  borderRadius: '4px',
                  backgroundColor: '#ADEFF9',
                  padding: '2px 4px',
                  fontSize: '14px',
                }}>
                {offices?.find((office) => office._id === value)?.name || ''}
              </div>
            </div>
          )
        },
      },
      {
        Header: t('users.usersTable.score'),
        accessor: 'awareness_score',
        Cell: ({ value }: { value: number }) => {
          return <HumanizedScore score={value} />
        },
      },
      {
        Header: t('users.usersTable.status'),
        accessor: 'state',
        Cell: ({ value }: { value: string }) =>
          value === 'active' ? t('users.usersTable.active') : t('users.usersTable.inactive'),
      },
      {
        id: 'delete',
        width: 80,
        align: 'center',
        Cell: () => {
          return (
            <>
              <Tooltip title={t('dynamicGroupProfile.actionsTooltip')} placement={'top'}>
                <Box>
                  <MoreMenu items={[]} ariaLabel={'users.usersTable.moreActions'} disabled />
                </Box>
              </Tooltip>
            </>
          )
        },
      },
    ],
    [t, offices]
  )

  return (
    <StyledRootBox>
      <BreadcrumbBox>
        <CustomBreadcrumbs lastBreadcrumbSuffix={`| ${dynamicGroup?.name}` || ''} />
      </BreadcrumbBox>
      <StyledGroupTitleBox>
        <FontAwesomeIcon icon={faAtom as IconProp} fontSize={18} color={theme.palette.purple[500]} />
        <Typography ml={theme.spacing(1)} fontSize={18} fontWeight={theme.typography.fontWeightMedium}>
          {dynamicGroup?.name}
        </Typography>
      </StyledGroupTitleBox>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '328px' }}>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('dynamicGroupProfile.groupDetails')}
              </Typography>
            </StyledTitleBar>
            {/* last Updated - unComment to unHide  */}
            {/* <StyledDetailBox>
              <FontAwesomeIcon icon={faRepeat as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('dynamicGroupProfile.lastUpdated')}</StyledKeyTypography>
              <Typography color={'inherit'}>{dayjs.utc(dynamicGroup?.updated_at).format(EU_DATE_TIME_24HR)}</Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" /> */}
            <StyledDetailBox>
              <FontAwesomeIcon icon={faBullhorn as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('dynamicGroupProfile.lastCampaign')}</StyledKeyTypography>
              <Typography color={'inherit'}>
                {dynamicGroup?.campaign_last_sent_at
                  ? dayjs.utc(dynamicGroup?.campaign_last_sent_at).format(EU_DATE_TIME_24HR)
                  : '-'}
              </Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" />
            <StyledDetailBox>
              <FontAwesomeIcon icon={faUsers as IconProp} fontSize={18} />
              <StyledKeyTypography color={'inherit'}>{t('dynamicGroupProfile.memberCount')}</StyledKeyTypography>
              <Typography color={'inherit'}>{dynamicGroup?.count}</Typography>
            </StyledDetailBox>
            <StyledDivider orientation="horizontal" />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ height: '328px' }}>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('dynamicGroupProfile.groupScore')}
              </Typography>
            </StyledTitleBar>
            <StyledScoreMeterBox>
              <GaugeScoremeter score={dynamicGroup?.awareness_score || 0} hideTitle />
            </StyledScoreMeterBox>
          </Paper>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '15px' }}>
        <Grid item xs={12} md={12}>
          <Paper>
            <StyledTitleBar>
              <Typography color={'inherit'} variant={'subtitle2'}>
                {t('dynamicGroupProfile.groupMembers')}
              </Typography>
            </StyledTitleBar>
            <StyledSearchBox>
              <SimpleSearchBar onChange={handleSearch} debounceTime={10} />
            </StyledSearchBox>
            <PaginatingTable
              noResultsContent={<NoResults membersCount={total} hasSearch={!!queryFilters?.search} />}
              columns={columns}
              data={members ?? []}
              fetchData={fetchData}
              loading={isLoading}
              pageCount={pageCount}
              count={total}
              onRowClick={handleNavigateToMemberProfile}
              initialPageSize={defaultRowsPerPage}
              currentPageIndex={pageIndex}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              enableCheckbox={false}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledRootBox>
  )
}

const NoResults: FC<{ membersCount?: number; hasSearch?: boolean }> = ({ membersCount, hasSearch = false }) => {
  const { t } = useTranslation()
  return (
    <InTableMessageWithAnimation>
      <p>
        {t(!membersCount ? (!hasSearch ? 'dynamicGroupProfile.noMembers' : 'dynamicGroupProfile.noMembersSearch') : '')}
      </p>
    </InTableMessageWithAnimation>
  )
}

const StyledDivider = styled(Divider)(() => ({
  margin: '0px 15px',
  borderBottomWidth: '3px',
  borderColor: '#E0EDEF',
}))

const StyledKeyTypography = styled(Typography)(() => ({
  fontWeight: theme.typography.fontWeightBold,
}))

const StyledTitleBar = styled(Box)(() => ({
  display: 'flex',
  color: '#294D55',
  alignItems: 'stretch',
  padding: '12px 16px',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: 'normal',
  background: '#E0EDEF',
  borderRadius: '13px 13px 0px 0px',
}))

const StyledDetailBox = styled(Box)(() => ({
  display: 'flex',
  padding: '12px 26px',
  gap: '12px',
  alignItems: 'center',
}))

const StyledRootBox = styled(Box)(() => ({
  height: '100%',
  padding: theme.spacing(1, 4, 4),
}))

const BreadcrumbBox = styled(Box)(() => ({
  marginBottom: theme.spacing(2),
}))

const StyledGroupTitleBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}))

const StyledScoreMeterBox = styled(Box)(() => ({
  justifyContent: 'center',
  paddingTop: '60px',
}))

const StyledSearchBox = styled(Box)(() => ({
  padding: theme.spacing(1),
}))

export default DynamicGroupProfile
