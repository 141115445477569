import { Box } from '@mui/material'
import { FC, useMemo, useState } from 'react'
import RenderIfVisible from 'react-render-if-visible'

import Carousel from '@/common/components/Carousel/Carousel'
import { PhishingSimulation } from '@/types/phishingSimulations'
import SimulationCard from './SimulationCard'

type SimulationLibraryCardViewProps = {
  simulationsByTopic: {
    [topic: string]: PhishingSimulation[]
  }
  showPreview: (simulation: PhishingSimulation) => void
  deleteModule: (simulation: PhishingSimulation) => void
  launchModule: (simulation: PhishingSimulation) => void
  saveModule: (id: string) => void
  savedSimulations: string[]
}
const SimulationLibraryCardView: FC<SimulationLibraryCardViewProps> = ({
  simulationsByTopic,
  showPreview,
  deleteModule,
  launchModule,
  saveModule,
  savedSimulations = [],
}) => {
  const [hoveredSimulation, setHoveredSimulation] = useState<{
    topic: string
    index: number
  } | null>(null)

  const topicsList = useMemo(() => Object.entries(simulationsByTopic), [simulationsByTopic])
  return (
    <Box pt={4}>
      {topicsList.map(([topic, simulations]) => {
        return (
          <Box mb={2} key={topic}>
            <RenderIfVisible defaultHeight={300} stayRendered>
              <Box sx={{ pt: 4 }}>
                <Carousel title={topic}>
                  {simulations.map((simulation, index) => (
                    <div key={simulation.id}>
                      <SimulationCard
                        key={simulation.id}
                        carouselTopic={topic}
                        simulation={simulation}
                        showPreview={showPreview}
                        deleteModule={deleteModule}
                        launchModule={launchModule}
                        saveModule={saveModule}
                        onMouseLeave={() => {
                          setHoveredSimulation(null)
                        }}
                        onMouseEnter={() => {
                          setHoveredSimulation({ topic, index })
                        }}
                        isSaved={savedSimulations?.includes(simulation.id)}
                        hoverState={
                          topic === hoveredSimulation?.topic
                            ? index === hoveredSimulation?.index
                              ? 0
                              : hoveredSimulation?.index > index
                              ? 1
                              : -1
                            : null
                        }
                      />
                    </div>
                  ))}
                </Carousel>
              </Box>
            </RenderIfVisible>
          </Box>
        )
      })}
    </Box>
  )
}

export default SimulationLibraryCardView
