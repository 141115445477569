import { Moment } from 'moment'

export function secondsToDays(seconds: number) {
  return Math.round(seconds / 86400)
}

export function calculateBusinessDays(firstDate: Moment, secondDate: Moment) {
  let day1 = firstDate.clone().startOf('day')
  let day2 = secondDate.clone().startOf('day')

  const weekends = [6, 0] // 6 = Saturday, 0 = Sunday
  const days = day2.diff(day1, 'days') + 1
  const fullWeeks = Math.floor(days / 7)
  const remainingDays = days % 7
  let businessDays = fullWeeks * 5

  for (let i = 1; i <= remainingDays; i++) {
    const day = day1.clone().add(i, 'days').day()
    if (!weekends.includes(day)) {
      businessDays++
    }
  }

  return businessDays
}

export const formatSeconds = (seconds: number) => {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0')
  const secs = (seconds % 60).toString().padStart(2, '0')
  return `${minutes}:${secs}`
}
