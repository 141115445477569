import { Box, Button, Typography } from '@mui/material'
import { FC, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useUnlockUser } from '@/api/auth/unlock-user'
import unlockedBouncerImage from '@/assets/images/auth/unlocked_bouncer.svg'
import FutureFormWrapper from '@/common/components/Layout/FutureFormWrapper'
import { useUrlQuery } from '@/common/hooks/useUrlQuery'
import { theme } from '@/theme/theme'

const UserUnlockedPage: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const urlQuery = useUrlQuery()
  const [unlockUser] = useUnlockUser()
  const token = urlQuery.get('token')

  useEffect(() => {
    token && unlockUser(token)
  }, [])

  return (
    <FutureFormWrapper>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
          {t('userUnlocked.userUnlocked')}
        </Typography>
        <Typography textAlign="center" width={'568px'}>
          <Trans
            i18nKey={'userUnlocked.description'}
            components={{
              1: <br />,
            }}
          />
        </Typography>
        <Box paddingTop={3}>
          <img src={unlockedBouncerImage} alt="bouncer" />
        </Box>
        <Box sx={{ width: '100%', padding: theme.spacing(1, 1) }}>
          <Button size="large" variant="contained" sx={{ width: '100%' }} onClick={() => navigateTo('/sign-in')}>
            {t('userUnlocked.signIn')}
          </Button>
        </Box>
      </Box>
    </FutureFormWrapper>
  )
}
export default UserUnlockedPage
