import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faUserGroup } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography } from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { chunk, sortBy } from 'lodash'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dots from '@/common/components/SliderDots/Dots'
import { scoreToColor } from '@/common/utils/difficultyToColor'

import { ALL_DATA_LIMIT } from '@/api/constants'
import { useGroups } from '@/api/groups/get'

const DepartmentScores: FC = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { data: groupsData } = useGroups(ALL_DATA_LIMIT)
  const groups = groupsData?.results || []
  const [activeStep, setActiveStep] = useState(0)

  function handleChange(index: number) {
    setActiveStep(index)
  }

  const groupScores: { name: string; score: number }[] = groups
    ? groups.map(({ name, awareness_score: score }) => ({
        name,
        score: score >= 0 ? Math.ceil(score) : 0,
      }))
    : []

  const formattedGroups = chunk(sortBy(groupScores, 'score').reverse(), 4)

  return (
    <div className={classes.root}>
      <div className={classes.slidesContainer}>
        {formattedGroups.length > 0 ? (
          formattedGroups[activeStep].map((group) => {
            return (
              <div key={group.name} className={classes.groupContainer}>
                <div className={classes.groupName}>
                  <FontAwesomeIcon icon={faUserGroup as IconProp} className={classes.groupIcon} />
                  <Typography variant={'subtitle2'}>{group.name}</Typography>
                </div>
                <div
                  className={classes.score}
                  style={{
                    background: scoreToColor(Math.floor(group.score), 100),
                  }}>
                  <Typography variant={'subtitle2'} color={'inherit'}>
                    {group.score || '-'}
                  </Typography>
                </div>
              </div>
            )
          })
        ) : (
          <div className={classes.errorContainer}>
            <Typography color={'textSecondary'} variant={'subtitle2'}>
              {t('adminDashboard.noData')}
            </Typography>
          </div>
        )}
      </div>
      <div className={classes.dotsContainer}>
        <Dots handleChange={handleChange} length={formattedGroups.length} activeStep={activeStep} />
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    slidesContainer: {
      height: '80%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    dotsContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    groupContainer: {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(1.5),
    },
    groupName: {
      display: 'flex',
      alignItems: 'center',
      width: 100,
      flex: 1,
    },
    groupIcon: {
      marginRight: theme.spacing(1),
    },
    score: {
      width: 50,
      padding: 2,
      borderRadius: theme.shape.borderRadius,
      background: theme.palette.grey[800],
      marginLeft: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.common.white,
    },
    errorContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  })
)

export default DepartmentScores
