import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { PagedResult } from '@/types/common'
import { API_URL } from '../constants'
import { paramsSerializer } from '../utils/serialize-array'
import { GroupListItem, GroupsListQuery } from '@/types/groups'

const getGroups = async (params: GroupsListQuery) => {
  const url = `${API_URL}/groups/`
  const { data } = await axios.get(url, {
    withCredentials: true,
    params,
    paramsSerializer,
  })

  return data
}

export const useGroups = (query: GroupsListQuery) => {
  return useQuery<PagedResult<GroupListItem>>({
    queryKey: ['groups', query],
    queryFn: () => getGroups(query),
    placeholderData: keepPreviousData,
  })
}
