import LoadingButton from '@mui/lab/LoadingButton'
import { Box, Button } from '@mui/material'
import Modal from '@mui/material/Modal'
import { FC, ReactNode, useState } from 'react'

import { ButtonsWrapper, ImageWrapper, ModalConent, StyledTypography } from '../AccountCenter/PopupsStyledComponents'

type PopupProps = {
  open: boolean
  onClose: () => void
  onConfirm?: (params?: any) => void
  buttonLabels?: { confirm?: string; cancel?: string }
  icon: string
  children: ReactNode[] | ReactNode
  disableBackdropClick?: boolean
  extraBottom?: ReactNode[] | ReactNode
}

const Popup: FC<PopupProps> = ({
  open,
  onClose,
  onConfirm,
  buttonLabels,
  icon,
  children,
  disableBackdropClick,
  extraBottom,
}) => {
  const [isPending, setIsPending] = useState(false)

  const handleClose = (_: React.SyntheticEvent, reason: 'backdropClick') => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return
    }
    setIsPending(false)
    onClose()
  }

  const handleConfirm = async () => {
    setIsPending(true)
    try {
      if (onConfirm) await onConfirm()
    } catch (e) {
      setIsPending(false)
      throw e
    }
    setIsPending(false)
  }

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalConent flexDirection={'column'}>
        <Box display="flex" gap={4}>
          <ImageWrapper>
            <img src={icon} alt="invite" />
          </ImageWrapper>
          <StyledTypography>{children}</StyledTypography>
        </Box>
        {extraBottom && <StyledTypography>{extraBottom}</StyledTypography>}
        <ButtonsWrapper>
          {!!buttonLabels?.cancel && (
            <Button variant="outlined" onClick={onClose}>
              {buttonLabels?.cancel}
            </Button>
          )}
          {Boolean(onConfirm) && (
            <LoadingButton variant="contained" onClick={handleConfirm} loading={isPending}>
              {buttonLabels?.confirm}
            </LoadingButton>
          )}
        </ButtonsWrapper>
      </ModalConent>
    </Modal>
  )
}

export default Popup
