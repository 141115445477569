export enum EntityType {
  GROUPS = 'groups',
}

export type IntegrationsEntitiesRequest = {
  types: EntityType[]
}

export type IntegrationAssets = {
  groups?: string[]
}
