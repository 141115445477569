import { palette } from '../palette'
import { typography } from '../typography'

export const MuiButton = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      whiteSpace: 'nowrap',
      letterSpacing: '0.02857em',
      textTransform: 'capitalize',
      minWidth: 'fit-content',
      fontWeight: typography.fontWeightMedium,
      fontSize: '16px',
      padding: '6px 16px',
      // margin: '4px', // Removed to work GroupButtons... button shuold no have margin
      height: '32px',
      boxShadow: 'none',
      borderRadius: 100,
      '&:hover , &:active': { boxShadow: 'none' },
      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        '& svg': {
          fontSize: '18px',
        },
      },
    },
    sizeLarge: {
      fontSize: '18px',
      height: '40px',
      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        '& svg': {
          fontSize: '20px',
        },
      },
    },
    sizeSmall: {
      fontSize: '12px',
      height: '24px',
      padding: '6px 8px',
      '& .MuiButton-startIcon, & .MuiButton-endIcon': {
        '& svg': {
          fontSize: '14px',
        },
      },
    },
    text: {
      color: palette.blueDianne[900],
      fontWeight: typography.fontWeightBold,
      background: 'none',
      '&:hover': {
        background: palette.blueDianne[50],
      },
      '&:active': {
        background: palette.blueDianne[100],
      },
      '&:disabled': {
        color: palette.grey[600],
      },
    },
    textSecondary: {
      color: palette.white,
      fontWeight: typography.fontWeightBold,
      background: 'none',
      '&:hover': {
        background: palette.blueDianne[600],
      },
      '&:active': {
        background: palette.blueDianne[800],
      },
      '&:disabled': {
        color: palette.grey[600],
      },
    },
    outlined: {
      color: palette.black,
      background: palette.white,
      borderColor: palette.cyan[500],
      '&:hover': {
        background: palette.blueDianne[50],
        borderColor: palette.cyan[500],
        '&:active': {
          background: palette.blueDianne[100],
        },
      },

      '&:disabled': {
        color: palette.grey[600],
        background: 'none',
        borderColor: palette.grey[400],
      },
    },
    contained: {
      color: palette.black,
      background: palette.cyan[500],
      '&:hover': {
        background: palette.blueDianne[600],
      },
      '&:active': {
        background: palette.blueDianne[800],
      },
      '&:disabled': {
        color: palette.grey[600],
        background: palette.grey[300],
        border: '1px solid',
        borderColor: palette.grey[400],
      },
    },

    containedSecondary: {
      color: palette.cyan[500],
      background: palette.blueGray[900],
      border: '1px solid',
      borderColor: palette.cyan[500],
      '&:hover': {
        background: palette.blueGray[700],
      },
      '&:active': {
        background: palette.blueGray[800],
      },
      '&:disabled': {
        color: palette.grey[600],
        background: palette.grey[300],
        border: '1px solid',
        borderColor: palette.grey[400],
      },
    },
  },
}
