import { Box, Button, Typography } from '@mui/material'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import uploadFile from '@/assets/images/upload-file.svg'
import { StyledTypography } from '@/common/components/AccountCenter/PopupsStyledComponents'

type Step6Props = {
  handleClose: () => void
}

const Step6: FC<Step6Props> = ({ handleClose }) => {
  const { t } = useTranslation()

  return (
    <>
      <Box display="flex" gap={2} sx={{ flexDirection: 'row' }}>
        <Box width={320} height={320}>
          <img src={uploadFile} alt="Upload successful" />
        </Box>
        <StyledTypography>
          <h4>{t('bulkUserUpload.success.title')}</h4>
          <Typography fontSize={16}>
            <Trans i18nKey={t('bulkUserUpload.success.message')} components={{ 1: <b /> }} />
          </Typography>
        </StyledTypography>
      </Box>

      <Box display="flex" width={1} justifyContent="flex-end" gap={1}>
        <Button variant="contained" onClick={handleClose}>
          {t('bulkUserUpload.viewMembers')}
        </Button>
      </Box>
    </>
  )
}

export default Step6
