import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircleInfo, faTrash, faHeart as heartIcon } from '@fortawesome/pro-light-svg-icons'
import { faHeart as fullHeartIcon } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, IconButton, styled, Tooltip, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { EducationModule } from '@/api/education-modules/modules'
import { shortenString } from '@/common/utils/shortenString'
import { theme } from '@/theme/theme'

type VideoLibraryActionsCellComponentProps = {
  row: EducationModule
  isSaved: boolean
  clientId: string | undefined
  saveFunction: (video: EducationModule) => void
  deleteFunction: (video: EducationModule) => void
}
const VideoLibraryActionsCellComponent: FC<VideoLibraryActionsCellComponentProps> = ({
  row,
  isSaved = false,
  clientId,
  saveFunction,
  deleteFunction,
}) => {
  const { t } = useTranslation()
  const isCustom = row.client_id === clientId
  const isLesson = !!row.additional_resources?.lesson_plan
  return (
    <StyledActionsContainer>
      <StyledIconButton
        onClick={(e) => {
          e.stopPropagation()
          saveFunction(row)
        }}
        size="small"
        color={'inherit'}>
        <FontAwesomeIcon
          icon={(isSaved ? fullHeartIcon : heartIcon) as IconProp}
          fontSize={22}
          color={theme.palette.pink[500]}
        />
      </StyledIconButton>
      <Tooltip
        title={
          <Box padding={theme.spacing(1, 0.5)}>
            <Typography variant="subtitle2">{t('videoLibrary.summary')}</Typography>
            <Typography variant="body1">{shortenString(row.description, 150)}</Typography>
          </Box>
        }
        placement={'top'}>
        <FontAwesomeIcon icon={faCircleInfo as IconProp} fontSize={22} />
      </Tooltip>

      <StyledIconButton
        disabled={!isCustom || isLesson}
        onClick={(e) => {
          e.stopPropagation()
          deleteFunction(row)
        }}
        size="small"
        color={'inherit'}>
        <FontAwesomeIcon icon={faTrash as IconProp} fontSize={22} />
      </StyledIconButton>
    </StyledActionsContainer>
  )
}

const StyledActionsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
}))

const StyledIconButton = styled(IconButton)(() => ({
  margin: theme.spacing(0, 0.5),
  color: theme.palette.blueGray[900],
}))
export default VideoLibraryActionsCellComponent
