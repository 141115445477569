import { API_URL } from '@/api/constants'
import { keepPreviousData, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { paramsSerializer } from '../utils/serialize-array'

const getVideos = async (params: any) => {
  const url = `${API_URL}/v1/training-videos/`
  const { data } = await axios.get(url, { withCredentials: true, params, paramsSerializer: paramsSerializer })
  return data
}
export const useVideos = ({ query }: VideosRequest) => {
  return useQuery<VideosResponse>({
    queryKey: ['videos', query],
    queryFn: () => {
      return getVideos(query)
    },
    placeholderData: keepPreviousData,
  })
}
