import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faClock, faSlidersUp, faUserGroup } from '@fortawesome/pro-light-svg-icons'
import {
  Box,
  Card,
  CardMedia,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Grid,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import moment, { Moment } from 'moment'
import { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { EducationModule } from '@/api/education-modules/modules'
import { Group } from '@/api/groups/groups'
import { User } from '@/api/users/users'
import CywarenessDefaultImage from '@/assets/images/cywareness_default.png'
import { calculateBusinessDays } from '@/common/utils/time'
import SimulationPackageCard, {
  SimulationPackageCardType,
} from '@/pages/ContentLibrary/SimulationsLibrary/SimulationPackageMenu/SimulationPackageCard'
import { getPackageCover } from '@/pages/ContentLibrary/SimulationsLibrary/SimulationPackageMenu/SimulationPackageCardUtils'
import SimulationPackagePreview from '@/pages/ContentLibrary/SimulationsLibrary/SimulationPackageMenu/SimulationPackagePreview'
import { theme } from '@/theme/theme'
import { AssetType, Targets } from '@/types/campaigns'
import { Office } from '@/types/offices'
import { PhishingSimulation } from '@/types/phishingSimulations'
import InfoTooltip from '../InfoTooltip/InfoTooltip'
import AdvancedSettings from './AdvancedSettings'
import StepperIcon from './StepperIcon'
import UserPicker from './UserPicker'

type CampaignDetailsProps = {
  assets?: PhishingSimulation[] | EducationModule[]
  dates: {
    start: Date
    end: Date
    span: number
    timezone: 'per_user' | string
  }
  setDates: Dispatch<
    SetStateAction<{
      start: Date
      end: Date
      span: number
      timezone: 'per_user' | string
    }>
  >
  setRecipients: (recipients: { groups: Group[]; offices: Office[]; users: User[] }) => void
  setCampaignName: Dispatch<SetStateAction<string>>
  campaignName: string
  assetMetadata: {
    isDemo: boolean
    includeQuiz?: boolean
    preferUserLanguage: boolean
    redirectUrl?: string
  }
  setAssetMetadata: Dispatch<
    SetStateAction<{
      isDemo: boolean
      includeQuiz: boolean
      preferUserLanguage: boolean
      redirectUrl: string
    }>
  >
  distributionTargets: Targets
  setDistributionTargets: Dispatch<SetStateAction<Targets>>
  errors: {
    [fieldName: string]: string
  }
  setErrors: Dispatch<SetStateAction<{ [key: string]: string }>>
  campaignType: AssetType
  packageName?: string
  customizeMicroTraining: {}
  setCustomizeMicroTraining: Dispatch<SetStateAction<{}>>
}

const CampaignDetails: FC<CampaignDetailsProps> = ({
  assets,
  setRecipients,
  dates,
  setDates,
  campaignName,
  setCampaignName,
  assetMetadata,
  setAssetMetadata,
  distributionTargets,
  setDistributionTargets,
  customizeMicroTraining,
  setCustomizeMicroTraining,
  errors,
  setErrors,
  campaignType,
  packageName,
}) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const [packagePreview, setPackagePreview] = useState(false)
  const [distributionLogic, setDistributionLogic] = useState<'allAtOnce' | 'gradual'>('allAtOnce')
  const pickerWrapperRef = useRef<HTMLDivElement>(null)
  const minStartDatetime = useRef(dates.start)

  const handleDateChange = (key: string) => (date: Moment | null) => {
    setDates((prevState) => ({
      ...prevState,
      [key]: date?.toDate(),
    }))
  }

  function handleNameChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setCampaignName(e.target.value)
    if (errors && errors.campaignName) {
      setErrors({ ...errors, campaignName: '' })
    }
  }

  function handleSetRecipients(recipients: { groups: Group[]; offices: Office[]; users: User[] }) {
    setRecipients(recipients)
    if (errors && (errors.recipients || errors.dates)) {
      setErrors({ ...errors, recipients: '', dates: '' })
    }
  }

  const campaignDurationInBusinessDays = calculateBusinessDays(moment(dates.start), moment(dates.end))

  useEffect(() => {
    if (campaignDurationInBusinessDays < 2) {
      setDistributionLogic('allAtOnce')
      return
    } else if (campaignDurationInBusinessDays < dates.span - 1) {
      setDates((prevState) => ({
        ...prevState,
        span: campaignDurationInBusinessDays - 1,
      }))
    }
  }, [campaignDurationInBusinessDays, dates.span, setDates])

  return (
    <Stepper nonLinear orientation="vertical" elevation={0} className={classes.stepper}>
      <Step active style={{ zIndex: 2 }}>
        <StepLabel
          StepIconComponent={() => (
            <StepperIcon faIcon={faSlidersUp as IconProp} label={t('launchWizard.details.general')}></StepperIcon>
          )}
        />
        <StyledStepContentWrapper>
          <StepContent>
            <Grid container spacing={2} minWidth={packagePreview ? '1040px' : '696px'}>
              <Grid item xs={8} xl={9}>
                <StyledInputContainerBox>
                  <StyledNameTextField
                    className={classes.input}
                    variant="outlined"
                    label={t('launchWizard.details.name')}
                    value={campaignName}
                    onChange={handleNameChange}
                    fullWidth
                    required
                    error={!!errors?.campaignName}
                    helperText={errors?.campaignName}
                  />
                  <div className={classes.flex}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={assetMetadata.isDemo}
                          onChange={() =>
                            setAssetMetadata((prevState) => ({
                              ...prevState,
                              isDemo: !prevState.isDemo,
                            }))
                          }
                          name="is-demo"
                        />
                      }
                      label={
                        <div className={classes.flex}>
                          {t('launchWizard.details.isDemo')}
                          <InfoTooltip title={t('launchWizard.details.isDemoTooltip')} />
                        </div>
                      }
                    />
                    {assets[0]?.quizzes?.length ? (
                      <div>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={assetMetadata.includeQuiz}
                              onChange={() =>
                                setAssetMetadata((prevState) => ({
                                  ...prevState,
                                  includeQuiz: !prevState.includeQuiz,
                                }))
                              }
                              name="include-quiz"
                            />
                          }
                          label={
                            <div className={classes.flex}>
                              {t('launchWizard.details.includeQuiz')}
                              <InfoTooltip title={t('launchWizard.details.includeQuizTooltip')} />
                            </div>
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </StyledInputContainerBox>
              </Grid>
              {assets && (
                <Grid item xs={4} xl={3}>
                  <StyledPreviewContainerBox>
                    {assets?.length > 1 ? (
                      packagePreview ? (
                        <SimulationPackagePreview
                          setPreview={setPackagePreview}
                          simulations={assets}
                          packageName={packageName}
                          maxHeight={pickerWrapperRef.current ? pickerWrapperRef.current.offsetHeight + 478 : 1000}
                        />
                      ) : (
                        <SimulationPackageCard
                          setPreview={setPackagePreview}
                          simulation={assets[0]}
                          packageName={packageName || t('simulationPackage.defaultPackageName')}
                          type={SimulationPackageCardType.Wizard}
                          coverImgage={getPackageCover(assets?.map((asset: any) => asset.vectors).flat())}
                        />
                      )
                    ) : (
                      <Card className={classes.assetCard}>
                        <Typography className={classes.title} noWrap variant="h6" component="h2">
                          {assets[0]?.name}
                        </Typography>
                        <div className={classes.overlay} />

                        <StyledCardMedia
                          component="img"
                          alt={`Image of ${
                            (assets[0] as PhishingSimulation)?.name || (assets[0] as EducationModule)?.title
                          }`}
                          image={
                            (assets[0] as PhishingSimulation)?.template_thumbnail ||
                            (assets[0] as EducationModule)?.thumbnail ||
                            CywarenessDefaultImage
                          }
                          title={(assets[0] as PhishingSimulation)?.name || (assets[0] as EducationModule)?.title}
                        />
                      </Card>
                    )}
                  </StyledPreviewContainerBox>
                </Grid>
              )}
            </Grid>
          </StepContent>
        </StyledStepContentWrapper>
      </Step>
      <Step active>
        <StepLabel
          StepIconComponent={() => (
            <StepperIcon
              faIcon={faUserGroup as IconProp}
              label={t('launchWizard.details.recipientsTitle')}></StepperIcon>
          )}
        />
        <StyledStepContentWrapper ref={pickerWrapperRef}>
          <StepContent>
            <UserPicker
              setRecipients={handleSetRecipients}
              hideRecipientSummary={packagePreview}
              assets={assets}
              distributionTargets={distributionTargets}
              setDistributionTargets={setDistributionTargets}
            />
            {errors?.recipients ? (
              <FormHelperText error>{t('launchWizard.errors.missingRecipients')}</FormHelperText>
            ) : null}
          </StepContent>
        </StyledStepContentWrapper>
      </Step>
      <Step active className={classes.lastStep}>
        <StepLabel
          StepIconComponent={() => (
            <StepperIcon faIcon={faClock as IconProp} label={t('launchWizard.details.schedule')}></StepperIcon>
          )}
        />
        <StyledStepContentWrapper>
          <StepContent>
            <StyledScheduleContainerBox>
              <div className={classes.datesContainer}>
                <div className={classes.flex}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      inputFormat="DD/MM/YYYY hh:mm a"
                      className={classes.datePicker}
                      label={t('launchWizard.details.startDate')}
                      value={moment(dates.start)}
                      minDateTime={moment(minStartDatetime.current).subtract(1, 'second')}
                      onChange={handleDateChange('start')}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      inputFormat="DD/MM/YYYY hh:mm a"
                      className={classes.datePicker}
                      minDateTime={moment(dates.start).add(1, 'hours')}
                      label={t('launchWizard.details.endDate')}
                      value={moment(dates.end)}
                      onChange={handleDateChange('end')}
                      onError={(error) =>
                        setErrors({
                          ...errors,
                          dates: (error && t('launchWizard.errors.datesMismatch.end')) || '',
                        })
                      }
                      renderInput={(params) => {
                        return <TextField {...params} />
                      }}
                    />
                  </LocalizationProvider>
                  <div className={classes.durationContainer}>
                    <Typography variant="body1">
                      <strong>{t('launchWizard.details.duration')}</strong>{' '}
                      {t('launchWizard.details.durationCalculation', {
                        count: campaignDurationInBusinessDays,
                      })}
                    </Typography>
                  </div>
                </div>
                {errors?.dates ? <FormHelperText error>{errors.dates}</FormHelperText> : null}
              </div>
              <AdvancedSettings
                distributionLogic={distributionLogic}
                setDistributionLogic={setDistributionLogic}
                dates={dates}
                setDates={setDates}
                errors={errors}
                setErrors={setErrors}
                campaignDurationInBusinessDays={campaignDurationInBusinessDays}
                assetMetadata={assetMetadata}
                setAssetMetadata={setAssetMetadata}
                campaignType={campaignType}
                assets={assets}
                customizeMicroTraining={customizeMicroTraining}
                setCustomizeMicroTraining={setCustomizeMicroTraining}
              />
            </StyledScheduleContainerBox>
          </StepContent>
        </StyledStepContentWrapper>
      </Step>
      <Step />
    </Stepper>
  )
}

const StyledStepContentWrapper = styled(Box)(() => ({
  marginTop: '-85px',
}))
const StyledCardMedia = styled(CardMedia)(() => ({
  minHeight: '192px',
}))
const StyledNameTextField = styled(TextField)(() => ({
  '&.MuiFormControl-root': {
    margin: 0,
  },
}))

const StyledScheduleContainerBox = styled(Box)(() => ({
  padding: theme.spacing(0, 3.5, 3.5),
  width: '100%',
}))
const StyledInputContainerBox = styled(Box)(() => ({
  margin: '0 24px',
}))

const StyledPreviewContainerBox = styled(Box)(() => ({
  paddingRight: '16px',
}))

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {},
    stepper: {
      border: 'none',
      '& .MuiStepContent-root': {
        borderLeft: `2px solid ${theme.palette.blueGray[900]}}`,
        marginTop: theme.spacing(-9),
        '& .MuiCollapse-wrapper': {
          transform: 'translateX(32px) translateY(-0px)',
        },
      },

      '& .MuiStepConnector-lineVertical': {
        minHeight: 0,
      },
      '& .MuiStepConnector-vertical': {
        display: 'none',
      },
    },
    datePicker: {
      width: 240,
      marginRight: theme.spacing(4),
    },
    dateContainer: {
      width: '100%',
      padding: theme.spacing(2),
      display: 'flex',
      alignItems: 'center',
      maxWidth: 600,
    },
    input: { marginBottom: theme.spacing(3) },
    tabs: {
      marginTop: theme.spacing(1),
    },
    tabsRoot: {
      border: `1px solid ${theme.palette.blueGray[900]}`,
      borderRadius: 30,
      padding: 0,
      width: 160,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
    },
    flexStart: {
      display: 'flex',
    },
    assetCard: {
      width: '100%',
      position: 'relative',
    },
    overlay: {
      position: 'absolute',
      height: 80,
      bottom: 0,
      right: 0,
      left: 0,
      background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 80%)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      pointerEvents: 'none',
    },
    title: {
      marginRight: theme.spacing(1),
      color: theme.palette.common.white,
      zIndex: 1,
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      padding: theme.spacing(1, 2),
    },

    durationContainer: {
      paddingTop: theme.spacing(2),
    },
    datesContainer: {
      marginBottom: theme.spacing(2),
    },
    lastStep: {
      '& .MuiStepContent-root': {
        borderRadius: '0 0 0 20px',
        border: 'solid',
        borderColor: 'black',
        borderWidth: '0 0 2px 2px',
      },
      '& .MuiStepConnector-lineVertical': {
        minHeight: 0,
      },
    },
  })
)
export default CampaignDetails
