import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { useQueryCache } from 'react-query'
import { API_URL } from '../constants'

export const disableMFA = async () => {
  const url = `${API_URL}/profile/mfa/disable`
  const { data } = await axios.post(url, {}, { withCredentials: true })

  return data
}

export const useDisableMFA = () => {
  const queryCache = useQueryCache()

  return useMutation({
    mutationFn: disableMFA,
    onSuccess: async () => {
      await queryCache.invalidateQueries('user')
    },
  })
}
