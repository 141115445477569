import FutureFormWrapper from '@/common/components/Layout/FutureFormWrapper'
import { FC } from 'react'
import { Link, Route, Routes, useMatch } from 'react-router-dom'
import ResetPasswordEmail from './ResetPasswordEmail'
import VerifyResetToken from './VerifyResetToken'
import SetPassword from './SetPassword'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import { Box, Typography } from '@mui/material'
import { theme } from '@/theme/theme'
import { Trans, useTranslation } from 'react-i18next'

const ResetPasswordPage: FC = () => {
  const { t } = useTranslation()
  const isSuccessPage = useMatch('/reset-password/success')

  return (
    <FutureFormWrapper>
      <Box display="flex" flexDirection="column" alignItems="center" minHeight={'400px'}>
        <Typography fontSize={24} fontWeight={theme.typography.fontWeightBold} paddingBottom={2}>
          {isSuccessPage ? 'Success!' : t('resetPassword.resetPassword')}
        </Typography>
        <Routes>
          <Route path="/" element={<ResetPasswordEmail />} />
          <Route path="verify/:username" element={<VerifyResetToken />} />
          <Route path="set-new/:username/:token" element={<SetPassword />} />
          <Route path="success" element={<ResetPasswordSuccess />} />
        </Routes>
        {!isSuccessPage && (
          <Typography fontWeight={theme.typography.fontWeightMedium} paddingTop={3}>
            <Trans
              i18nKey={'resetPassword.backTo'}
              components={{
                1: (
                  <Link
                    to="/sign-in"
                    style={{
                      color: theme.palette.blue[900],
                      fontWeight: theme.typography.fontWeightSemiBold,
                      textDecoration: 'none',
                    }}
                  />
                ),
              }}
            />
          </Typography>
        )}
      </Box>
    </FutureFormWrapper>
  )
}

export default ResetPasswordPage
