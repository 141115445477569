import LoadingButton from '@mui/lab/LoadingButton'
import { Box } from '@mui/material'
import { Form, Formik } from 'formik'
import { FC } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
//@ts-ignore
import * as Yup from 'yup'

import { useRequestPasswordReset } from '@/api/auth/request-password-reset'
import { theme } from '@/theme/theme'
import TextField from '@/common/components/Inputs/TextFieldV2'

const ResetPasswordEmail: FC = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { mutateAsync: requestPasswordReset } = useRequestPasswordReset()

  const handleSubmit = async (values: { username: string; password: string }) => {
    const recaptcha = (executeRecaptcha && (await executeRecaptcha('forgot_password'))) || ''
    await requestPasswordReset({ ...values, recaptcha })
    navigateTo(`/reset-password/verify/${values.username}`)
  }
  const initialValues = {
    username: '',
    password: '',
  }
  const validationSchema = Yup.object().shape({
    username: Yup.string().email('Invalid email').required('Field required'),
  })

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <Box minWidth={568} display="flex" flexDirection="column">
            <TextField required label="Email" name="username" />
            <Box sx={{ width: '100%', padding: theme.spacing(3, 0.5, 0, 0) }}>
              <LoadingButton
                size="large"
                type="submit"
                loading={isSubmitting}
                variant="contained"
                disabled={!dirty || !isValid}
                sx={{ width: '100%' }}>
                {t('resetPassword.sendEmail')}
              </LoadingButton>
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  )
}

export default ResetPasswordEmail
