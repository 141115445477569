import { createContext, ReactNode, useContext } from 'react'

import { useClientDetails } from '@/api/client/client'
import { CurrentUser, useCurrentUser } from '../api/profile/current-user'

type AuthContextValue = {
  user: CurrentUser | undefined
  isLoading: boolean
  isError: boolean
  organizationState: any
}
export const AuthContext = createContext<AuthContextValue>({
  user: undefined,
  isLoading: true,
  isError: false,
  organizationState: undefined,
})

export default function AuthProvider({ children }: { children: ReactNode }) {
  const { data: currentUser, isLoading: CurrentUserLoading, isError } = useCurrentUser()
  const { data: organizationDetails, isLoading: organizatioDetailsLoading } = useClientDetails()

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        isLoading: CurrentUserLoading && !currentUser && organizatioDetailsLoading && !organizationDetails,
        organizationState: organizationDetails?.state,
        isError: isError,
      }}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
