import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

import { PagedResult } from '@/types/common'
import { DynamicGroupMembersListQuery } from '@/types/dynamicGroups'
import { Member } from '@/types/members'
import { API_URL } from '../constants'

type Request = {
  id: string
  params: DynamicGroupMembersListQuery
}

export const getDynamicGroupMembers = async ({ id, params }: Request) => {
  const url = `${API_URL}/dynamic-groups/${id}/members`
  const { data } = await axios.get(url, {
    params,
    withCredentials: true,
  })

  return data
}

export const useDynamicGroupMemebers = ({ id, params }: Request) =>
  useQuery<PagedResult<Member>>({
    queryKey: ['dynamic-group', id, 'members', { ...params }],
    queryFn: () => getDynamicGroupMembers({ id, params }),
  })
