import { Box, Button, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import LogoText from '@/assets/images/logo-stay-safe.svg'
import trainingCompleteAnimation from '@/assets/lottie/completeCheck.json'
import useLottie from '@/common/hooks/useLottie'
import { theme } from '@/theme/theme'

const TrainingComplete: FC = () => {
  const { t } = useTranslation()
  const { LottieAnimation } = useLottie({
    animationData: trainingCompleteAnimation,
    height: 240,
    width: 240,
    options: {
      loop: false,
      autoplay: true,
      animationData: trainingCompleteAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    },
  })
  return (
    <StyledContainer>
      <Box padding={4}>
        <img alt={'Cywareness Logo'} src={LogoText} height={72} />
      </Box>
      <StyledPattayaTypography>{t('trainingCompleted.title')}</StyledPattayaTypography>
      <Box>{LottieAnimation}</Box>
      <Typography fontSize={20} fontWeight="fontWeightMedium">
        {t('trainingCompleted.description')}
      </Typography>
      <Typography fontSize={20} fontWeight="fontWeightMedium">
        {t('trainingCompleted.beAware')}
      </Typography>
      <Box width={'156px'} padding={theme.spacing(1, 0)}>
        <Button
          size="large"
          fullWidth
          variant="contained"
          onClick={() => {
            window.close()
          }}>
          {t('trainingCompleted.closeTab')}
        </Button>
      </Box>
      <Typography fontWeight="fontWeightMedium">
        {
          <Trans
            i18nKey={'trainingCompleted.learnMore'}
            components={{
              1: (
                //TODO: Link theme component!
                <Link
                  to={'https://cywareness.io/'}
                  style={{
                    color: theme.palette.blue[900],
                  }}
                />
              ),
            }}
          />
        }
      </Typography>
    </StyledContainer>
  )
}

const StyledContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(3),
  minHeight: '100vh',
})

const StyledPattayaTypography = styled(Typography)({
  fontSize: 48,
  fontWeight: theme.typography.fontWeightRegular,
  fontFamily: 'Pattaya',
  background: `linear-gradient(${theme.palette.blueGray[200]}, ${theme.palette.blueDianne[900]} ,${theme.palette.grey[900]})`,
  WebkitTextFillColor: 'transparent',
  WebkitBackgroundClip: 'text',
  paddingRight: theme.spacing(2),
})

export default TrainingComplete
